import React from "react";
import { useEffect, useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, Box } from "@mui/material";
import { AddCreditApi, PaymentSuccessListApi, VerifyCreditApi } from "../redux/actions/PaymenHistory";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { BASE_URL } from "../redux/apiConfig/BaseUrl";


const CreditPopup = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const [paymentIds, setPaymentIds] = useState();
  console.log("paymentIds",paymentIds);
  
  const companyListStatus = useSelector(
    (state) =>
      state.MyProfileDetails &&
      state.MyProfileDetails.progileMeGetApiRes &&
      state.MyProfileDetails.progileMeGetApiRes.data &&
      state.MyProfileDetails.progileMeGetApiRes.data.data
  );

  const CreditMsg = useSelector(
    (state) =>
      state.paymentHistory &&
      state.paymentHistory.CreditList &&
      state.paymentHistory.CreditList.data &&
      state.paymentHistory.CreditList.data.razorPayOrder &&
      state.paymentHistory.CreditList.data.razorPayOrder.message
  );
  const CreditpaymentArr = useSelector(
    (state) =>
      state.paymentHistory &&
      state.paymentHistory.CreditList &&
      state.paymentHistory.CreditList.data &&
      state.paymentHistory.CreditList.data.razorPayOrder &&
      state.paymentHistory.CreditList.data.razorPayOrder.data   
  );
  console.log('CreditpaymentArr from addcredit',CreditpaymentArr);
  useEffect(() => {
    console.log('CreditpaymentArr from addcredit',CreditpaymentArr);
      if (CreditpaymentArr && CreditpaymentArr.id) {
        handleVerifyPay();
      } else {
        if(CreditMsg){
          toast.success(CreditMsg);
          setTimeout(() => {
            navigate("/payment_history");
          }, 3000);
        }
      }
    }, [CreditpaymentArr]);

  const handleVerifyPay =  async (event) => {
    
    const addamount = document.getElementById("credit-amount")?.value;
    console.log("ADDED AMOUNT",addamount);
    onClose();
    const options = {
      key: "rzp_test_zv6ZfAcp5zacnk", // Your Razorpay API Key
      amount: parseInt(addamount) * 100, // Amount in paise (Example: for Rs. 500, amount should be 50000)
      currency: "INR",
      name: "KURMAR Research Private Limited",
      description: "Payment for services/products",
      order_id: CreditpaymentArr.id,
      handler: function (response) {
        const id = localStorage.getItem("companyId");
        console.log("response", response);
        setPaymentIds(response);
        toast.success(`Payment successful with ₹${parseInt(addamount)}`,{
        autoClose: 5000,
        });
        const data = {
          url: BASE_URL +`razorPay/verifyCredit?company_id=${id}`,
          body: { ...response,
                 invoiceReferenceId: "",
           },
        };
        dispatch(VerifyCreditApi(data));
      },
      prefill: {
        name: companyListStatus.name,
        email: companyListStatus.email,
        contact: companyListStatus.contact,
      },
    };
    const rzp = new window.Razorpay(options);
    rzp.open();
  };
  
  const handlePay = async (event) => {
    const addamount = document.getElementById("credit-amount")?.value;
      const bodyData = {
        amount: addamount,
        currency: "INR",
        receipt: "Payment for services/products",
        invoiceReferenceId: "",
      };
      const id = localStorage.getItem("companyId");
      const data = {
        url: BASE_URL +`razorPay/addCredit?company_id=${id}`,
        body: bodyData,
      };
      dispatch(AddCreditApi(data));
    };

  return (
    
    <Dialog
  open={open}
  onClose={onClose}
  fullWidth
  PaperProps={{
    style: {
      borderRadius: "10px",
      padding: "20px",
    },
  }}
>
  <DialogTitle
    style={{
      fontSize: "20px",
      fontWeight: "bold",
      marginBottom: "10px",
    }}
  >
    Add Amount
  </DialogTitle>
  <DialogContent>
    <TextField
      autoFocus
      margin="dense"
      id="credit-amount"
      label="Enter the Amount"
      type="number"
      fullWidth
      variant="outlined"
      style={{
        marginBottom: "20px",
      }}
    />
  </DialogContent>
  <DialogActions
    style={{
      display: "flex",
      justifyContent: "flex-end",
      padding: "10px",
    }}
  >
    <Button
      onClick={onClose}
      className="header-add"
      style={{
        width: "100px",
        backgroundColor: "#ccc",
        color: "#333",
        border: "none",
        borderRadius: "5px",
        padding: "10px",
        cursor: "pointer",
        marginRight: "10px",
      }}
    >
      Cancel
    </Button>
    <Button
      onClick={handlePay}
      className="header-add"
      style={{
        width: "100px",
        backgroundColor: "#007bff",
        color: "#fff",
        border: "none",
        borderRadius: "5px",
        padding: "10px",
        cursor: "pointer",
      }}
    >
      Pay
    </Button>
  </DialogActions>
</Dialog>
  );
};

export default CreditPopup;
