import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  FormControl,
  Grid,
  Stack,
  TextField,
  Autocomplete,
  Typography,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  IconButton,
  ListItemIcon,
} from "@mui/material";
import { ToastContainer } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ProgressbarTransparent from "../../GlobalProgress/ProgressbarTransparent";
import moment from "moment";
import {
  CreateMasterQRCodeApi,
  CreateTemplateApi,
  EditMasterQRCodeApi,
  EditTemplateApi,
  ListProductStoreApi,
  ProductListInventoryApi,
  SubProductListInventoryApi,
  TemplateListInventoryApi,
} from "../../redux/actions/Inventory";
import { BASE_URL } from "../../redux/apiConfig/BaseUrl";
import AddNewQRCodeHeader from "./AddNewQRCodeHeader";
import { ListUserChecklistApi } from "../../redux/actions/User";
import { ArchivedIssueListApi } from "../../redux/actions/Issue";
import ClearIcon from "@mui/icons-material/Clear";
import { makeStyles } from "@material-ui/core/styles";
import DropdownWithDetails from "../component/DropdownWithDetails";
import ProductDropdown from "./ProductDropdown";
import ViewProductItemsForQRCode from "./ViewProductItemsForQRCode";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: 300,
  },
  indeterminateColor: {
    color: "#f50057",
  },
  selectAllText: {
    fontWeight: 500,
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
}));

const AddNewQRCode = () => {
  let templatedata =
    (localStorage.getItem("addMasterBarCodeLocal") &&
      JSON.parse(localStorage.getItem("addMasterBarCodeLocal"))) ||
    (localStorage.getItem("masterBarCodeLocalStorageData") &&
      JSON.parse(localStorage.getItem("masterBarCodeLocalStorageData")));
  let addQustionEditData =
    (localStorage.getItem("questions") &&
      JSON.parse(localStorage.getItem("questions"))) ||
    (localStorage.getItem("masterBarCodeLocalStorageData") &&
      JSON.parse(localStorage.getItem("masterBarCodeLocalStorageData"))
        .question);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [questionData, setQuestionData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [hover, setHover] = useState({
    Edit: false,
    Delete: false,
  });
  const validationSchema = Yup.object().shape({});
  const [productListData, setProductListData] = useState([]);
  const [subProductListData, setSubProductListData] = useState([]);
  // const [selectedProducts, setSelectedProducts] = useState("");
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedProductsIds, setSelectedProductsIds] = useState([]);
  const [selectedSubProducts, setSelectedSubProducts] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectAllSubProd, setSelectAllSubProd] = useState(false);
  const [selectedSubProductId, setSelectedSubProductId] = useState([]);
  const [selectedErrorProduct, setSelectedErrorProduct] = useState(false);
  const [selectedErrorSubProduct, setSelectedErrorSubProduct] = useState(false);
  const classes = useStyles();
  const [selectedTemplateQuestion, setSelectedTemplateQuestion] = useState();
  const [selectedStore, setSelectedStore] = useState("");
  const [template, setTemplate] = useState([]);
  const [stores, setStores] = useState([]);

  console.log("productListData", productListData);
  const transformedProducts = Array.isArray(productListData)
    ? productListData.map((item) => ({
        ...item,
        name: item.productDetails?.name || "",
      }))
    : [];

  const isClickAllProducts =
    selectAllSubProd?.length > 0 &&
    selectAllSubProd?.length === subProductListData?.length;

  const isAllSelectedSubProducts =
    selectAllSubProd?.length > 0 &&
    selectAllSubProd?.length === subProductListData?.length;

  const productList = useSelector(
    (state) =>
      (state.inventory &&
        state.inventory.productList &&
        state.inventory.productList.data &&
        state.inventory.productList.data.data) ||
      []
  );

  const subProductList = useSelector(
    (state) =>
      (state.inventory &&
        state.inventory.subproductList &&
        state.inventory.subproductList.data &&
        state.inventory.subproductList.data.data) ||
      []
  );
  console.log("subProductList", subProductList);

  const templateData = useSelector(
    (state) =>
      (state.inventory &&
        state.inventory.templateData &&
        state.inventory.templateData.data &&
        state.inventory.templateData.data.data) ||
      []
  );

  const storeData = useSelector(
    (state) =>
      (state.inventory &&
        state.inventory.store &&
        state.inventory.store.data &&
        state.inventory.store.data.data) ||
      []
  );

  const getTemplateList = () => {
    const id = localStorage.getItem("companyId");

    const api = {
      url: BASE_URL + `product-template?company_id=${id}`,
      body: {
        pagination: {
          page: 1,
          per_page: 500,
        },
      },
    };
    dispatch(TemplateListInventoryApi(api));
  };

  const listStore = () => {
    const payloadRequest = {
      body: {
        page: 1,
        per_page: 200000,
        sort_order: -1,
        sort_by: "createdAt",
        type: "report",
      },
    };
    const id = localStorage.getItem("companyId");
    payloadRequest.url = BASE_URL + `stores/index?company_id=${id}`;
    dispatch(ListProductStoreApi(payloadRequest));
  };

  useEffect(() => {
    getTemplateList();
    listStore();
  }, []);

  useEffect(() => {
    if (templateData.finalData) {
      setTemplate(templateData.finalData);
    }
  }, [templateData]);

  useEffect(() => {
    if (storeData.stores) {
      setStores(storeData.stores);
    }
  }, [storeData]);

  const getProductList = () => {
    const id = localStorage.getItem("companyId");
    const api = {
      url: BASE_URL + `inventory-product?company_id=${id}`,
    };
    dispatch(ProductListInventoryApi(api));
  };

  useEffect(() => {
    getProductList();
  }, []);

  useEffect(() => {
    if (productList?.finalData) {
      setProductListData(productList?.finalData);
    }
  }, [productList?.finalData]);

  useEffect(() => {
    if (subProductList?.finalData?.length) {
      setSubProductListData(subProductList?.finalData);
    }
  }, [subProductList]);

  useEffect(() => {
    if (questionData)
      localStorage.setItem("qustionDataid", questionData?.length);
  }, [questionData]);

  useEffect(() => {
    if (addQustionEditData) {
      setQuestionData(addQustionEditData);
    }
  }, []);

  const navigatioToList = (data) => {
    if (data === 200) {
      setTimeout(() => {
        setLoader(false);
        navigate({ pathname: "/master-barcode-list" });
      }, 2000);
    }
  };
  console.log("filterStore", selectedStore);

  const formik = useFormik({
    initialValues: {
      title: templatedata?.title || "",
      description: templatedata?.description || "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleFinalSubmit({
        ...values,
        store: {
          _id: selectedStore?._id,
          name: selectedStore?.name,
        },
        productItemIds: selectedProducts,
        subProductItemIds: selectedSubProducts,
      });
    },
  });

  console.log("selectedProductsIds", selectedProductsIds);

  const handleFinalSubmit = (values) => {
    const isEdit = localStorage.getItem("masterBarCodeLocalStorageData");
  
    if (!values.title) {
      return toast.error("Title is required!");
    }
  
    if (!values.description) {
      return toast.error("Description is required!");
    }
  
    const selectedProductsToSubmit = selectedProductsIds.length > 0 
      ? selectedProductsIds 
      : selectedProducts.map((product) => product._id); 
  
    const formquestionValue = {
      title: values.title,
      description: values.description,
      store: {
        _id: selectedStore?._id,
        name: selectedStore?.name,
      },
      products: selectedProductsToSubmit, 
    };
  
    const allData = {
      body: formquestionValue,
    };
    
    const id = localStorage.getItem("companyId");
    setLoader(true);
  
    if (isEdit && JSON.parse(isEdit)) {
      allData.url =
        BASE_URL +
        `master-barcode/${JSON.parse(isEdit)?._id}?company_id=${id}`;
      dispatch(EditMasterQRCodeApi(allData, navigatioToList));
    } else {
      allData.url = BASE_URL + `master-barcode?company_id=${id}`;
      dispatch(CreateMasterQRCodeApi(allData, navigatioToList));
    }
  };
  

  const handleCancel = () => {
    localStorage.removeItem("addMasterBarCodeLocal");
    localStorage.removeItem("questionsListLocal");
    localStorage.removeItem("masterBarCodeLocalStorageData");
  };

  // useEffect(() => {
  //   if (selectedProducts) {
  //     const id = localStorage.getItem("companyId");
  //     const api = {
  //       url:
  //         BASE_URL +
  //         `inventory-product-items?company_id=${id}&id=${selectedProducts?._id}`,
  //     };
  //     dispatch(SubProductListInventoryApi(api));
  //   }
  // }, [selectedProducts, dispatch]);

  // sub product
  const handleSubProductChange = (event) => {
    const value = event.target.value;
    const isClickAll = value.includes("all");

    if (selectedProducts?.length === 0) {
      setSelectedSubProducts([]);
      setSelectedSubProductId([]);
      return;
    }

    if (isClickAll) {
      const newSelectedSubProducts =
        selectedSubProducts.length === subProductListData.length
          ? []
          : subProductListData;

      setSelectedSubProducts(newSelectedSubProducts);
      setSelectedSubProductId(newSelectedSubProducts.map((item) => item._id));
      return;
    }

    const updatedSelections = value.filter(
      (item, index, self) => index === self.findIndex((t) => t._id === item._id)
    );

    setSelectedSubProducts(updatedSelections);
    setSelectedSubProductId(updatedSelections.map((item) => item._id));
  };

  const handleBlurSubProduct = () => {
    if (!selectedSubProducts?.length) {
      setSelectedErrorSubProduct(true);
    } else {
      setSelectedErrorSubProduct(false);
    }
  };
  useEffect(() => {
    if (stores.length && !selectedStore) {
      const storeIdFromProductItem = templatedata?.store?._id;
      if (storeIdFromProductItem) {
        const preSelectedStore = stores?.find(
          (store) => store?._id === storeIdFromProductItem
        );
        setSelectedStore(preSelectedStore || null);
      }
    }
  }, [templatedata, stores, selectedStore]);

  const preSelectProdRef = useRef(false);

  useEffect(() => {
    if (
      Array.isArray(templatedata?.products) &&
      templatedata.products.length > 0 &&
      !preSelectProdRef.current &&
      transformedProducts.length > 0
    ) {
      const productIds = templatedata.products.map(
        (product) => product.productId?._id
      );
      const preSelectedProducts = transformedProducts.filter((product) =>
        productIds.includes(product._id)
      );

      if (preSelectedProducts.length > 0) {
        setSelectedProducts(preSelectedProducts);
      }
      preSelectProdRef.current = true;
    }
  }, [templatedata, transformedProducts]);

  const preSelectRef = useRef(false);

  useEffect(() => {
    if (
      templatedata?.productItemIds?.length > 0 &&
      subProductListData?.length > 0 &&
      !preSelectRef.current
    ) {
      const preSelectedOptions = subProductListData.filter((option) =>
        templatedata.productItemIds.includes(option._id)
      );

      setSelectedSubProducts(preSelectedOptions);
      setSelectedSubProductId(preSelectedOptions.map((item) => item._id));

      preSelectRef.current = true;
    }
  }, [templatedata, subProductListData]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <ToastContainer autoClose={3000} />
      {loader && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <ProgressbarTransparent play />
        </div>
      )}

      <AddNewQRCodeHeader
        handleFinalSubmit={handleFinalSubmit}
        handleCancel={handleCancel}
        values={formik}
      />
      <Box sx={{ bgcolor: "#F6F8FC", p: 1, borderRadius: "4px" }}>
        <Container
          maxWidth="xl"
          sx={{
            backgroundColor: "#fff",
            borderRadius: "10px",
            p: 1,
            textAlign: "left",
          }}
        >
          {/* Title Field */}
          <FormControl fullWidth sx={{ mb: 2 }}>
            <label>Title</label>
            <TextField
              id="outlined-basic"
              variant="outlined"
              size="small"
              name="title"
              onChange={formik.handleChange}
              value={formik.values.title}
              className="date-input"
              sx={{
                height: "48px",
                "& .MuiInputBase-root": {
                  height: "48px", 
                },
              }}
            />
          </FormControl>

          {/* Description Field */}
          <FormControl fullWidth sx={{ mb: 2 }}>
            <label>Description</label>
            <TextField
              id="outlined-basic"
              variant="outlined"
              size="small"
              name="description"
              onChange={formik.handleChange}
              value={formik.values.description}
              className="date-input"
              sx={{
                height: "48px", 
                "& .MuiInputBase-root": {
                  height: "48px", 
                },
              }}
            />
          </FormControl>

          {/* Store Dropdown */}
          <FormControl sx={{ width: "100%", mb: 2 }}>
            <Box>Store</Box>
            <Autocomplete
              id="country-select-demo"
              sx={{
                width: "100%",
                paddingX: "1px",
                "& .MuiInputBase-root": {
                  height: "48px", 
                },
              }}
              options={stores || []}
              value={selectedStore && stores.length ? selectedStore : ""}
              onChange={(event, newValue) => setSelectedStore(newValue)}
              getOptionLabel={(option) => option?.name || ""}
              renderOption={(props, option) => {
                const { key, ...optionProps } = props;
                return (
                  <Box {...optionProps}>
                    {option.name} 
                  </Box>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{
                    padding: "0px",
                    height: "48px", 
                  }}
                />
              )}
              size="small"
              MenuProps={{
                PaperProps: {
                  sx: {
                    maxHeight: 250,
                    "& .MuiMenuItem-root": {
                      height: "48px", 
                    },
                  },
                },
              }}
            />
          </FormControl>

          <FormControl sx={{ width: "100%", mb: 2 }}>
            <Box>Product</Box>
            <Autocomplete
              id="country-select-demo"
              multiple // Enable multiple select
              sx={{
                width: "100%",
                paddingX: "1px",
                "& .MuiInputBase-root": {
                  height: "48px", // Ensure equal height for the input field
                },
              }}
              options={transformedProducts || []} // Ensure options is always an array
              value={
                Array.isArray(selectedProducts)
                  ? selectedProducts.map(
                      (selected) =>
                        transformedProducts.find(
                          (product) => product._id === selected._id
                        ) || null
                    )
                  : [] // Map selectedProducts to options
              }
              isOptionEqualToValue={(option, val) => option?._id === val?._id}
              // onChange={(event, newValue) => {
              //   console.log("Selected Products:", newValue); // Debugging log
              //   setSelectedProducts(newValue || []); // Ensure fallback to empty array
              // }}
              onChange={(event, newValue) => {
                console.log("Selected Products:", newValue);
                setSelectedProducts(newValue || []);
                const selectedProductIds = (newValue || []).map(
                  (product) => product._id
                );
                setSelectedProductsIds(selectedProductIds);
                console.log("Selected Product IDs:", selectedProductIds);
              }}
              getOptionLabel={(option) => option?.name || ""}
              renderOption={(props, option) => {
                const { key, ...optionProps } = props;
                return (
                  <Box {...optionProps}>
                    <Box
                      key={key}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        py: 1, // Add padding to the option content
                      }}
                      pl={2}
                      pr={2}
                    >
                      <Typography sx={{ fontWeight: "bold" }}>
                        {option.name}
                      </Typography>
                      <Box sx={{ display: "flex", gap: "16px", mt: 1 }}>
                        {[
                          {
                            title: "",
                            value: "updatedAt",
                            type: "date",
                            format: "ll",
                          },
                          { title: "Desc", value: "description" },
                          { title: "Count", value: "count" },
                        ].map((item) => {
                          let value;
                          if (item.value === "description") {
                            value = option.productDetails?.description;
                          } else if (item.value === "count") {
                            value = option.productItems;
                          } else {
                            value = option[item.value];
                          }

                          // Format if it's a date
                          if (item.type === "date" && value) {
                            value = moment(value).format(item.format);
                          }

                          return (
                            <Typography
                              key={item.value}
                              sx={{ fontSize: "12px", color: "#64748b" }}
                            >
                              {item.title && `${item.title}:`} {value}
                            </Typography>
                          );
                        })}
                      </Box>
                    </Box>
                  </Box>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{
                    padding: "0px",
                    height: "48px", // Ensure equal height for the input field
                  }}
                />
              )}
              size="small"
              MenuProps={{
                PaperProps: {
                  sx: {
                    maxHeight: 250, // Limit the dropdown height
                    "& .MuiMenuItem-root": {
                      height: "48px", // Ensure equal height for each menu item
                    },
                  },
                },
              }}
            />
          </FormControl>

          {/* {selectedProducts.length > 0 && (
            <ViewProductItemsForQRCode selectedProducts={selectedProducts} />
          )} */}
         
        </Container>
      </Box>
    </form>
  );
};

export default AddNewQRCode;
