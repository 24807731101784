import { toast } from "react-toastify";
import JwtAuthService from "../apiConfig/ApiConfig";
import {
  ADD_NEW_PRODUCT_ITEMS_ERROR,
  ADD_NEW_PRODUCT_ITEMS_REQUEST,
  ADD_NEW_PRODUCT_ITEMS_SUCCESS,
  CATEGORY_LIST_INVENTORY_ERROR,
  CATEGORY_LIST_INVENTORY_REQUEST,
  CATEGORY_LIST_INVENTORY_SUCCESS,
  CREATE_INVENTORY_PRODUCT_ERROR,
  CREATE_INVENTORY_PRODUCT_REQUEST,
  CREATE_INVENTORY_PRODUCT_SUCCESS,
  DELETE_PRODUCT_ERROR,
  DELETE_PRODUCT_ITEMS_ERROR,
  DELETE_PRODUCT_ITEMS_REQUEST,
  DELETE_PRODUCT_ITEMS_SUCCESS,
  DELETE_PRODUCT_REQUEST,
  DELETE_PRODUCT_SUCCESS,
  DELETEITEM_ERROR,
  DELETEITEM_REQUEST,
  DELETEITEM_SUCCESS,
  EDIT_PRODUCT_ITEMS_ERROR,
  EDIT_PRODUCT_ITEMS_REQUEST,
  EDIT_PRODUCT_ITEMS_SUCCESS,
  INVENTORY_PRODUCT_ITEM_ERROR,
  INVENTORY_PRODUCT_ITEM_REQUEST,
  INVENTORY_PRODUCT_ITEM_SUCCESS,
  LISTQRCODE_DELETE_ERROR,
  LISTQRCODE_DELETE_REQUEST,
  LISTQRCODE_DELETE_SUCCESS,
  MASTERBARCODEVIEW_ERROR,
  MASTERBARCODEVIEW_ITEMVIEW_ERROR,
  MASTERBARCODEVIEW_ITEMVIEW_REQUEST,
  MASTERBARCODEVIEW_ITEMVIEW_SUCCESS,
  MASTERBARCODEVIEW_REQUEST,
  MASTERBARCODEVIEW_SUCCESS,
  MASTERQRCODE_CREATE_ERROR,
  MASTERQRCODE_CREATE_REQUEST,
  MASTERQRCODE_CREATE_SUCCESS,
  MASTERQRCODE_LIST_INVENTORY_ERROR,
  MASTERQRCODE_LIST_INVENTORY_REQUEST,
  MASTERQRCODE_LIST_INVENTORY_SUCCESS,
  PRODUCT_ITEMS_ERROR,
  PRODUCT_ITEMS_REQUEST,
  PRODUCT_ITEMS_SUCCESS,
  PRODUCT_LIST_ERROR,
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_SUCCESS,
  PRODUCT_STORE_ERROR,
  PRODUCT_STORE_REQUEST,
  PRODUCT_STORE_SUCCESS,
  TEMPLATE_DELETE_ERROR,
  TEMPLATE_DELETE_REQUEST,
  TEMPLATE_DELETE_SUCCESS,
  TEMPLATE_LIST_INVENTORY_ERROR,
  TEMPLATE_LIST_INVENTORY_REQUEST,
  TEMPLATE_LIST_INVENTORY_SUCCESS,
  TEMPLATE_QUESTION_ADD_ERROR,
  TEMPLATE_QUESTION_ADD_REQUEST,
  TEMPLATE_QUESTION_ADD_SUCCESS,
  UPDATE_ITEMTOQR_ERROR,
  UPDATE_ITEMTOQR_REQUEST,
  UPDATE_ITEMTOQR_SUCCESS,
  UPLOAD_PRODUCT_MEDIA_QUES_ERROR,
  UPLOAD_PRODUCT_MEDIA_QUES_REQUEST,
  UPLOAD_PRODUCT_MEDIA_QUES_SUCCESS,
} from "../types/Types";

export const AddTemplateQuestionRequest = () => ({
  type: TEMPLATE_QUESTION_ADD_REQUEST,
});
export const AddTemplateQuestionSuccess = (type) => ({
  type: TEMPLATE_QUESTION_ADD_SUCCESS,
  payload: type,
});
export const AddTemplateQuestionFailed = (error) => ({
  type: TEMPLATE_QUESTION_ADD_ERROR,
  payload: error,
});

export const DeleteTemplateQuestionRequest = () => ({
  type: TEMPLATE_DELETE_REQUEST,
});
export const DeleteTemplateQuestionSuccess = (type) => ({
  type: TEMPLATE_DELETE_SUCCESS,
  payload: type,
});
export const DeleteTemplateQuestionFailed = (error) => ({
  type: TEMPLATE_DELETE_ERROR,
  payload: error,
});

export const CreateTemplateApi = (data, callBack) => (dispatch) => {
  try {
    dispatch(AddTemplateQuestionRequest(data));
    JwtAuthService.PostApiService(data).then((response) => {
      if (response?.status === 200) {
        const data = response && response.data && response.data.data;
        dispatch(AddTemplateQuestionSuccess(data));
        callBack && callBack(response?.status);
      } else {
        dispatch(AddTemplateQuestionFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(AddTemplateQuestionFailed(error));
  }
};

export const EditTemplateApi = (data, callBack) => (dispatch) => {
  try {
    dispatch(AddTemplateQuestionRequest(data));
    JwtAuthService.PutApiService(data).then((response) => {
      if (response?.status === 200) {
        const data = response && response.data && response.data.data;
        dispatch(AddTemplateQuestionSuccess(data));
        callBack && callBack(response?.status);
      } else {
        dispatch(AddTemplateQuestionFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(AddTemplateQuestionFailed(error));
  }
};

export const DeleteTemplateApi = (data, callBack) => (dispatch) => {
  try {
    dispatch(DeleteTemplateQuestionRequest(data));
    JwtAuthService.DeleteApiService(data).then((response) => {
      if (response?.status === 200) {
        const data = response && response.data && response.data.data;
        dispatch(DeleteTemplateQuestionSuccess(data));
        callBack && callBack(response?.status);
      } else {
        dispatch(DeleteTemplateQuestionFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(DeleteTemplateQuestionFailed(error));
  }
};

export const CategoryListRequest = () => ({
  type: CATEGORY_LIST_INVENTORY_REQUEST,
});
export const CategoryListSuccess = (list) => ({
  type: CATEGORY_LIST_INVENTORY_SUCCESS,
  payload: list,
});
export const CategoryListFailed = (error) => ({
  type: CATEGORY_LIST_INVENTORY_ERROR,
  payload: error,
});

export const CategoryListInventoryApi = (data) => (dispatch) => {
  try {
    dispatch(CategoryListRequest(data));
    JwtAuthService.GetApiService(data).then((response) => {
      console.log("response", response);
      if (response?.status === 200) {
        dispatch(CategoryListSuccess(response));
      } else {
        dispatch(CategoryListFailed(response?.data?.message));
        // toast.error(`${response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(CategoryListFailed(error));
    console.error("Something went Wrong", error);
    // toast.error(error);
  }
};

export const TemplateListRequest = () => ({
  type: TEMPLATE_LIST_INVENTORY_REQUEST,
});
export const TemplateListSuccess = (list) => ({
  type: TEMPLATE_LIST_INVENTORY_SUCCESS,
  payload: list,
});
export const TemplateListFailed = (error) => ({
  type: TEMPLATE_LIST_INVENTORY_ERROR,
  payload: error,
});

export const TemplateListInventoryApi = (data) => (dispatch) => {
  try {
    dispatch(TemplateListRequest(data));
    JwtAuthService.GetApiService(data).then((response) => {
      console.log("response", response);
      if (response?.status === 200) {
        dispatch(TemplateListSuccess(response));
      } else {
        dispatch(TemplateListFailed(response?.data?.message));
        // toast.error(`${response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(TemplateListFailed(error));
    console.error("Something went Wrong", error);
    // toast.error(error);
  }
};

export const CreateInventoryProductRequest = () => ({
  type: CREATE_INVENTORY_PRODUCT_REQUEST,
});
export const CreateInventoryProductSuccess = (list) => ({
  type: CREATE_INVENTORY_PRODUCT_SUCCESS,
  payload: list,
});
export const CreateInventoryProductFailed = (error) => ({
  type: CREATE_INVENTORY_PRODUCT_ERROR,
  payload: error,
});

export const CreateInventoryProductApi = (data, callback) => (dispatch) => {
  try {
    dispatch(CreateInventoryProductRequest(data));
    JwtAuthService.PostApiService(data).then((response) => {
      console.log("response", response);
      if (response?.status === 200) {
        dispatch(CreateInventoryProductSuccess(response));
        callback && callback();
      } else {
        dispatch(CreateInventoryProductFailed(response?.data?.message));
        // toast.error(`${response?.data?.message}`);
      }
    });
  } catch (error) {
    // dispatch(CreateInventoryProductFailed(error));
    console.error("Something went Wrong", error);
    // toast.error(error);
  }
};

export const ListProductStoreRequest = () => ({
  type: PRODUCT_STORE_REQUEST,
});
export const ListProductStoreSuccess = (list) => ({
  type: PRODUCT_STORE_SUCCESS,
  payload: list,
});
export const ListProductStoreFailed = (error) => ({
  type: PRODUCT_STORE_ERROR,
  payload: error,
});

export const ListProductStoreApi = (data) => (dispatch) => {
  try {
    dispatch(ListProductStoreRequest(data));
    JwtAuthService.PostApiService(data).then((response) => {
      console.log("response", response);
      if (response?.status === 200) {
        dispatch(ListProductStoreSuccess(response));
      } else {
        dispatch(ListProductStoreFailed(response?.data?.message));
        // toast.error(`${response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(ListProductStoreFailed(error));
    console.error("Something went Wrong", error);
    // toast.error(error);
  }
};

export const UploadProductMediaQ = (companyid) => ({
  type: UPLOAD_PRODUCT_MEDIA_QUES_REQUEST,
  payload: companyid,
});

export const UploadProductMediaQSuccess = (companyid) => ({
  type: UPLOAD_PRODUCT_MEDIA_QUES_SUCCESS,
  payload: companyid,
});

export const UploadProductMediaQFailed = (error) => ({
  type: UPLOAD_PRODUCT_MEDIA_QUES_ERROR,
  payload: error,
});

export const UploadProductMediaQuesApi =
  (data, questionIndex) => (dispatch) => {
    try {
      dispatch(UploadProductMediaQ(data));
      JwtAuthService.BinaryImageApiService(data).then((response) => {
        if (response?.status == 200) {
          const data = response;
          console.log("data ==>", data);
          dispatch(
            UploadProductMediaQSuccess({
              imgKey: data.data.data[0].key,
              questionIndex: questionIndex,
            })
          );
          toast.success(`${data?.data?.message}`);
        } else {
          dispatch(UploadProductMediaQFailed(response?.response?.statusText));
          toast.error(`${data?.data?.message}`);
        }
      });
    } catch (error) {
      dispatch(UploadProductMediaQFailed(error));
    }
  };

export const ProductListRequest = () => ({
  type: PRODUCT_LIST_REQUEST,
});
export const ProductListSuccess = (list) => ({
  type: PRODUCT_LIST_SUCCESS,
  payload: list,
});
export const ProductListFailed = (error) => ({
  type: PRODUCT_LIST_ERROR,
  payload: error,
});

export const ProductListInventoryApi = (data) => (dispatch) => {
  try {
    dispatch(ProductListRequest(data));
    JwtAuthService.GetApiService(data).then((response) => {
      console.log("response", response);
      if (response?.status === 200) {
        dispatch(ProductListSuccess(response));
      } else {
        dispatch(ProductListFailed(response?.data?.message));
        // toast.error(`${response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(ProductListFailed(error));
    console.error("Something went Wrong", error);
    // toast.error(error);
  }
};

export const SubProductListRequest = () => ({
  type: INVENTORY_PRODUCT_ITEM_REQUEST,
});
export const SubProductListSuccess = (list) => ({
  type: INVENTORY_PRODUCT_ITEM_SUCCESS,
  payload: list,
});
export const SubProductListFailed = (error) => ({
  type: INVENTORY_PRODUCT_ITEM_ERROR,
  payload: error,
});

export const SubProductListInventoryApi = (data) => (dispatch) => {
  try {
    dispatch(SubProductListRequest(data));
    JwtAuthService.PostApiService(data).then((response) => {
      console.log("response", response);
      if (response?.status === 200) {
        dispatch(SubProductListSuccess(response));
      } else {
        dispatch(SubProductListFailed(response?.data?.message));
        // toast.error(`${response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(SubProductListFailed(error));
    console.error("Something went Wrong", error);
    // toast.error(error);
  }
};

//MasterQRCode
export const MasterQRCodeListRequest = () => ({
  type: MASTERQRCODE_LIST_INVENTORY_REQUEST,
});
export const MasterQRCodeListSuccess = (list) => ({
  type: MASTERQRCODE_LIST_INVENTORY_SUCCESS,
  payload: list,
});
export const MasterQRCodeListFailed = (error) => ({
  type: MASTERQRCODE_LIST_INVENTORY_ERROR,
  payload: error,
});

export const MasterQRCodeListInventoryApi = (data) => (dispatch) => {
  try {
    dispatch(MasterQRCodeListRequest(data));
    JwtAuthService.GetApiService(data).then((response) => {
      console.log("response", response);
      if (response?.status === 200) {
        dispatch(MasterQRCodeListSuccess(response));
      } else {
        dispatch(MasterQRCodeListFailed(response?.data?.message));
        // toast.error(`${response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(MasterQRCodeListFailed(error));
    console.error("Something went Wrong", error);
    // toast.error(error);
  }
};

export const DeleteQRCodeListRequest = () => ({
  type: LISTQRCODE_DELETE_REQUEST,
});
export const DeleteQRCodeListSuccess = (type) => ({
  type: LISTQRCODE_DELETE_SUCCESS,
  payload: type,
});
export const DeleteQRCodeListFailed = (error) => ({
  type: LISTQRCODE_DELETE_ERROR,
  payload: error,
});

export const DeleteQRCodeListApi = (data, callBack) => (dispatch) => {
  try {
    dispatch(DeleteQRCodeListRequest(data));
    JwtAuthService.PostApiService(data).then((response) => {
      if (response?.status === 200) {
        const data = response && response.data && response.data.data;
        dispatch(DeleteQRCodeListSuccess(data));
        toast.success(`${response?.data?.message}`);
        callBack && callBack(response?.status);
      } else {
        console.log("data ee", response);
        toast.error(`${response?.data?.message}`);
        dispatch(DeleteQRCodeListFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(DeleteQRCodeListFailed(error));
  }
};

export const AddMasterQRCodeRequest = () => ({
  type: MASTERQRCODE_CREATE_REQUEST,
});
export const AddMasterQRCodeSuccess = (type) => ({
  type: MASTERQRCODE_CREATE_SUCCESS,
  payload: type,
});
export const AddMasterQRCodeFailed = (error) => ({
  type: MASTERQRCODE_CREATE_ERROR,
  payload: error,
});

export const CreateMasterQRCodeApi = (data, callBack) => (dispatch) => {
  try {
    dispatch(AddMasterQRCodeRequest(data));
    JwtAuthService.PostApiService(data).then((response) => {
      if (response?.status === 200) {
        const data = response && response.data && response.data.data;
        dispatch(AddMasterQRCodeSuccess(data));
        callBack && callBack(response?.status);
        toast.success(`${response?.data?.message}`);
      } else {
        toast.error(`${response?.data?.message}`);
        dispatch(AddMasterQRCodeFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(AddMasterQRCodeFailed(error));
  }
};

export const EditMasterQRCodeApi = (data, callBack) => (dispatch) => {
  try {
    dispatch(AddMasterQRCodeRequest(data));
    JwtAuthService.PutApiService(data).then((response) => {
      if (response?.status === 200) {
        const data = response && response.data && response.data.data;
        dispatch(AddMasterQRCodeSuccess(data));
        callBack && callBack(response?.status);
        toast.success(`${response?.data?.message}`);
      } else {
        toast.error(`${response?.data?.message}`);
        dispatch(AddMasterQRCodeFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(AddMasterQRCodeFailed(error));
  }
};

export const ProductItemsRequest = () => ({
  type: PRODUCT_ITEMS_REQUEST,
});
export const ProductItemsSuccess = (list) => ({
  type: PRODUCT_ITEMS_SUCCESS,
  payload: list,
});
export const ProductItemsFailed = (error) => ({
  type: PRODUCT_ITEMS_ERROR,
  payload: error,
});

export const ProductItemsInventoryApi = (data) => (dispatch) => {
  try {
    dispatch(ProductItemsRequest(data));
    JwtAuthService.PostApiService(data).then((response) => {
      if (response?.status === 200) {
        dispatch(ProductItemsSuccess(response));
      } else {
        dispatch(ProductItemsFailed(response?.data?.message));
        // toast.error(`${response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(ProductItemsFailed(error));
    console.error("Something went Wrong", error);
    // toast.error(error);
  }
};

export const DeleteProductItemsRequest = () => ({
  type: DELETE_PRODUCT_ITEMS_REQUEST,
});
export const DeleteProductItemsSuccess = (list) => ({
  type: DELETE_PRODUCT_ITEMS_SUCCESS,
  payload: list,
});
export const DeleteProductItemsFailed = (error) => ({
  type: DELETE_PRODUCT_ITEMS_ERROR,
  payload: error,
});

export const DeleteProductItemsInventoryApi =
  (data, callBack) => (dispatch) => {
    try {
      dispatch(DeleteProductItemsRequest(data));
      JwtAuthService.PostApiService(data).then((response) => {
        if (response?.status === 200) {
          dispatch(DeleteProductItemsSuccess(response));
          callBack && callBack();
        } else {
          dispatch(DeleteProductItemsFailed(response?.data?.message));
          // toast.error(`${response?.data?.message}`);
        }
      });
    } catch (error) {
      dispatch(DeleteProductItemsFailed(error));
      console.error("Something went Wrong", error);
      // toast.error(error);
    }
  };

export const EditProductItemsRequest = () => ({
  type: EDIT_PRODUCT_ITEMS_REQUEST,
});
export const EditProductItemsSuccess = (list) => ({
  type: EDIT_PRODUCT_ITEMS_SUCCESS,
  payload: list,
});
export const EditProductItemsFailed = (error) => ({
  type: EDIT_PRODUCT_ITEMS_ERROR,
  payload: error,
});

export const EditProductItemsInventoryApi = (data, callBack) => (dispatch) => {
  try {
    dispatch(EditProductItemsRequest(data));
    JwtAuthService.PutApiService(data).then((response) => {
      if (response?.status === 200) {
        dispatch(EditProductItemsSuccess(response));
        callBack && callBack();
      } else {
        dispatch(EditProductItemsFailed(response?.data?.message));
        // toast.error(`${response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(EditProductItemsFailed(error));
    console.error("Something went Wrong", error);
    // toast.error(error);
  }
};

export const AddNewProductItemsRequest = () => ({
  type: ADD_NEW_PRODUCT_ITEMS_REQUEST,
});
export const AddNewProductItemsSuccess = (list) => ({
  type: ADD_NEW_PRODUCT_ITEMS_SUCCESS,
  payload: list,
});
export const AddNewProductItemsFailed = (error) => ({
  type: ADD_NEW_PRODUCT_ITEMS_ERROR,
  payload: error,
});

export const AddNewProductItemsInventoryApi =
  (data, callBack) => (dispatch) => {
    try {
      dispatch(AddNewProductItemsRequest(data));
      JwtAuthService.PostApiService(data).then((response) => {
        if (response?.status === 200) {
          dispatch(AddNewProductItemsSuccess(response));
          callBack && callBack();
        } else {
          dispatch(AddNewProductItemsFailed(response?.data?.message));
        }
      });
    } catch (error) {
      dispatch(AddNewProductItemsFailed(error));
      console.error("Something went Wrong", error);
    }
  };

export const DeleteProductRequest = () => ({
  type: DELETE_PRODUCT_REQUEST,
});
export const DeleteProductSuccess = (list) => ({
  type: DELETE_PRODUCT_SUCCESS,
  payload: list,
});
export const DeleteProductFailed = (error) => ({
  type: DELETE_PRODUCT_ERROR,
  payload: error,
});

export const DeleteProductApi = (data, callBack) => (dispatch) => {
  try {
    dispatch(DeleteProductRequest(data));
    JwtAuthService.DeleteApiService(data).then((response) => {
      if (response?.status === 200) {
        dispatch(DeleteProductSuccess(response));
        callBack && callBack();
      } else {
        dispatch(DeleteProductFailed(response?.data?.message));
      }
    });
  } catch (error) {
    dispatch(DeleteProductFailed(error));
    console.error("Something went Wrong", error);
  }
};

export const MasterQRCodeViewRequest = () => ({
  type: MASTERBARCODEVIEW_REQUEST,
});
export const MasterQRCodeViewSuccess = (list) => ({
  type: MASTERBARCODEVIEW_SUCCESS,
  payload: list,
});
export const MasterQRCodeViewFailed = (error) => ({
  type: MASTERBARCODEVIEW_ERROR,
  payload: error,
});

export const MasterQRCodeViewInventoryApi = (data) => (dispatch) => {
  try {
    dispatch(MasterQRCodeViewRequest(data));
    JwtAuthService.GetApiService(data).then((response) => {
      console.log("response", response);
      if (response?.status === 200) {
        dispatch(MasterQRCodeViewSuccess(response));
      } else {
        dispatch(MasterQRCodeViewFailed(response?.data?.message));
        // toast.error(`${response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(MasterQRCodeViewFailed(error));
    console.error("Something went Wrong", error);
    // toast.error(error);
  }
};

export const DeleteItemRequest = () => ({
  type: DELETEITEM_REQUEST,
});
export const DeleteItemSuccess = (type) => ({
  type: DELETEITEM_SUCCESS,
  payload: type,
});
export const DeleteItemFailed = (error) => ({
  type: DELETEITEM_ERROR,
  payload: error,
});

export const DeleteItemApi = (data, callBack) => (dispatch) => {
  try {
    dispatch(DeleteItemRequest(data));
    JwtAuthService.PostApiService(data).then((response) => {
      if (response?.status === 200) {
        const data = response && response.data && response.data.data;
        dispatch(DeleteItemSuccess(data));
        toast.success(`${response?.data?.message}`);
        callBack && callBack(response?.status);
      } else {
        console.log("data ee", response);
        toast.error(`${response?.data?.message}`);
        dispatch(DeleteItemFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(DeleteItemFailed(error));
  }
};

export const MasterQRCodeViewItemViewRequest = () => ({
  type: MASTERBARCODEVIEW_ITEMVIEW_REQUEST,
});
export const MasterQRCodeViewItemViewSuccess = (list) => ({
  type: MASTERBARCODEVIEW_ITEMVIEW_SUCCESS,
  payload: list,
});
export const MasterQRCodeViewItemViewFailed = (error) => ({
  type: MASTERBARCODEVIEW_ITEMVIEW_ERROR,
  payload: error,
});

export const MasterQRCodeViewItemViewInventoryApi = (data) => (dispatch) => {
  try {
    dispatch(MasterQRCodeViewItemViewRequest(data));
    JwtAuthService.GetApiService(data).then((response) => {
      console.log("response", response);
      if (response?.status === 200) {
        dispatch(MasterQRCodeViewItemViewSuccess(response));
      } else {
        dispatch(MasterQRCodeViewItemViewFailed(response?.data?.message));
        // toast.error(`${response?.data?.message}`);
      }
    });
  } catch (error) {
    dispatch(MasterQRCodeViewFailed(error));
    console.error("Something went Wrong", error);
    // toast.error(error);
  }
};


export const UpdateItemToQRCodeRequest = () => ({
  type: UPDATE_ITEMTOQR_REQUEST,
});
export const UpdateItemToQRCodeSuccess = (type) => ({
  type: UPDATE_ITEMTOQR_SUCCESS,
  payload: type,
});
export const UpdateItemToQRCodeFailed = (error) => ({
  type: UPDATE_ITEMTOQR_ERROR,
  payload: error,
});

export const UpdateItemToQRCodeApi = (data, callBack) => (dispatch) => {
  try {
    dispatch(UpdateItemToQRCodeRequest(data));
    JwtAuthService.PutApiService(data).then((response) => {
      if (response?.status === 200) {
        const data = response && response.data && response.data.data;
        dispatch(UpdateItemToQRCodeSuccess(data));
        callBack && callBack(response?.status);
        toast.success(`${response?.data?.message}`);
      } else {
        toast.error(`${response?.response?.data?.message}`);
        dispatch(UpdateItemToQRCodeFailed(response?.statusText));
      }
    });
  } catch (error) {
    dispatch(UpdateItemToQRCodeFailed(error));
  }
};
