import { Autocomplete, Box, Container, Divider, FormControl, MenuItem, Paper, Select, TextField } from "@mui/material"
import { toast, ToastContainer } from "react-toastify";
import ProgressbarTransparent from "../../GlobalProgress/ProgressbarTransparent";
import { useFormik } from "formik";
import * as Yup from "yup";
import CreateProductHeader from "../component/CreateProductHeader";
import { BASE_URL } from "../../redux/apiConfig/BaseUrl";
import { AddNewProductItemsInventoryApi, CategoryListInventoryApi, CreateInventoryProductApi, EditProductItemsInventoryApi, ListProductStoreApi, TemplateListInventoryApi } from "../../redux/actions/Inventory";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Typography } from "material-ui-core";
import CreateProductQuestion from "./CreateProductQuestion";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import DropdownWithDetails from "../component/DropdownWithDetails";
import { confirmAlert } from "react-confirm-alert";

const CreateProduct = () => {
    let productData = localStorage.getItem("userId") && JSON.parse(localStorage.getItem("userId"));
    let productItemIds = localStorage.getItem("productItemIds") && JSON.parse(localStorage.getItem("productItemIds"));

    console.log("productData", productData);
    const loader = useSelector(
        (state) =>
        (state.inventory &&
            state.inventory.loading)
    );

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const templateData = useSelector(
        (state) =>
            (state.inventory &&
                state.inventory.templateData &&
                state.inventory.templateData.data &&
                state.inventory.templateData.data.data) ||
            []
    );

    // const storeData = useSelector(
    //     (state) =>
    //         (state.inventory &&
    //             state.inventory.store &&
    //             state.inventory.store.data &&
    //             state.inventory.store.data.data) ||
    //         []
    // );

    const categoryData = useSelector(
        (state) =>
            (state.inventory &&
                state.inventory.categoryDataInventory &&
                state.inventory.categoryDataInventory.data &&
                state.inventory.categoryDataInventory.data.data) ||
            []
    );

    const productItemsList = useSelector(
        (state) =>
        (state.inventory &&
            state.inventory.productItems &&
            state.inventory.productItems.data &&
            state.inventory.productItems.data.data)
    );

    const [productItems, setProductItems] = useState("");
    // const [selectedStore, setSelectedStore] = useState("");

    const [productName, setProductName] = useState("");
    const [description, setDescription] = useState("");
    const [category, setCategory] = useState([]);
    const [template, setTemplate] = useState([]);
    // const [stores, setStores] = useState([]);
    const [selectedTemplateQuestion, setSelectedTemplateQuestion] = useState();
    const [questionData, setQuestionData] = useState([]);
    const [categoryList, setCategoryList] = useState([]);

    const ListCatagory = () => {
        const id = localStorage.getItem("companyId");
        const data = {
            url: BASE_URL + `categories?company_id=${id}`,
        };
        dispatch(CategoryListInventoryApi(data));
    };

    useEffect(() => {
        ListCatagory();
    }, []);

    useEffect(() => {
        if (categoryData.length) {
            setCategoryList(categoryData);
        }
    }, [categoryData]);

    useEffect(() => {
        if (template && template.length) {
            if (productData.template_id) {
                const selectedtemplate = template.filter(item => item._id === productData.template_id);
                selectedtemplate.length && setSelectedTemplateQuestion(selectedtemplate[0]);
                selectedtemplate.length && setQuestionData(productData.productDetails.questions);
            }
        }
    }, [template]);

    // useEffect(() => {
    //     if (stores && stores?.length) {
    //         if (productData?.store?._id) {
    //             const selectedstore = stores.filter(item => item._id === productData?.store?._id);
    //             selectedstore.length && setSelectedStore(selectedstore[0]);
    //         }
    //     }
    // }, [stores]);

    useEffect(() => {
        if (productData) {
            setProductName(productData.productDetails.name);
            setDescription(productData.productDetails.description);
            setCategory(productData.productDetails.category);
        }
    }, [productData])

    const getTemplateList = () => {
        const id = localStorage.getItem("companyId");

        const api = {
            url: BASE_URL + `product-template?company_id=${id}`,
            body: {
                pagination: {
                    page: 1,
                    per_page: 500,
                }
            }
        }
        dispatch(TemplateListInventoryApi(api));
    }

    // const listStore = () => {
    //     const payloadRequest = {
    //         body: {
    //             page: 1,
    //             per_page: 200000,
    //             sort_order: -1,
    //             sort_by: "createdAt",
    //             type: "report",
    //         },
    //     };
    //     const id = localStorage.getItem("companyId");
    //     payloadRequest.url = BASE_URL + `stores/index?company_id=${id}`;
    //     dispatch(ListProductStoreApi(payloadRequest));
    // };

    useEffect(() => {
        getTemplateList();
        // listStore();
    }, []);

    useEffect(() => {
        if (templateData.finalData) {
            setTemplate(templateData.finalData);
        };
    }, [templateData]);

    // useEffect(() => {
    //     if (storeData?.stores) {
    //         setStores(storeData.stores);
    //     }
    // }, [storeData]);

    const validateQuestionsAnswered = () => {
        for (let i = 0; i < questionData.length; i++) {
            const question = questionData[i];

            switch (question?.type?.key) {
                case "boolean":
                    if (
                        (question?.type?.validations?.[0]?.key === "required" && question?.type?.validations?.[0]?.answer?.[0]?.value) && (
                            question?.answer?.answer === undefined || question?.answer?.answer === null)
                    ) {
                        toast.error(`Select an answer for question ${i + 1} first!`);
                        return false;
                    }
                    break;

                case "text":
                    if (
                        (question?.type?.validations?.[0]?.key === "required" && question?.type?.validations?.[0]?.answer?.[0]?.value) && (
                            typeof question?.answer?.answer?.value === "undefined" ||
                            question?.answer?.answer?.value === null ||
                            (typeof question?.answer?.answer?.value === "string" &&
                                question?.answer?.answer?.value.trim() === "")
                        )
                    ) {
                        toast.error(`Answer question ${i + 1} first!`);
                        return false;
                    }
                    if ((question?.type?.validations?.[0]?.key === "required" && question?.type?.validations?.[0]?.answer?.[0]?.value) && (
                        question?.answer?.answer?.value === "")) {
                        toast.error(`Answer question ${i + 1} first!`);
                        return false;
                    }
                    break;

                case "number":
                    if (
                        (question?.type?.validations?.[0]?.key === "required" && question?.type?.validations?.[0]?.answer?.[0]?.value) && (
                            question?.answer?.answer?.value === undefined ||
                            (typeof question?.answer?.answer?.value === "string" &&
                                question?.answer?.answer?.value.trim() === "")
                        )
                    ) {
                        toast.error(`Answer question ${i + 1} first!`);
                        return false;
                    }
                    break;

                case "single choice":
                    if (
                        (question?.type?.validations?.[0]?.key === "required" && question?.type?.validations?.[0]?.answer?.[0]?.value) && (
                            question?.answer?.answer?.title === undefined ||
                            question?.answer?.answer?.title === null)
                    ) {
                        toast.error(`Select an answer for question ${i + 1} first!`);
                        return false;
                    }
                    break;

                case "multiple choice":
                    if (
                        (question?.type?.validations?.[0]?.key === "required" && question?.type?.validations?.[0]?.answer?.[0]?.value) && (
                            question?.answer?.answer === undefined ||
                            question?.answer?.answer === null ||
                            (Array.isArray(question?.answer?.answer) && question?.answer?.answer.length === 0))
                    ) {
                        toast.error(`Select an answer for question ${i + 1} first!`);
                        return false;
                    }
                    break;

                case "file":
                    if ((question?.type?.validations?.[0]?.key === "required" && question?.type?.validations?.[0]?.answer?.[0]?.value) && (
                        !question?.answer?.answer?.answer.length
                    )) {
                        toast.error(`Upload a attached file for question ${i + 1} first!`);
                        return false;
                    } else if (
                        (question?.type?.validations?.[0]?.key === "required" && question?.type?.validations?.[0]?.answer?.[0]?.value) && (
                            question?.answer?.answer?.answer === undefined ||
                            question?.answer?.answer?.answer === null ||
                            !question?.answer?.answer?.answer.length)
                    ) {
                        toast.error(`Attach a file for question ${i + 1} first!`);
                        return false;
                    }
                    break;

                case "date":
                    if (
                        (question?.type?.validations?.[0]?.key === "required" && question?.type?.validations?.[0]?.answer?.[0]?.value) && (
                            question?.answer?.answer?.value === undefined ||
                            question?.answer?.answer?.value === null)
                    ) {
                        toast.error(`Provide a valid date for question ${i + 1} first!`);
                        return false;
                    }
                    break;

                case "datetime":
                    if (
                        (question?.type?.validations?.[0]?.key === "required" && question?.type?.validations?.[0]?.answer?.[0]?.value) && (
                            question?.answer?.answer?.date === undefined ||
                            question?.answer?.answer?.time === undefined ||
                            question?.answer?.answer?.date === null ||
                            question?.answer?.answer?.time === null)
                    ) {
                        toast.error(
                            `Provide a valid date and time for question ${i + 1} first!`
                        );
                        return false;
                    }
                    break;

                case "time":
                    if (
                        (question?.type?.validations?.[0]?.key === "required" && question?.type?.validations?.[0]?.answer?.[0]?.value) && (
                            question?.answer?.answer?.value === undefined ||
                            question?.answer?.answer?.value === null)
                    ) {
                        toast.error(`Provide a valid time for question ${i + 1} first!`);
                        return false;
                    }
                    break;

                case "timer":
                    if (
                        (question?.type?.validations?.[0]?.key === "required" && question?.type?.validations?.[0]?.answer?.[0]?.value) && (
                            question?.answer?.answer?.value === undefined ||
                            question?.answer?.answer?.value === 0 ||
                            question?.answer?.answer?.value === null)
                    ) {
                        toast.error(`Start the timer for question ${i + 1} first!`);
                        return false;
                    }
                    break;

                default:
                    break;
            }
        }
        return true;
    };

    const handleCancel = () => {
        localStorage.removeItem("addTemplate")
        localStorage.removeItem("questions")
        if (!location?.pathname.includes('/inventory-product/add-product-item')) {
            localStorage.removeItem("userId")
        }
    };

    const callback = () => {
        if (location.pathname === '/inventory-product/edit-product-item') {
            navigate("/inventory-product/view/product-items")
        } else if (location?.pathname === '/inventory-product/add-product-item') {
            navigate("/inventory-product")
        } else {
            navigate("/product-list");
        }
    };

    const handleFinalSubmit = () => {
        if (location.pathname !== "/inventory-product/edit-product-item") {
            if (!productItems) {
                toast.error(`Please enter product items`);
                return;
            } 
            // else if (!selectedStore?._id) {
            //     toast.error(`Please select store`);
            //     return;
            // }
             else if (!selectedTemplateQuestion?._id) {
                toast.error(`Please select template`);
                return;
            }
        }

        if (validateQuestionsAnswered()) {
            const id = localStorage.getItem("companyId");
            const data = {
                productDetails: {
                    questions: questionData
                }
            };

            if (location.pathname === "/inventory-product/add-product-item") {
                data.productItems = parseInt(productItems);
                data._id = productData._id;
                data.productDetails.category = category

                const api = {
                    url: BASE_URL + `add-more-inventory-product-items?company_id=${id}`,
                    body: data
                }
                dispatch(AddNewProductItemsInventoryApi(api, callback));
            } else if (location.pathname === "/inventory-product/edit-product-item") {
                console.log("productItemsList", productItemsList);
                const getSerialNumbers = productItemsList?.finalData?.map(item => productItemIds.includes(item._id) && item.serialNumber).filter(item => item);
                data.ids = productItemIds;

                confirmAlert({
                    title: "Confirm to edit",
                    message: "Are you sure to do edit below products -" + getSerialNumbers.join(', '),
                    buttons: [
                        {
                            label: "Yes",
                            onClick: () => {
                                const api = {
                                    url: BASE_URL + `inventory-product-items?company_id=${id}`,
                                    body: data
                                }
                                dispatch(EditProductItemsInventoryApi(api, callback));
                            },
                        },
                        {
                            label: "No",
                        },
                    ],
                });

            } else {
                data.template_id = selectedTemplateQuestion?._id;
                // data.store = {
                //     _id: selectedStore?._id,
                //     name: selectedStore?.name,
                // };
                data.productItems = productItems;
                data.productDetails.name = productName
                data.productDetails.description = description
                data.productDetails.category = category

                const api = {
                    url: BASE_URL + `inventory-product?company_id=${id}`,
                    body: data
                }
                dispatch(CreateInventoryProductApi(api, callback));
            };

        }
    };

    return (
        <>
            <ToastContainer autoClose={3000} />
            {loader && (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100vh",
                    }}
                >
                    <ProgressbarTransparent play />
                </div>)
            }
            <CreateProductHeader handleFinalSubmit={handleFinalSubmit} handleCancel={handleCancel} />
            <Box sx={{ bgcolor: "#F6F8FC", p: 1, borderRadius: "4px" }}>
                <Container
                    maxWidth="xl"
                    sx={{
                        backgroundColor: "#fff",
                        borderRadius: "10px",
                        p: 1,
                        textAlign: "left",
                    }}
                >
                    {location.pathname !== "/inventory-product/edit-product-item" && <Box>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <label>Product name</label>
                            <TextField
                                id="outlined-basic"
                                variant="outlined"
                                size="small"
                                name="productItems"
                                onChange={(e) => setProductName(e.target.value)}
                                value={productName}
                                className="date-input"
                            />
                        </FormControl>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <label>Description</label>
                            <TextField
                                id="outlined-basic"
                                variant="outlined"
                                size="small"
                                name="productItems"
                                onChange={(e) => setDescription(e.target.value)}
                                value={description}
                                className="date-input"
                            />
                        </FormControl>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <label>Category</label>
                            <Select
                                value={category || ''}
                                onChange={(e) => {
                                    setCategory(e.target.value);
                                }}
                                name={``}
                                displayEmpty
                                size="small"
                                placeholder="Measurement"
                                sx={{ width: "100%", paddingX: "1px" }}
                            >
                                <MenuItem
                                    value="0"
                                    sx={{
                                        color: "gray",
                                    }}
                                    disabled
                                >
                                    <em>Select category</em>
                                </MenuItem>
                                {categoryList.map(item => {
                                    return <MenuItem value={item.title}>{item.title}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <label>No of product items</label>
                            <TextField
                                id="outlined-basic"
                                variant="outlined"
                                size="small"
                                name="productItems"
                                onChange={(e) => setProductItems(e.target.value)}
                                value={productItems}
                                className="date-input"
                                type="number"
                            />
                        </FormControl>
                        {/* <FormControl sx={{ width: "100%", mb: 2 }}>
                            <Box>Store</Box>
                            <Autocomplete
                                id="country-select-demo"
                                sx={{ width: "100%", paddingX: "1px" }}
                                options={stores}
                                value={(selectedStore && stores?.length) ? selectedStore : ""}
                                onChange={(event, newValue) => setSelectedStore(newValue)}
                                getOptionLabel={(option) => option?.name || ''}
                                renderOption={(props, option) => {
                                    const { key, ...optionProps } = props;
                                    return (
                                        <Box {...optionProps}>
                                            <Typography>
                                                {option.name}
                                            </Typography>
                                        </Box>
                                    );
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        sx={{
                                            padding: "0px"
                                        }}
                                    // label="Template"
                                    />
                                )}
                                size="small"
                                disabled={productData?.template_id}
                            />
                        </FormControl> */}
                        <FormControl sx={{ width: "100%", mb: 2 }}>
                            <Box>Template</Box>
                            {/* <Autocomplete
                                id="country-select-demo"
                                sx={{ width: "100%", paddingX: "1px" }}
                                options={template}
                                value={selectedTemplateQuestion}
                                onChange={(event, newValue) => { setSelectedTemplateQuestion(newValue); setQuestionData(newValue.question); }}
                                getOptionLabel={(option) => option.name}
                                renderOption={(props, option) => {
                                    const { key, ...optionProps } = props;
                                    return (
                                        <Box {...optionProps}>
                                            <Box key={key} sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: "column", width: '100%' }} pl={2}
                                                pr={2} >
                                                <Box>
                                                    <Typography style={{
                                                        fontWeight: "bold"
                                                    }}>
                                                        {option.name}
                                                    </Typography>
                                                </Box>
                                                <Box sx={{
                                                    display: "flex",
                                                    width: "100%",
                                                    alignItems: "center",
                                                    marginTop: "5px",
                                                    gap: "16px"
                                                }}>
                                                    <Typography style={{
                                                        fontSize: "12px",
                                                        color: "#64748b"
                                                    }}>
                                                        {moment(option?.updatedAt).format("ll")}
                                                    </Typography>
                                                    <Typography style={{
                                                        fontSize: "12px",
                                                        color: "#64748b"
                                                    }}>
                                                        Desc: {option.description}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                    );
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        sx={{
                                            padding: "0px"
                                        }}
                                    />
                                )}
                                size="small"
                            /> */}
                            <DropdownWithDetails
                                id="country-select-demo"
                                options={template}
                                value={selectedTemplateQuestion}
                                handleChange={(newValue) => { setSelectedTemplateQuestion(newValue); setQuestionData(newValue.question); }}
                                details={
                                    [
                                        {
                                            title: "",
                                            value: "updatedAt",
                                            type: "date",
                                            format: "ll"
                                        },
                                        {
                                            title: "Desc",
                                            value: "description"
                                        },
                                    ]
                                }
                                disabled={productData.template_id}
                            />
                        </FormControl>
                    </Box>}
                    {selectedTemplateQuestion &&
                        <CreateProductQuestion questionData={questionData} setQuestionData={setQuestionData} />
                    }
                </Container>
            </Box>
        </>
    )
}

export default CreateProduct;