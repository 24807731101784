import { Box, Button, Card, Container, Grid, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import DoneIcon from "@mui/icons-material/Done";
import ViewProductItems from "./ViewProductItems";
import moment from "moment";

const ViewInventoryProduct = () => {
    let productData = localStorage.getItem("userId") && JSON.parse(localStorage.getItem("userId"));

    return (
        <Box
            sx={{ bgcolor: "#F6F8FC", borderRadius: "4px", mb: 1 }}
            className="header-card"
        >
            <Grid container spacing={1} alignItems="center" sx={{
                padding: "0px 8px"
            }}>
                <Grid item xs={12} lg={12} md={12} sm={12}>
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="flex-end"
                    >
                        <Stack direction="row" alignItems="center">
                            <Link
                                to="/inventory-product"
                                className="submenu-item"
                            >
                                <Button
                                    className="header-add cancle-hover"
                                    sx={{
                                        whiteSpace: "nowrap",
                                        ml: 1,
                                        height: "42px",
                                        borderRadius: "8px",
                                    }}
                                >
                                    <DoneIcon sx={{ mr: 1 }} />
                                    Done
                                </Button>
                            </Link>
                        </Stack>
                    </Stack>
                </Grid>
            </Grid>
            {" "}
            {/* <Container> */}
            <Grid spacing={1} sx={{ textAlign: "left", mt: 1, p: 4 }}>
                <Grid item xs={12}>
                    <Typography sx={{ marginBottom: "16px !important" }} className="form-head">
                        <b>Product Details</b>
                    </Typography>
                    <Card sx={{
                        mb: 2,
                        boxShadow: "none",
                        borderRadius: "8px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "left",
                        padding: "16px",
                        flexDirection: "column",
                        gap: "10px"
                    }}>
                        <div>
                            Product Name: <b>{productData?.productDetails?.name}</b>
                        </div>
                        <div>
                            Product description: <b>{productData?.productDetails?.description}</b>
                        </div>
                        <div>
                            Created At: <b>{moment(productData?.createdAt).format('ll')}</b>
                        </div>
                        <div>
                            Updated At: <b>{moment(productData?.updatedAt).format('ll')}</b>
                        </div>
                        <div>
                            Total Product items: <b>{productData?.productItems}</b>
                        </div>
                    </Card>
                </Grid>

                <Grid item xs={12}>
                    <ViewProductItems />
                </Grid>

                {/* <Grid item xs={12}>
                    <div>
                        {productData?.productDetails?.questions?.map((item, i) => {
                            let result = "-";
                            if (item?.title === "Product weight & product measurement" && item?.answer?.answer?.value) {
                                result = `${item?.answer?.answer?.value.weight} ${item?.answer?.answer?.value.measurement}`
                            } else if (item?.answer?.type != "boolean" && item?.answer?.answer?.value) {
                                result = `${item?.answer?.answer?.value}`
                            } else if (item?.answer?.answer?.title) {
                                result = `${item?.answer?.answer?.title}`;
                            } else if (item?.answer?.answer?.length) {
                                result = item.answer.answer.map(ans => ans.title).join(',');
                            } else if (item?.answer?.answer.date && item?.answer?.answer?.time) {
                                result = `${item.answer.answer.date} ${item.answer.answer.time}`
                            } else if (item?.answer?.answer?.answer?.length) {
                                const mediaresult = item.answer.answer.answer.map(ans => `https://popprobe-saas.s3.us-west-2.amazonaws.com/${ans}`);
                                result = mediaresult.join(',');
                            }

                            return (
                                <Card
                                    sx={{ mb: 2, boxShadow: "none", borderRadius: "8px" }}
                                    key={i}
                                >

                                    <ol style={{ listStyle: "none" }} className="list-style">
                                        <LightTooltip placement="top-start" title={<>
                                            <Box>
                                                <b>Description:</b> {item && item.desc && `${item.desc} `}
                                            </Box>
                                            <Box>
                                                <b>Category:</b> {item && item.category && `${item.category}`}
                                            </Box>
                                        </>}>
                                            <li className="list-form" style={{
                                                cursor: "pointer"
                                            }}>
                                                <b>
                                                    {i + 1}. {item?.title}
                                                </b>
                                            </li>
                                        </LightTooltip>
                                        <div
                                            className="list-form"
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                marginBottom: "10px",
                                            }}
                                        >
                                            <div>- {result}</div>
                                        </div>
                                    </ol>
                                </Card>
                            )
                        })}
                    </div>
                </Grid> */}
            </Grid>
            {/* </Container> */}
        </Box>
    )
};

export default ViewInventoryProduct;