import {
  PAYMENT_ERROR,
  PAYMENT_REQUEST,
  PAYMENT_SUCCESS,
  PAYMENT_DONE_REQUEST,
  PAYMENT_DONE_SUCCESS,
  PAYMENT_DONE_ERROR,
  COMPANY_INFO_DETAIL_REQUEST,
  COMPANY_INFO_DETAIL_SUCCESS,
  COMPANY_INFO_DETAIL_ERROR,
  PAYMENT_HISTORY_LIST_ERROR,
  PAYMENT_HISTORY_LIST_REQUEST,
  PAYMENT_HISTORY_LIST_SUCCESS,
  PAYMENT_HISTORY_ERROR,
  PAYMENT_HISTORY_REQUEST,
  PAYMENT_HISTORY_SUCCESS,
  MANUAL_PAYMENT_REQUEST,
  MANUAL_PAYMENT_SUCCESS,
  MANUAL_PAYMENT_ERROR,
  PAYNOW_REQUEST,
  PAYNOW_SUCCESS,
  PAYNOW_ERROR,
  CREDIT_REQUEST,
  CREDIT_SUCCESS,
  CREDIT_ERROR,
} from "../types/Types";

const initialState = {
  maunalPayment: {},
  PaymentHistoryListLoading: false,
  PaymentHistoryList: [],
  PaymentHistoryActivity: [],
  PaymentList: [],
  CreditList: [],
  PaymentDoneList: [],
  CompanyInfoDetailList: [],
  loading: false,
  paymentLoading: false,
  error: "",
  CompanyInfoDetailListError: "",
};

const PaymentHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case PAYMENT_HISTORY_LIST_REQUEST: {
      return {
        ...state,
        PaymentHistoryListLoading: true,
        error: "",
      };
    }
    case PAYMENT_HISTORY_LIST_SUCCESS: {
      console.log(action, action.payload);
      return {
        ...state,
        PaymentHistoryList: action.payload,
        PaymentHistoryListLoading: false,
      };
    }
    case PAYMENT_HISTORY_LIST_ERROR: {
      return {
        ...state,
        PaymentHistoryListLoading: false,
        error: action.error,
      };
    }
    case PAYMENT_HISTORY_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case PAYMENT_HISTORY_SUCCESS: {
      return {
        ...state,
        PaymentHistoryActivity: action.payload,
        loading: false,
      };
    }
    case PAYMENT_HISTORY_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case PAYMENT_REQUEST: {
      return {
        ...state,
        paymentLoading: true,
        error: "",
      };
    }
    case PAYMENT_SUCCESS: {
      return {
        ...state,
        PaymentList: action.payload,
        paymentLoading: false,
      };
    }
    case PAYMENT_ERROR: {
      return {
        ...state,
        paymentLoading: false,
        error: action.error,
      };
    }
    case CREDIT_REQUEST: {
      return {
        ...state,
        paymentLoading: true,
        error: "",
      };
    }
    case CREDIT_SUCCESS: {
      return {
        ...state,
        CreditList: action.payload,
        paymentLoading: false,
      };
    }
    case CREDIT_ERROR: {
      return {
        ...state,
        paymentLoading: false,
        error: action.error,
      };
    }
    case PAYMENT_DONE_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case PAYMENT_DONE_SUCCESS: {
      return {
        ...state,
        PaymentDoneList: action.payload,
        loading: false,
      };
    }
    case PAYMENT_DONE_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    case PAYNOW_REQUEST: {
      return {
        ...state,
        paymentLoading: true,
        error: "",
      };
    }
    case PAYNOW_SUCCESS: {
      return {
        ...state,
        PayNowList: action.payload,
        paymentLoading: false,
      };
    }
    case PAYNOW_ERROR: {
      return {
        ...state,
        paymentLoading: false,
        error: action.error,
      };
    }

    case COMPANY_INFO_DETAIL_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
        CompanyInfoDetailListErr: "",
      };
    }
    case COMPANY_INFO_DETAIL_SUCCESS: {
      return {
        ...state,
        CompanyInfoDetailList: action.payload,
        loading: false,
        error: "",
        CompanyInfoDetailListErr: "",
      };
    }
    case COMPANY_INFO_DETAIL_ERROR: {
      return {
        ...state,
        loading: false,
        CompanyInfoDetailListError: action.payload,
      };
    }
    case MANUAL_PAYMENT_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case MANUAL_PAYMENT_SUCCESS: {
      return {
        ...state,
        maunalPayment: action.payload,
        loading: false,
      };
    }
    case MANUAL_PAYMENT_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
};

export default PaymentHistoryReducer;
