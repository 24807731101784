import { Box, Grid, IconButton, Tooltip, tooltipClasses } from "@mui/material";
import ProgressbarTransparent from "../GlobalProgress/ProgressbarTransparent";
import { ToastContainer } from "react-toastify";
import TemplateHeader from "./component/TemplateHeader";
import { DeleteTemplateApi, TemplateListInventoryApi } from "../redux/actions/Inventory";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { BASE_URL } from "../redux/apiConfig/BaseUrl";
import { useSelector } from "react-redux";
import SingleGlobalTable from "../GlobalComponent/SingleGlobalTable";
import { styled } from "material-ui-core";
import LastPageIcon from "@mui/icons-material/LastPage";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import View from "../images/View.svg";
import ViewHover from "../images/ViewHover.svg";
import EditHover from "../images/EditHover.svg";
import Edit from "../images/Edit.svg";
import Delete from "../images/Delete.svg";
import DeleteHover from "../images/DeleteHover.svg";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import moment from "moment";

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: "rgba(0, 0, 0, 0.87)",
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}));

const Template = () => {
    // const loader = false;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const permissions = localStorage.getItem("permissionData");
    const TemplateFilter = localStorage.getItem("TemplateFilter");
    const filterData = TemplateFilter && JSON.parse(TemplateFilter);
    const [permission, setPermission] = useState({});

    const templateData = useSelector(
        (state) =>
            (state.inventory &&
                state.inventory.templateData &&
                state.inventory.templateData.data &&
                state.inventory.templateData.data.data) ||
            []
    );
    const loader = useSelector(
        (state) =>
        (state.inventory &&
            state.inventory.loading)
    );

    const [data, setData] = useState([]);
    const [page, setPage] = useState(filterData?.body?.pagination?.page || 1);
    const [rowsPerPage, setRowsPerPage] = useState(
        filterData?.body?.pagination?.per_page || 9
      );
    const [title, setTitle] = useState("");
    const [pageNo, setPageNo] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [indexHover, setIndexHover] = useState(0);
    const [hover, setHover] = useState({
        View: false,
    });

    const columns = [
        {
            field: "S.No.",
            headerName: "S.No.",
            renderHeader: () => (
                <LightTooltip title={"S.No"}>
                    <strong style={{ marginLeft: "0%" }}>{"S.No"}</strong>
                </LightTooltip>
            ),
            flex: 1,
            align: "center",
            headerAlign: "center",
            valueGetter: (params) => params.rowIndex + 1 + (pageNo - 1) * perPage,
            renderCell: (params) => {
                const d = data || [];
                const index =
                    d.map(e => e._id).indexOf(params.row._id) + 1 + (pageNo - 1) * perPage;

                return (
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            height: "100%",
                            minWidth: "50px",
                        }}
                    >
                        <p
                            className="text-short"
                            style={{
                                margin: "0 auto",
                                textAlign: "center",
                                fontSize: "16px",
                            }}
                        >
                            {index}
                        </p>
                    </div>
                );
            },
        },

        {
            field: "Name",
            headerName: "Name",
            renderHeader: () => <LightTooltip title={"Name"}>
                <strong>{"Name"}</strong>
            </LightTooltip>,
            flex: 2,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                let result = [];
                if (params?.row?.name) {
                    result.push(params?.row?.name);
                } else {
                    result = ["N/A"];
                }
                let results = result.join(", ");
                return (
                    <div
                        style={{
                            display: "flex",
                            width: "133px",
                        }}
                    >
                        <LightTooltip title={results}>
                            <p
                                className="text-short"
                                style={{ marginLeft: "10px", fontWeight: "normal" }}
                            >
                                {results}
                            </p>
                        </LightTooltip>
                    </div>
                );
            },
        },
        {
            field: "Description",
            headerName: "Description",
            renderHeader: () => <LightTooltip title={"Description"}>
                <strong>{"Description"}</strong>
            </LightTooltip>,
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                let result = [];
                if (params?.row?.description) {
                    result.push(params?.row?.description);
                } else {
                    result = ["Unknown"];
                }
                let results = result.join(", ");
                return (
                    <LightTooltip title={results}>
                        <p className="text-short"> {results}</p>
                    </LightTooltip>
                );
            },
        },
        {
            field: "CreatedAt",
            headerName: "CreatedAt",
            renderHeader: () => <LightTooltip title={"CreatedAt"}>
                <strong>{"CreatedAt"}</strong>
            </LightTooltip>,
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                let result = [];
                if (params?.row?.createdAt) {
                    result.push(params?.row?.createdAt);
                } else {
                    result = ["0"];
                }
                let results = result.join(", ");
                return (
                    <LightTooltip title={results}>
                        <p className="text-short"> {moment(results).format('ll')}</p>
                    </LightTooltip>
                );
            },
        },
        {
            field: "UpdatedAt",
            headerName: "UpdatedAt",
            renderHeader: () => <LightTooltip title={"UpdatedAt"}>
                <strong>{"UpdatedAt"}</strong>
            </LightTooltip>,
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                let result = [];
                if (params?.row?.updatedAt) {
                    result.push(params?.row?.updatedAt);
                } else {
                    result = ["N/A"];
                }
                let results = result.join(", ");
                return (
                    <LightTooltip title={results}>
                        <p className="text-short"> {moment(results).format('ll')}</p>
                    </LightTooltip>
                );
            },
        },
        {
            field: "Total Questions",
            headerName: "Total Questions",
            renderHeader: () => <LightTooltip title={"Total Questions"}><strong>{"Total Questions"}</strong></LightTooltip>,

            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                let result = [];
                if (params?.row?.question) {
                    result.push(params?.row?.question.length);
                } else {
                    result = ["N/A"];
                }
                let results = result.join(", ");
                return (
                    <LightTooltip title={results}>
                        <p className="text-short"> {results}</p>
                    </LightTooltip>
                );
            },
        },
        {
            field: "Action",
            headerName: "Action",
            renderHeader: () => <LightTooltip title={"Action"}>
                <strong>{"Action"}</strong>
            </LightTooltip>,
            flex: 1,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                const viewBtnStyle = {
                    cursor: "pointer",
                    margin: "0px 3px 0px 3px",
                    height: "32px"
                };

                return (
                    <div style={{
                        display: 'flex'
                    }}>
                        <Tooltip title="View">
                            <img
                                src={
                                    hover.View && indexHover === params?.row?._id
                                        ? ViewHover
                                        : View
                                }
                                alt="VIEW"
                                style={{
                                    ...viewBtnStyle,
                                }}
                                onClick={() => handleView(params?.row)}
                                onMouseOver={() => {
                                    setHover({ ...hover, View: true });
                                    setIndexHover(params?.row?._id);
                                }}
                                onMouseLeave={() => {
                                    setHover({ ...hover, View: false });
                                }}
                            />
                        </Tooltip>
                        {permission?.logEntry?.manageLogEntry && (
                         <>
                        <Tooltip title="Edit">
                            <img
                                onClick={() => handleEditDetails(params?.row)}
                                src={
                                    hover.Edit && indexHover === params?.row?._id
                                        ? EditHover
                                        : Edit
                                }
                                alt="Edit"
                                style={{
                                    ...viewBtnStyle,
                                }}
                                onMouseOver={() => {
                                    setHover({ Edit: true });
                                    setIndexHover(params?.row?._id);
                                }}
                                onMouseLeave={() => {
                                    setHover({ Edit: false });
                                }}
                            />
                        </Tooltip>
                        <Tooltip title="Delete">
                            <img
                                onClick={() => handleDelete(params?.row)}
                                src={
                                    hover.Delete && indexHover === params?.row?._id
                                        ? DeleteHover
                                        : Delete
                                }
                                alt="Delete"
                                style={{
                                    height: "35px",
                                    cursor: "pointer",
                                    marginBottom: "5px",
                                }}
                                onMouseOver={() => {
                                    setHover({ Delete: true });
                                    setIndexHover(params?.row?._id);
                                }}
                                onMouseLeave={() => {
                                    setHover({ Delete: false });
                                }}
                            />
                        </Tooltip>
                        </>
                        )}
                    </div>
                );
            },
        }
    ];

    const handleEditDetails = (row) => {
        navigate("/template/edit");
        localStorage.setItem("userId", JSON.stringify(row));
    };

    const handleView = (row) => {
        navigate('/template/view');
        localStorage.setItem("userId", JSON.stringify(row));
    };

    const handleDelete = (values) => {
        confirmAlert({
            title: "Confirm to delete",
            message: "Are you sure to do this.",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => {
                        const id = localStorage.getItem("companyId");
                        const infoId = values?._id;
                        console.log("logEntryId", infoId);
                        const data = {
                            url: BASE_URL + `product-template/${infoId}?company_id=${id}`,
                        };
                        dispatch(DeleteTemplateApi(data, getTemplateList));
                    },
                },
                {
                    label: "No",
                },
            ],
        });
    };
       
    useEffect(() => {
        if (permissions) {
          const newDAta = JSON.parse(permissions);
          setPermission(newDAta);
        }
      }, [permissions]);

    useEffect(() => {
        if (templateData.finalData) {
            setData(templateData.finalData);
        }
    }, [templateData]);

    const TemplateListRequest = {
        body: {
          pagination: {
            page: page,
            per_page: rowsPerPage,
          },
          search: title,
        },
      };
    
    const [filterValues, setFilterValues] = useState({ });

    const handleFilterData = (filterValues) => {
        // Handle the data further as needed
        if (filterValues?.search) {
          TemplateListRequest.body.search = filterValues.search;
        } else {
          delete TemplateListRequest.body.search;
        }
        // Update pagination and other properties
        TemplateListRequest.body.pagination.page = 1;
    
        // Update component state
        setTitle(filterValues?.search);
        //setStatus(filterValues?.status);
        setPage(1);
    
        // Perform the API call or further processing
        getTemplateList(TemplateListRequest);
    
        // Save the current filter in localStorage
        localStorage.setItem("templateFilter", JSON.stringify(TemplateListRequest));
      };

    const getTemplateList = () => {
        const id = localStorage.getItem("companyId");

        const api = {
            url: BASE_URL + `product-template?company_id=${id}`,
            ...TemplateListRequest,
        }
        dispatch(TemplateListInventoryApi(api));
    }

    useEffect(() => {
        getTemplateList();
        localStorage.setItem("userId", "");
    }, []);


    const handleChangePage = (event, newPage) => {
        // submissionPayload.body.pagination.page = newPage + 1;
        setPageNo(newPage + 1);
        // fetchLosEntryESubmission();
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        // submissionPayload.body.pagination.page = 1;
        // submissionPayload.body.pagination.per_page = parseInt(event.target.value, 10);
        setPerPage(event.target.value);
        // fetchLosEntryESubmission();
    }

    function TablePaginationActions(props) {
        const classes = useStyles1();
        const theme = useTheme();
        const { count, page, rowsPerPage, onPageChange, showFirstButton } = props;

        const handleFirstPageButtonClick = (event) => {
            onPageChange(event, 0);
        };

        const handleBackButtonClick = (event) => {
            onPageChange(event, page - 1);
        };

        const handleNextButtonClick = (event) => {
            onPageChange(event, page + 1);
        };

        const handleLastPageButtonClick = (event) => {
            onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
        };

        return (
            <div className={classes.root}>
                {showFirstButton && (
                    <IconButton
                        onClick={handleFirstPageButtonClick}
                        disabled={page === 0}
                        aria-label="first page"
                    >
                        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
                    </IconButton>
                )}
                <IconButton
                    onClick={handleBackButtonClick}
                    disabled={page === 0}
                    aria-label="previous page"
                >
                    {theme.direction === "rtl" ? (
                        <KeyboardArrowRight />
                    ) : (
                        <KeyboardArrowLeft />
                    )}
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="next page"
                >
                    {theme.direction === "rtl" ? (
                        <KeyboardArrowLeft />
                    ) : (
                        <KeyboardArrowRight />
                    )}
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="last page"
                >
                    {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
                </IconButton>
            </div>
        );
    }

    TablePaginationActions.propTypes = {
        count: PropTypes.number.isRequired,
        onPageChange: PropTypes.func.isRequired,
        page: PropTypes.number.isRequired,
        rowsPerPage: PropTypes.number.isRequired,
    };


    return (
        <Box>
            <ToastContainer autoClose={3000} />
            <TemplateHeader onFilter={handleFilterData} permission={permission} />
            {loader && (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100vh",
                    }}
                >
                    <ProgressbarTransparent play />
                </div>)
            }
            <Box
                sx={{
                    bgcolor: "#F6F8FC",
                    height: "calc(100vh - 280px)",
                    mt: 1,
                    borderRadius: "8px",
                    overflowX: "hidden",
                    overflowY: "scroll",
                    "&::-webkit-scrollbar": {
                        width: 10,
                    },
                    "&::-webkit-scrollbar-track": {
                        padding: "12px 5px",
                        backgroundColor: "#CBD4E1",
                    },
                    "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "#64748B",
                        borderRadius: 8,
                    },
                }}
            >
                {/* <Grid container spacing={1} style={{
                    display: "flex",
                    flexWrap: "wrap",
                    width: "100%",
                    padding: '20px'
                }}> */}
                <SingleGlobalTable
                    data={data}
                    columns={columns}
                    totalCount={templateData?.paginate?.total_item || 0}
                    loading={loader}
                    page={pageNo}
                    rowsPerPage={perPage}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                />
                {/* </Grid> */}
            </Box>
        </Box>
    )
};

export default Template;