import {
  Box,
  Grid,
  Typography,
  Button,
  Modal,
  useMediaQuery,
  createTheme,
  ThemeProvider,
  useTheme,
} from "@mui/material";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import ViewProductItemsForQRCode from "./ViewProductItemsForQRCode";
import { MasterQRCodeListInventoryApi, UpdateItemToQRCodeApi } from "../../redux/actions/Inventory";
import { BASE_URL } from "../../redux/apiConfig/BaseUrl";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";

const MasterQRCodeViewHeader = ({ data, masterQRId }) => {
  console.log("props data in MasterQRCodeViewHeader", data);
  const theme = createTheme({
    palette: {
      primary: {
        main: "#196773",
      },
    },
  });
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [formData, setFormData] = useState(data);
  const [productsId, setProductsId] = useState([]);
  const [itemsId, setItemsId] = useState([]);
  const [loader, setLoader] = useState(false);

    const getMasterBarCodeList = () => {
      const id = localStorage.getItem("companyId");
      const api = {
        url:
          BASE_URL +
          `master-barcode?company_id=${id}&per_page=${100}&sort_as=updatedAt&sort_by=desc&page=${1}`,
      };
      dispatch(MasterQRCodeListInventoryApi(api));
    };

  // Getting the product ids here from master details screen
  useEffect(() => {
    if (data[0]?.products) {
      const productListIds = data[0]?.products.map(
        (item) => item?.productId?._id
      );
      setProductsId(productListIds);
    }
  }, [data]);

  // Getting the item ids here from master details screen
  useEffect(() => {
    if (data[0]?.products) {
      const productItemsIds = data[0]?.products.flatMap(
        (item) =>
          item?.productItemIds?.map((productItem) => productItem?._id) || []
      );
      setItemsId(productItemsIds);
    }
  }, [data]);

  // console.log("itemsId", itemsId);

  const handleSubmit = () => {
    const payloadRequest = {
      store: data[0]?.store,
      products: productsId,
      productItems: formData,
      title: data[0]?.title,
      description: data[0]?.description,
    };
    console.log("payload req in submit", payloadRequest);

    const allData = {
      body: payloadRequest,
    };

    const id = localStorage.getItem("companyId");
    setLoader(true);
    allData.url = BASE_URL + `master-barcode/${masterQRId}?company_id=${id}`;
    dispatch(UpdateItemToQRCodeApi(allData));

    getMasterBarCodeList();
    handleClose();
    setTimeout(() => {
      setLoader(false);
    }, 2000);
  };

  const handleDataChange = (updatedData) => {
    setFormData(updatedData);
  };

  return (
    <>
      <Box
        sx={{ bgcolor: "#F6F8FC", borderRadius: "4px" }}
        className="header-card"
      >
        <Grid
          container
          spacing={1}
          alignItems="center"
          sx={{
            padding: "0px 8px",
          }}
        >
          <Grid
            item
            xs={12}
            lg={7}
            md={4}
            sm={12}
            sx={{
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <Typography
              variant="h6"
              component="h6"
              sx={{
                fontWeight: "bold",
                textAlign: "left",
              }}
            >
              Master Barcode View
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            lg={5}
            md={8}
            sm={12}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button className="header-add cancle-hover" onClick={handleOpen}>
              <AddOutlinedIcon sx={{ mr: 1 }} />
              Add Item
            </Button>
          </Grid>
        </Grid>
      </Box>

      {/* Modal */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: 700, sm: 800, md: 1100 },
            bgcolor: "background.paper",
            border: "1px solid #ddd",
            boxShadow: 24,
            borderRadius: "8px",
            display: "flex",
            flexDirection: "column",
            maxHeight: "90vh",
          }}
        >
          <Box
            sx={{
              overflowY: "auto",
              flex: 1,
              p: 3,
            }}
          >
            <ThemeProvider theme={theme}>
              <Typography
                id="modal-title"
                variant="h6"
                sx={{
                  mb: 0.5,
                  fontWeight: "bold",
                  color: theme.palette.primary.main,
                  textAlign: "center",
                }}
              >
                Items Link to Master Barcode
              </Typography>
            </ThemeProvider>
            <ViewProductItemsForQRCode
              data={data}
              onDataChange={handleDataChange}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderTop: "1px solid #ddd",
              p: 2,
              bgcolor: "#f6f8fc",
              gap: 18,
              flexWrap: "wrap",
            }}
          >
            <Button
              onClick={handleClose}
              sx={{
                minWidth: 120,
                bgcolor: "#f44336",
                color: "#fff",
                "&:hover": {
                  bgcolor: "#d32f2f",
                },
              }}
            >
              <CloseIcon sx={{ mr: 1 }} />
              Cancel
            </Button>

            <Button
              onClick={handleSubmit}
              sx={{
                minWidth: 120,
                bgcolor: "#196773",
                color: "#fff",
                "&:hover": {
                  bgcolor: "#388e3c",
                },
              }}
            >
              <DoneIcon sx={{ mr: 1 }} />
              Submit
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default MasterQRCodeViewHeader;
