import {
  Box,
  Grid,
  IconButton,
  Tooltip,
  tooltipClasses,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Card,
} from "@mui/material";
import { ToastContainer } from "react-toastify";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { BASE_URL } from "../../redux/apiConfig/BaseUrl";
import { useSelector } from "react-redux";
import { styled } from "material-ui-core";
import LastPageIcon from "@mui/icons-material/LastPage";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import PropTypes from "prop-types";
import { useLocation, useNavigate } from "react-router-dom";
import ProgressbarTransparent from "../../GlobalProgress/ProgressbarTransparent";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { confirmAlert } from "react-confirm-alert";

import {
  DeleteItemApi,
  MasterQRCodeListInventoryApi,
  MasterQRCodeViewInventoryApi,
  TemplateListInventoryApi,
} from "../../redux/actions/Inventory";
import MasterQRCodeViewHeader from "./MasterQRCodeViewHeader";
import SingleGlobalTable from "../../GlobalComponent/SingleGlobalTable";
import moment from "moment";
import View from "../../images/View.svg";
import ViewHover from "../../images/ViewHover.svg";
import EditHover from "../../images/EditHover.svg";
import Edit from "../../images/Edit.svg";
import Delete from "../../images/Delete.svg";
import DeleteHover from "../../images/DeleteHover.svg";
import React from "react";
import DownloadIcon from "@mui/icons-material/Download";
import ViewProductItemsForQRCode from "./ViewProductItemsForQRCode";

const TruncatedText = ({ text }) => {
  const truncated = text?.length > 25 ? `${text.slice(0, 25)}...` : text;
  return (
    <Tooltip title={text || "N/A"}>
      <span>{truncated || "N/A"}</span>
    </Tooltip>
  );
};

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

const MasterQRCodeView = () => {
  const location = useLocation();
  const backScreenRowData = location.state?.rowData;
  const [rowData, setRowData] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  console.log("backScreenRowData", backScreenRowData);
  console.log("rowData", rowData);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageItem, setPageItem] = useState(0);
  const [rowsPerPageItem, setRowsPerPageItem] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePageItem = (event, newPage) => {
    setPageItem(newPage);
  };

  const handleChangeRowsPerPageItem = (event) => {
    setRowsPerPageItem(parseInt(event.target.value, 10));
    setPageItem(0);
  };

  const masterBarCodeView = useSelector(
    (state) =>
      (state.inventory &&
        state.inventory.QRCodeViewData &&
        state.inventory.QRCodeViewData.data &&
        state.inventory.QRCodeViewData.data.data) ||
      []
  );
  console.log("masterBarCodeView", masterBarCodeView);
  const loader = useSelector(
    (state) => state.inventory && state.inventory.loading
  );

  const [data, setData] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [indexHover, setIndexHover] = useState(0);
  const [masterQRId, setMasterQRId] = useState(null);
  const [hover, setHover] = useState({
    View: false,
  });
  console.log("loader", loader);

  useEffect(() => {
    const storedRowData = localStorage.getItem("rowData");
    if (storedRowData) {
      setRowData(JSON.parse(storedRowData));
    } else {
      console.warn("No rowData found in localStorage");
    }
  }, []);

  useEffect(() => {
    if (Array.isArray(masterBarCodeView) && masterBarCodeView.length > 0) {
      setData(masterBarCodeView);
    }
  }, [masterBarCodeView]);

  const getTemplateList = () => {
    const storedRowData = localStorage.getItem("rowData");
    const currentRowdata = JSON.parse(storedRowData);
    if (currentRowdata || rowData) {
      let qrCodeId = rowData?._id;
      let mqrId = currentRowdata?._id || qrCodeId;
      setMasterQRId(mqrId);
      const id = localStorage.getItem("companyId");
      const api = {
        url:
          BASE_URL +
          `master-barcode-wise-product-items?company_id=${id}&_id=${
            currentRowdata?._id || qrCodeId
          }&per_page=${perPage}&sort_as=updatedAt&sort_by=desc&page=${pageNo}`,
      };
      dispatch(MasterQRCodeViewInventoryApi(api));
    }
  };

  useEffect(() => {
    getTemplateList();
    localStorage.setItem("masterBarCodeLocalStorageData", "");
  }, [perPage, pageNo]);

  const handleView = (row) => {
    console.log("view screen not yet made", row);
    navigate("/master-barcode-list/view/item-view", {
      state: { rowData: row },
    });
  };

  const handleDelete = (values) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            const id = localStorage.getItem("companyId");
            const infoId = values?._id;
            console.log("logEntryId", infoId);

            const data = {
              url:
                BASE_URL +
                `delete-inventory-product-items?company_id=${id}&page=1&per_page=&sort_as=updatedAt&sort_by=desc`,
              body: {
                ids: [infoId],
              },
            };

            dispatch(DeleteItemApi(data, getTemplateList));
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  return (
    <Box>
      <ToastContainer autoClose={3000} />
      <MasterQRCodeViewHeader data={data} masterQRId={masterQRId} />
      {loader && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <ProgressbarTransparent play />
        </div>
      )}
      <Box
        sx={{
          bgcolor: "#F6F8FC",
          height: "100%",
          mt: 2,
          borderRadius: "8px",
          overflowX: "hidden",
          overflowY: "scroll",
          "&::-webkit-scrollbar": {
            width: 10,
          },
          "&::-webkit-scrollbar-track": {
            padding: "12px 5px",
            backgroundColor: "#CBD4E1",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#64748B",
            borderRadius: 8,
          },
        }}
      >
        {data && (
          <Box
            key={data?._id || 0}
            sx={{
              marginBottom: 0,
              padding: 2,
              border: "1px solid #ddd",
              borderRadius: 2,
              // backgroundColor: "#fff",
              marginLeft: "auto",
              marginRight: "auto",
              maxWidth: "99%",
            }}
          >
            <Paper sx={{ padding: 2, marginBottom: 2 }}>
              <Typography
                variant="h6"
                sx={{
                  textAlign: "center",
                  marginBottom: 2,
                  fontWeight: "bold",
                  color: "#196773",
                  textTransform: "uppercase",
                  letterSpacing: "1px",
                  backgroundColor: "transparent",
                  padding: 0,
                  marginTop: 0,
                  boxShadow: "none",
                }}
              >
                Barcode Information
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 2,
                  marginBottom: 2,
                }}
              >
                <Typography
                  variant="body2"
                  sx={{ flex: "1 1 45%", textAlign: "left" }}
                >
                  Name: <strong>{data[0]?.title}</strong>
                </Typography>

                <Typography
                  variant="body2"
                  sx={{ flex: "1 1 45%", textAlign: "left" }}
                >
                  Description: <strong>{data[0]?.description}</strong>
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 2,
                  marginBottom: 2,
                }}
              >
                <Typography
                  variant="body2"
                  sx={{ flex: "1 1 45%", textAlign: "left" }}
                >
                  Store: <strong>{data[0]?.store?.name}</strong>
                </Typography>

                <Typography
                  variant="body2"
                  sx={{ flex: "1 1 45%", textAlign: "left" }}
                >
                  Created At:{" "}
                  <strong>
                    {moment(data[0]?.createdAt).format(
                      "MMMM D, YYYY, h:mm:ss A"
                    )}
                  </strong>
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 2,
                  marginBottom: 2,
                }}
              >
                <Typography
                  variant="body2"
                  sx={{ flex: "1 1 45%", textAlign: "left" }}
                >
                  Updated At:{" "}
                  <strong>
                    {moment(data[0]?.updatedAt).format(
                      "MMMM D, YYYY, h:mm:ss A"
                    )}
                  </strong>
                </Typography>

                <Typography
                  variant="body2"
                  sx={{ flex: "1 1 45%", textAlign: "left" }}
                >
                  Serial Number: <strong>{data[0]?.serialNumber}</strong>
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 2,
                  marginBottom: 2,
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    flex: "1 1 45%",
                    textAlign: "left",
                    cursor: "pointer",
                    color: "blue",
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                  }}
                  onClick={async () => {
                    try {
                      const barcodeUrl = data[0]?.barcode;
                      if (!barcodeUrl) {
                        console.warn("No Barcode URL available");
                        return;
                      }

                      const response = await fetch(barcodeUrl);
                      const blob = await response.blob();
                      const link = document.createElement("a");
                      link.href = URL.createObjectURL(blob);
                      link.download = barcodeUrl.split("/").pop();
                      document.body.appendChild(link);
                      link.click();
                      document.body.removeChild(link);
                    } catch (error) {
                      console.error("Download failed:", error);
                    }
                  }}
                >
                  <DownloadIcon fontSize="small" />
                  Download BarCode
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flex: "1 1 45%",
                    gap: 2,
                  }}
                >
                  <Typography variant="body2" sx={{ textAlign: "left" }}>
                    View Barcode:
                  </Typography>
                  {data[0]?.barcode && (
                    <a
                      href={data[0]?.barcode}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={data[0]?.barcode}
                        alt="Barcode"
                        style={{
                          height: 100,
                          width: "auto",
                          cursor: "pointer",
                        }}
                      />
                    </a>
                  )}
                </Box>
              </Box>
            </Paper>

            <Paper sx={{ padding: 2, marginBottom: 2 }}>
              <Typography
                variant="h6"
                sx={{
                  textAlign: "center",
                  marginBottom: 2,
                  marginTop: 1,
                  fontWeight: "bold",
                  color: "#196773",
                  textTransform: "uppercase",
                  letterSpacing: "1px",
                  backgroundColor: "transparent",
                  padding: 0,
                  boxShadow: "none",
                }}
              >
                Products Information
              </Typography>

              <Grid
                container
                spacing={3}
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                {data[0]?.products?.length > 0 ? (
                  data[0]?.products?.map((item, index) => (
                    <Grid
                      key={item?.productId?._id || index}
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={4}
                      display="flex"
                      justifyContent="center"
                    >
                      <Card
                        sx={{
                          p: 3,
                          width: "100%",
                          height: "100%",
                          overflow: "auto",
                          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                          borderRadius: "8px",
                          border: "1px solid #ddd",
                          transition: "transform 0.2s",
                          backgroundColor: "#f4f6f9",
                          "&:hover": {
                            transform: "scale(1.02)",
                            boxShadow: "0 6px 12px rgba(0, 0, 0, 0.3)",
                          },
                        }}
                      >
                        <div style={{ padding: "10px" }}>
                          {[
                            {
                              label: "Name",
                              value: item?.productId?.productDetails?.name,
                            },
                            {
                              label: "Description",
                              value:
                                item?.productId?.productDetails?.description,
                            },
                            {
                              label: "Category",
                              value: item?.productId?.productDetails?.category,
                            },
                            {
                              label: "Store",
                              value: item?.productId?.store?.name,
                            },
                            {
                              label: "Created At",
                              value: item?.productId?.createdAt
                                ? moment(item?.productId?.createdAt).format(
                                    "MMMM D, YYYY, h:mm:ss A"
                                  )
                                : "N/A",
                            },
                            {
                              label: "Updated At",
                              value: item?.productId?.updatedAt
                                ? moment(item?.productId?.updatedAt).format(
                                    "MMMM D, YYYY, h:mm:ss A"
                                  )
                                : "N/A",
                            },
                            {
                              label: "Total Items",
                              value: item?.productId?.productItems?.toString(),
                            },
                          ].map((field, idx) => (
                            <div
                              key={idx}
                              style={{
                                marginBottom: "10px",
                                display: "flex",
                                alignItems: "center",
                                paddingLeft: "5px",
                              }}
                            >
                              <Typography
                                style={{
                                  flex: "0 0 130px",
                                  textAlign: "left",
                                  fontWeight: "normal",
                                }}
                              >
                                {field.label} :{" "}
                              </Typography>
                              <Typography
                                component="span"
                                style={{ fontWeight: "bold" }}
                              >
                                <TruncatedText text={field.value} />
                              </Typography>
                            </div>
                          ))}
                        </div>
                      </Card>
                    </Grid>
                  ))
                ) : (
                  <Card
                    sx={{
                      p: 2,
                      width: "100%",
                      textAlign: "center",
                      marginTop: "20px",
                      boxShadow: "0 2px 6px rgba(0, 0, 0, 0.1)",
                      borderRadius: "8px",
                      border: "1px solid #ddd",
                    }}
                  >
                    <p>No products available.</p>
                  </Card>
                )}
              </Grid>
            </Paper>

            <Paper
              sx={{
                padding: "20px",
                borderRadius: "10px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                backgroundColor: "#fff",
                marginBottom: 2,
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  textAlign: "center",
                  marginBottom: 3,
                  marginTop: 1,
                  fontWeight: "bold",
                  color: "#196773",
                  textTransform: "uppercase",
                  letterSpacing: "1px",
                  backgroundColor: "transparent",
                  padding: 0,
                  boxShadow: "none",
                }}
              >
                Items Information
              </Typography>

              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      {[
                        "S. No",
                        "Serial Number",
                        "Barcode Generated",
                        "Barcode Scaned",
                        "Created At",
                        "Updated At",
                      ]?.map((header, index) => (
                        <TableCell
                          key={index}
                          style={{
                            fontWeight: "bold",
                            textAlign: "center",
                            backgroundColor: "#f9f9f9",
                            padding: "10px",
                            borderBottom: "2px solid #ddd",
                          }}
                        >
                          {header}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data[0]?.products?.length > 0 ? (
                      data[0].products?.flatMap((product, productIndex) =>
                        product?.productItemIds?.map((item, itemIndex) => {
                          // console.log("Item details:", item);

                          return (
                            <TableRow
                              key={item._id || `${productIndex}-${itemIndex}`}
                            >
                              <TableCell align="center">
                                {page * rowsPerPageItem + itemIndex + 1}
                              </TableCell>
                              <TableCell align="center">
                                <TruncatedText
                                  text={item?.serialNumber || "N/A"}
                                />
                              </TableCell>
                              <TableCell align="center">
                                {item?.barcodeGenerated ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "8px",
                                    }}
                                  >
                                    <span
                                      style={{
                                        color: "green",
                                        fontSize: "14px",
                                        fontWeight: "500",
                                      }}
                                    >
                                      Generated
                                    </span>
                                    <CloseIcon style={{ color: "green" }} />
                                  </div>
                                ) : (
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "8px",
                                    }}
                                  >
                                    <span
                                      style={{
                                        color: "red",
                                        fontSize: "14px",
                                        fontWeight: "500",
                                      }}
                                    >
                                      Not Generated
                                    </span>
                                    <CancelIcon style={{ color: "red" }} />
                                  </div>
                                )}
                              </TableCell>

                              <TableCell align="center">
                                {item?.barcodeScanned === true ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "8px",
                                    }}
                                  >
                                    <DoneIcon color="success" />
                                    <span
                                      style={{
                                        color: "green",
                                        fontSize: "14px",
                                        fontWeight: "500",
                                      }}
                                    >
                                      Scanned
                                    </span>
                                  </div>
                                ) : (
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "8px",
                                    }}
                                  >
                                    <CloseIcon color="error" />
                                    <span
                                      style={{
                                        color: "red",
                                        fontSize: "14px",
                                        fontWeight: "500",
                                      }}
                                    >
                                      Not Scanned
                                    </span>
                                  </div>
                                )}
                              </TableCell>

                              <TableCell align="center">
                                <TruncatedText
                                  text={
                                    item?.createdAt
                                      ? moment(item.createdAt).format(
                                          "MMMM D, YYYY, h:mm:ss A"
                                        )
                                      : "N/A"
                                  }
                                />
                              </TableCell>
                              <TableCell align="center">
                                <TruncatedText
                                  text={
                                    item?.updatedAt
                                      ? moment(item.updatedAt).format(
                                          "MMMM D, YYYY, h:mm:ss A"
                                        )
                                      : "N/A"
                                  }
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })
                      )
                    ) : (
                      <TableRow>
                        <TableCell colSpan={7} align="center">
                          No items available.
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>

              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={
                  data[0]?.products?.reduce(
                    (acc, product) =>
                      acc + (product?.productItemIds?.length || 0),
                    0
                  ) || 0
                }
                rowsPerPage={rowsPerPageItem}
                page={pageItem}
                onPageChange={handleChangePageItem}
                onRowsPerPageChange={handleChangeRowsPerPageItem}
              />
            </Paper>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default MasterQRCodeView;
