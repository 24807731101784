/** @format */

import {
  Box,
  Button,
  Container,
  Divider,
  FormControl,
  Input,
  Modal,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextareaAutosize as BaseTextareaAutosize,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ProgressbarTransparent from "../GlobalProgress/ProgressbarTransparent";
// import { BASE_URL } from "../../Store/Redux/apiConfig/BaseUrl";
// import { ProfileMeGetApiAction } from "../../Store/Redux/Actions/MyProfileDetailsAction";
import moment from "moment";
import { BASE_URL } from "../redux/apiConfig/BaseUrl";
import { ProfileMeGetApiAction } from "../redux/actions/MyProfileDetailsAction";
import CloseIcon from "@mui/icons-material/Close";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { ManualPaymentApi } from "../redux/actions/PaymenHistory";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";

const blue = {
  100: "#DAECFF",
  200: "#b6daff",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const TextareaAutosize = styled(BaseTextareaAutosize)(
  ({ theme }) => `
  box-sizing: border-box;
  width: 100%;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
  box-shadow: 0px 2px 2px ${
    theme.palette.mode === "dark" ? grey[900] : grey[50]
  };

  &:hover {
    border-color: ${blue[400]};
  }

  &:focus {
    border-color: ${blue[400]};
    box-shadow: 0 0 0 3px ${
      theme.palette.mode === "dark" ? blue[600] : blue[200]
    };
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`
);

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const downloadAllstyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  height: 500,
  overflow: "scroll",
  bgcolor: "#fff",
  // border: "2px solid rgb(12 36 49)",
  borderRadius: "6px",
  boxShadow: 24,
  p: 4,
};

// const uploadMedia = async (file, callback) => {
//   const S3_BUCKET = "popprobe-saas";
//   const REGION = "us-west-2";

//   AWS.config.update({
//     accessKeyId: "AKIAIAWD4CEY36LXX6AQ",
//     secretAccessKey: "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",
//   });
//   const s3 = new AWS.S3({
//     params: { Bucket: S3_BUCKET },
//     region: REGION,
//   });

//   const data = {
//     Bucket: S3_BUCKET,
//     Key: `${file.companyName}/${file.name}`,
//     Body: file.file,
//   };

//   var upload = s3
//     .putObject(data)
//     .on("httpUploadProgress", (evt) => {})
//     .promise();

//   await upload.then((err, data) => {
//     console.log(err);
//   });
// };

const PaymentDetails = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  // let totalAddOnPrice = 0;

  const companyInfo = location?.state?.companyInfo;
  const paymentDetails = location.state;

  const [loading, setLoading] = useState(false);
  const [companyDetails, setCompanyDetails] = useState(companyInfo);
  const [paymentIds, setPaymentIds] = useState();
  const [openManualModal, setManunalModal] = useState(false);
  const [bankName, setBankName] = useState("");
  const [ifscCode, setIfscCode] = useState("");
  const [amount, setAmount] = useState("");
  const [comment, setComment] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [paymentDate, setPaymentDate] = useState("");
  const [paymentReceipt, setPaymentReceipt] = useState({});
  const [basicAmount, setBasicAmount] = useState(0);
  const [addOnAmount, setAddOnAmount] = useState(0);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [gstAmount, setGstAmount] = useState(0);
  const [payableAmount, setPayableAmount] = useState(0);
  const [totalAddOnPrice, setTotalAddOnPrice] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [uploadError, setUploadError] = useState(false);
  const [fileUrl, setFileUrl] = useState(null);
  //   const [paymentDate, setPaymentDate] = useState("");

  const extractToken = "";
  console.log("companyDetails", companyDetails);
  console.log("location", location);

  const paymentListArr = useSelector(
    (state) =>
      state.paymentHistory &&
      state.paymentHistory.PaymentList &&
      state.paymentHistory.PaymentList.data &&
      state.paymentHistory.PaymentList.data.razorPay &&
      state.paymentHistory.PaymentList.data.razorPay.data
  );

  const PaymentDoneListArr = useSelector(
    (state) =>
      state.paymentHistory &&
      state.paymentHistory.PaymentDoneList &&
      state.paymentHistory.PaymentDoneList.data
  );

  const CompanyInfoDetailListArr = useSelector(
    (state) =>
      state.paymentHistory &&
      state.paymentHistory.CompanyInfoDetailList &&
      state.paymentHistory.CompanyInfoDetailList.data &&
      state.paymentHistory.CompanyInfoDetailList.data.data
  );

  const companyListStatus = useSelector(
    (state) =>
      state.MyProfileDetails &&
      state.MyProfileDetails.progileMeGetApiRes &&
      state.MyProfileDetails.progileMeGetApiRes.data &&
      state.MyProfileDetails.progileMeGetApiRes.data.data
  );

  //   useEffect(() => {
  //     if (CompanyInfoDetailListArr) {
  //       setCompanyDetails(CompanyInfoDetailListArr);
  //       setLoading(false);
  //     }
  //   }, [CompanyInfoDetailListArr]);

  useEffect(() => {
    if (PaymentDoneListArr && PaymentDoneListArr.isVerified) {
      printDocument();
    }
  }, [PaymentDoneListArr]);

  useEffect(() => {
    getProfile();
  }, []);

  useEffect(() => {
    let tAddOn = 0;

    companyDetails?.companyDetail?.companyAddOn?.map((addOn) => {
      addOn.subModules.map((subModule) => {
        subModule.features.map((feature) => {
          tAddOn += feature?.price;
        });
      });
    });
    setTotalAddOnPrice(tAddOn);

    const basic =
      (companyDetails?.userCount || 1) *
      (companyDetails?.planDetail.price || 0);
    const addOn = (companyDetails?.userCount || 1) * (tAddOn || 0);

    const discount =
      ((basic + addOn) * (companyDetails?.companyDetail?.discount || 0)) / 100;
    const total = basic + addOn - discount;

    const gst = (total * 9) / 100;
    const payable = total + gst + gst;

    setBasicAmount(basic.toFixed(2));
    setAddOnAmount(addOn.toFixed(2));
    setDiscountAmount(discount.toFixed(2));
    setTotalAmount(total.toFixed(2));
    setGstAmount(gst.toFixed(2));
    setPayableAmount(payable.toFixed(2));
  }, [companyDetails]);

  const printDocument = () => {
    let printableArea = document.getElementById("invoicePrint").innerHTML;
    let originalContent = document.body.innerHTML;
    document.body.innerHTML = printableArea;
    window.print();
    document.body.innerHTML = originalContent;
  };

  const getProfile = () => {
    const data = { url: BASE_URL + `profile/me` };
    dispatch(ProfileMeGetApiAction(data));
  };

  // const handleFile = (e) => {
  //   const file = e.target.files[0];

  //   const company = companyInfo?.companyDetail?.companyName
  //     .split(" ")
  //     .join("_");

  //   const fileName = `IMGPOPPROBE_INVOICE_${company}_${moment().format(
  //     "YYYY-MM-DD-HH-mm-ss"
  //   )}-${Date.now()}`;
  //   setPaymentReceipt(fileName);
  //   uploadMedia({
  //     name: fileName,
  //     file: file,
  //     companyName: company,
  //   });
  // };

 const getPresignedUrl = async (fileName, fileType, fileSize) => {
  try {
    const companyId = localStorage.getItem("companyId");
    const token = localStorage.getItem("token");

    if (!token) {
      throw new Error("No token found in local storage.");
    }

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await axios.post(
      `${BASE_URL}generate/signed/url?company_id=${companyId}`,
      {
        fileName: fileName,
        contentType: fileType,
        fileSize: fileSize,
      },
      { headers }
    );

    return response.data.data.presignedUrl;
  } catch (error) {
    console.error("Failed to generate presigned URL:", error);
    throw error;
  }
};

  const handleFile = async (e) => {
    const file = e.target.files[0];
    if (!file) return;
  
    try {
      const company = companyInfo?.companyDetail?.companyName
        .split(" ")
        .join("_");
  
      const fileName = `IMGPOPPROBE_INVOICE_${company}_${moment().format(
        "YYYY-MM-DD-HH-mm-ss"
      )}-${Date.now()}`;
      setPaymentReceipt(fileName);
  
      const fileType = file.type || "application/octet-stream"; 
      const fileSize = file.size || 0;
        const presignedUrl = await getPresignedUrl(fileName, fileType, fileSize);
  
      if (!presignedUrl) {
        throw new Error("Failed to get the presigned URL.");
      }
        await axios.put(presignedUrl, file, {
        headers: {
          "Content-Type": fileType,
        },
        onUploadProgress: (progressEvent) => {
          const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          console.log(`Upload Progress: ${progress}%`);
        },
      });
  
      toast.success("File uploaded successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });
        const uploadedFileUrl = presignedUrl.split("?")[0];
      console.log("Uploaded file URL:", uploadedFileUrl);
    } catch (error) {
      console.error("Error uploading file:", error);
  
      if (error.response) {
        toast.error(`Server Error: ${error.response.status}`);
      } else if (error.request) {
        toast.error("No response from server. Please try again.");
      } else {
        toast.error("An unexpected error occurred.");
      }
    }
  };
  

  const callback = () => {
    setManunalModal(false);
    setTimeout(() => {
      navigate("/payment_history");
    }, 3000);
  };

  const handleMakePayment = () => {
    const company = localStorage.getItem("companyId");

    if (!amount || !transactionId || !paymentDate || !paymentReceipt) {
      toast.error("Please enter all fields!");
      return;
    }

    const body = {
      amount: amount,
      pdfLink: paymentReceipt,
      transactionId: transactionId,
      transactionDate: paymentDate,
      ifscCode: ifscCode,
      bankName: bankName,
      message: comment,
    };

    const data = {
      url: BASE_URL + `payment/manual?company_id=${company}`,
      body: body,
    };
    dispatch(ManualPaymentApi(data, callback));
  };

  const downloadInvoice = (url) => {
    const link = document.createElement("a", "_blank");
    link.href = url;
    link.download = "invoice.pdf";
    link.dispatchEvent(
      new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: false,
      })
    );
  };

  return (
    <Container
      maxWidth="xl"
      sx={{
        width: "100%",
        bgcolor: "#F6F8FC",
        p: 2,
        height: "100%",
        borderRadius: 2,
        display: "flex",
        alignItems: "center",
        height: "min-content",
      }}
    >
      <ToastContainer autoClose={3000} />
      <Box
        sx={{
          height: "100%",
          width: "100%",
        }}
      >
        {loading && <ProgressbarTransparent play />}
        <div id="invoicePrint">
          <Box
            sx={{
              border: "1px solid #cbd4e1",
              borderRadius: "8px",
              background: "#FFFFFF",
              p: 2,
              width: "100%",
              // minWidth: "200px",
              // maxWidth: "300px",
              // minHeight: "300px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              alignItems: "flex-start",
              gap: "15px",
              // position: "relative",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "10px",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    textAlign: "left",
                  }}
                >
                  <Box sx={{ fontSize: "2.25rem", color: "#196773" }}>
                    Payment Details
                  </Box>
                </Box>
                <Box>
                  <Button
                    onClick={() =>
                      window.open(paymentDetails.pdfLink, "_blank")
                    }
                    fullWidth
                    variant="contained"
                    sx={{
                      width: "250px",
                      background: "#196773",
                    }}
                  >
                    Download Invoice
                  </Button>
                </Box>
              </Box>
              <Divider
                orientation="horizontal"
                flexItem
                sx={{
                  backgroundColor: "#27364B",
                  width: "100%",
                  height: "1px",
                  borderRadius: "10px",
                }}
              />
              <Box
                sx={{
                  width: "100%",
                  textAlign: "left",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  margin: "10px 0",
                }}
              >
                <Box sx={{ fontSize: "20px", color: "#196773" }}>
                  Invoice No.: {paymentDetails.uniqueInvoiceId}
                </Box>
                <Box sx={{ fontSize: "20px", color: "#196773" }}>
                  Date:{" "}
                  {moment(paymentDetails.updatedAt).format("MMMM D, YYYY, LTS")}
                </Box>
              </Box>
              <Divider
                orientation="horizontal"
                flexItem
                sx={{
                  backgroundColor: "#27364B",
                  width: "100%",
                  height: "1px",
                  borderRadius: "10px",
                  marginBottom: "10px",
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "50px",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    textAlign: "left",
                  }}
                >
                  <Box
                    sx={{
                      fontSize: "15px",
                      fontWeight: 600,
                      fontFamily: "Inter",
                    }}
                  >
                    KURMAR RESEARCH PRIVATE LIMITED
                  </Box>
                  <Box sx={{ fontSize: "14px", fontFamily: "Inter" }}>
                    26/A, 2ND FLOOR, KRISHNA REDDYLAYOUT,
                  </Box>
                  <Box sx={{ fontSize: "14px", fontFamily: "Inter" }}>
                    DOMLUR, BANGALORE, Karnataka
                  </Box>
                  <Box sx={{ fontSize: "14px", fontFamily: "Inter" }}>
                    GSTIN/UIN: 29AAGCK6235Q1ZS
                  </Box>
                </Box>
                <Box
                  sx={{
                    textAlign: "left",
                    minWidth: "250px",
                  }}
                >
                  <Box sx={{ fontSize: "15px", fontWeight: 600 }}>BILL TO</Box>
                  <Box sx={{ fontSize: "14px", fontWeight: 600 }}>
                    {companyDetails &&
                      companyDetails?.companyDetail?.companyName}
                  </Box>
                  <Box sx={{ fontSize: "14px" }}>
                    {companyDetails &&
                      companyDetails?.companyDetail?.address.line1}
                    ,
                    {companyDetails &&
                      companyDetails?.companyDetail?.address?.city}
                    ,{companyDetails && companyDetails?.companyDetail?.state},
                    {companyDetails &&
                      companyDetails?.companyDetail?.address?.postal_code}
                  </Box>
                  <Box sx={{ fontSize: "1rem" }}>
                    GSTIN/UIN :{" "}
                    {companyDetails && companyDetails?.companyDetail?.gst}
                  </Box>
                  <Box sx={{ fontSize: "14px", fontFamily: "Inter" }}>
                    Company Slag:{" "}
                    {companyDetails &&
                      companyDetails?.companyDetail?.uniqueslag}
                  </Box>
                </Box>
              </Box>
              <Divider
                orientation="horizontal"
                flexItem
                sx={{
                  backgroundColor: "#27364B",
                  width: "100%",
                  height: "1px",
                  borderRadius: "10px",
                  marginTop: "3px",
                  marginBottom: "10px",
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "10px",
                  width: "100%",
                }}
              >
                <Box sx={{ fontSize: "1rem" }}>
                  Plan Title :{" "}
                  <strong>
                    {companyDetails && companyDetails?.planDetail?.title}
                  </strong>
                </Box>
                <Box sx={{ fontSize: "1rem" }}>
                  Total Active Store :{" "}
                  <strong>
                    {companyDetails && companyDetails?.storeCount}
                  </strong>
                </Box>
                <Box sx={{ fontSize: "1rem" }}>
                  Total Active User :{" "}
                  <strong>{companyDetails && companyDetails?.userCount}</strong>
                </Box>
                <Box sx={{ fontSize: "1rem" }}>
                  Payment Status :{" "}
                  {paymentDetails && paymentDetails?.status === "successful" ? (
                    <strong style={{ color: "green" }}>
                      {paymentDetails?.status}
                    </strong>
                  ) : (
                    <strong>{paymentDetails?.status}</strong>
                  )}
                </Box>
              </Box>
              <Divider
                orientation="horizontal"
                flexItem
                sx={{
                  backgroundColor: "#27364B",
                  width: "100%",
                  height: "1px",
                  borderRadius: "10px",
                  marginTop: "3px",
                  marginBottom: "10px",
                }}
              />
            </Box>
            {companyDetails?.companyDetail?.companyAddOn.length ? (
              <>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    width: "100%",
                  }}
                >
                  <Box sx={{ fontWeight: 600, fontSize: "1rem" }}>
                    AddOn Features :
                  </Box>
                  {companyDetails &&
                    companyDetails?.companyDetail?.companyAddOn?.map(
                      (addOn) => {
                        return addOn.subModules.map((subModule) => {
                          return subModule.features.map((feature) => {
                            return (
                              <Box
                                sx={{
                                  fontSize: "1rem",
                                  display: "flex",
                                  justifyContent: "space-between",
                                  marginBottom: "10px",
                                  width: "100%",
                                }}
                              >
                                <Box sx={{ fontSize: "1rem" }}>
                                  {"- " + feature.title}
                                </Box>
                                <Box sx={{ fontSize: "1rem" }}>
                                  {paymentIds &&
                                  paymentIds?.razorpay_payment_id ? (
                                    ""
                                  ) : (
                                    <strong>{"₹ " + feature?.price}</strong>
                                  )}
                                </Box>
                              </Box>
                            );
                          });
                        });
                      }
                    )}
                </Box>
                <Divider
                  orientation="horizontal"
                  flexItem
                  sx={{
                    backgroundColor: "#27364B",
                    width: "100%",
                    height: "1px",
                    borderRadius: "10px",
                    marginTop: "3px",
                    // marginBottom: "10px",
                  }}
                />
              </>
            ) : (
              ""
            )}
            <Box
              sx={{
                width: "100%",
                textAlign: "left",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                // margin: "10px 0",
              }}
            >
              <Box sx={{ fontSize: "20px", color: "#196773" }}>
                Billing Period:{" "}
                {moment(paymentDetails.billingStartDate).format("MMMM D, YYYY")}{" "}
                - {moment(paymentDetails.billingEndDate).format("MMMM D, YYYY")}
              </Box>
              <Box sx={{ fontSize: "20px", color: "#196773" }}>
                Due Date:{" "}
                {moment(paymentDetails.dueDate).format("MMMM D, YYYY ")}
              </Box>
            </Box>

            <Divider
              orientation="horizontal"
              flexItem
              sx={{
                backgroundColor: "#27364B",
                width: "100%",
                height: "1px",
                borderRadius: "10px",
                marginTop: "3px",
                marginBottom: "10px",
              }}
            />

            <Table width={"100%"}>
              <TableHead>
                <TableRow>
                  <TableCell>Particulars</TableCell>
                  <TableCell align="right">HSN/SAC</TableCell>
                  <TableCell align="right">Total Users</TableCell>
                  <TableCell align="right">Rate Per</TableCell>
                  <TableCell align="right">Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {companyDetails?.planDetail?.title}
                  </TableCell>
                  <TableCell align="right">998313</TableCell>
                  <TableCell align="right">
                    {companyDetails?.userCount || 0}
                  </TableCell>
                  <TableCell align="right">
                    {companyDetails?.planDetail?.price || 0}
                  </TableCell>
                  <TableCell align="right">{basicAmount}</TableCell>
                </TableRow>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Additional Features
                  </TableCell>
                  <TableCell align="right">-</TableCell>
                  <TableCell align="right">
                    {companyDetails?.userCount || 0}
                  </TableCell>
                  <TableCell align="right">{totalAddOnPrice || 0}</TableCell>
                  <TableCell align="right">{addOnAmount}</TableCell>
                </TableRow>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Discount Amount
                  </TableCell>
                  <TableCell align="right">-</TableCell>
                  <TableCell align="right">-</TableCell>
                  <TableCell align="right">
                    <strong>
                      {(companyDetails.companyDetail &&
                        companyDetails?.companyDetail?.discount) ||
                        0}
                      %
                    </strong>
                  </TableCell>
                  <TableCell align="right">{discountAmount}</TableCell>
                </TableRow>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Total Amount
                  </TableCell>
                  <TableCell align="right">-</TableCell>
                  <TableCell align="right">-</TableCell>
                  <TableCell align="right">-</TableCell>
                  <TableCell align="right">{totalAmount}</TableCell>
                </TableRow>
              </TableBody>
            </Table>

            <Divider
              orientation="horizontal"
              flexItem
              sx={{
                backgroundColor: "#27364B",
                width: "100%",
                height: "1px",
                borderRadius: "10px",
                marginTop: "3px",
                marginBottom: "10px",
              }}
            />

            <Table width={"100%"}>
              <TableHead>
                <TableRow>
                  <TableCell>Particulars</TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right">Rate Per</TableCell>
                  <TableCell align="right">Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row" colSpan={3}>
                    Output Cgst
                  </TableCell>
                  {/* <TableCell align="right">998313</TableCell> */}
                  {/* <TableCell align="right">
                    {companyDetails?.userCount || 0}
                  </TableCell> */}
                  <TableCell align="right">9%</TableCell>
                  <TableCell align="right">{gstAmount}</TableCell>
                </TableRow>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row" colSpan={3}>
                    Output Sgst
                  </TableCell>
                  {/* <TableCell align="right">998313</TableCell> */}
                  {/* <TableCell align="right">
                    {companyDetails?.userCount || 0}
                  </TableCell> */}
                  <TableCell align="right">9%</TableCell>
                  <TableCell align="right">{gstAmount}</TableCell>
                </TableRow>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row" colSpan={3}>
                    Payable Amount
                  </TableCell>
                  {/* <TableCell align="right">998313</TableCell> */}
                  {/* <TableCell align="right">
                    {companyDetails?.userCount || 0}
                  </TableCell> */}
                  <TableCell align="right"></TableCell>
                  <TableCell align="right">{payableAmount}</TableCell>
                </TableRow>
              </TableBody>
            </Table>

            {/* <Box
              sx={{
                fontSize: "1rem",
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px",
                width: "100%",
              }}
            >
              <Box sx={{ fontSize: "1rem" }}>Discount Applied : </Box>
              <Box sx={{ fontSize: "1rem" }}>
                <strong>
                  {(companyDetails.companyDetail &&
                    companyDetails?.companyDetail?.discount) ||
                    0}{" "}
                  %
                </strong>
              </Box>
            </Box> */}

            <Divider
              orientation="horizontal"
              flexItem
              sx={{
                backgroundColor: "#27364B",
                width: "100%",
                height: "1px",
                borderRadius: "10px",
                marginTop: "3px",
                marginBottom: "10px",
              }}
            />

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                bottom: "10px",
              }}
            >
              <Box sx={{ fontSize: "2.25rem", color: "#f26539" }}>
                Rs. {paymentDetails?.amount}
              </Box>

              {paymentDetails &&
              paymentDetails?.manualPayment?.transactionId ? (
                <Box>
                  Payment No: {paymentDetails?.manualPayment?.transactionId}
                </Box>
              ) : paymentDetails && paymentDetails?.transactionId ? (
                <Box>Payment No: {paymentDetails?.transactionId}</Box>
              ) : paymentDetails?.status !== "successfull" ? (
                <Box
                  sx={{
                    display: "flex",
                    columnGap: "25px",
                  }}
                >
                  <Button
                    onClick={() =>
                      navigate(`/make-payment/${paymentDetails.token}?uniqueInvoiceId=${paymentDetails.uniqueInvoiceId}`)
                    }
                    fullWidth
                    variant="contained"
                    sx={{
                      width: "250px",
                      background: "#196773",
                    }}
                  >
                    Pay Invoice Online
                  </Button>
                  <Button
                    onClick={() => setManunalModal(true)}
                    fullWidth
                    variant="contained"
                    sx={{
                      width: "250px",
                      background: "#196773",
                    }}
                  >
                    Pay Invoice Manual
                  </Button>
                </Box>
              ) : (
                ""
              )}
            </Box>
          </Box>
        </div>
      </Box>
      <Modal
        open={openManualModal}
        onClose={() => setManunalModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableEscapeKeyDown={false}
      >
        <Box sx={{ ...downloadAllstyle, p: 0 }}>
          <Box
            sx={{
              position: "sticky",
              right: "15px",
              top: "15px",
              cursor: "pointer",
              textAlign: "right",
              padding: "0px 10px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Box onClick={() => setManunalModal(false)}>
              <CloseIcon />
            </Box>
          </Box>
          <Box>
            <Box sx={{ pt: 2, px: 4, pb: 3 }}>
              <FormControl fullWidth sx={{ mt: 2 }}>
                <label>Bank Name</label>
                <TextField
                  size="small"
                  fullWidth
                  sx={{ marginTop: "10px" }}
                  id="outlined-start-adornment"
                  placeholder="Bank Name"
                  onChange={(e) => {
                    setBankName(e.target.value);
                  }}
                  value={bankName}
                />
              </FormControl>
              <FormControl fullWidth sx={{ mt: 2 }}>
                <label>IFSC Code</label>
                <TextField
                  size="small"
                  fullWidth
                  sx={{ marginTop: "10px" }}
                  id="outlined-start-adornment"
                  placeholder="IFSC Code"
                  onChange={(e) => {
                    setIfscCode(e.target.value);
                  }}
                  value={ifscCode}
                />
              </FormControl>
              <FormControl fullWidth sx={{ mt: 2 }}>
                <label>Amount</label>
                <TextField
                  size="small"
                  fullWidth
                  sx={{ marginTop: "10px" }}
                  id="outlined-start-adornment"
                  placeholder="Amount"
                  onChange={(e) => {
                    setAmount(e.target.value);
                  }}
                  value={amount}
                />
              </FormControl>
              <FormControl fullWidth sx={{ mt: 2 }}>
                <label>Transaction Id</label>
                <TextField
                  size="small"
                  fullWidth
                  sx={{ marginTop: "10px" }}
                  id="outlined-start-adornment"
                  placeholder="Transaction Id"
                  onChange={(e) => {
                    setTransactionId(e.target.value);
                  }}
                  value={transactionId}
                />
              </FormControl>
              <FormControl fullWidth>
                <label style={{ marginTop: "10px", marginBottom: "5px" }}>
                  Payment Date
                </label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    inputFormat="DD/MM/YYYY"
                    size="small"
                    value={paymentDate}
                    onChange={(newValue) => {
                      let dateSort = moment(newValue?.$d)
                        .format("YYYY-MM-DD")
                        .split("/")
                        .join("");

                      setPaymentDate(dateSort);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                    className="date-picker-manual"
                    maxDate={new Date()}
                  />
                </LocalizationProvider>
              </FormControl>
              <FormControl fullWidth>
                <label style={{ marginTop: "10px", marginBottom: "5px" }}>
                  Please upload your payment receipt.
                </label>
                <Button
                  component="label"
                  role={undefined}
                  variant="contained"
                  tabIndex={-1}
                  startIcon={<CloudUploadIcon />}
                  sx={{
                    width: "100%",
                  }}
                >
                  Upload file
                  <VisuallyHiddenInput
                    type="file"
                    // onChange={(e) => handleFile(e)}
                    onChange={handleFile}
                    style={{
                      width: "100%",
                    }}
                  />
                </Button>
              </FormControl>
              <FormControl fullWidth sx={{ mt: 2 }}>
                <label>
                  Comment{" "}
                  <span
                    style={{
                      fontSize: "11px",
                    }}
                  >
                    (optional)
                  </span>
                </label>
                <TextareaAutosize
                  size="small"
                  fullWidth
                  sx={{ marginTop: "10px" }}
                  id="outlined-start-adornment"
                  placeholder="Comment"
                  onChange={(e) => {
                    setComment(e.target.value);
                  }}
                  minRows={3}
                  value={comment}
                />
              </FormControl>
            </Box>
            <Box
              sx={{
                display: "flex",
                position: "sticky",
                bottom: 0,
                width: "100%",
                gap: "30px",
                px: 3,
                pt: 1,
                background: "#fff",
              }}
            >
              <Button
                className="header-add cancle-hover"
                style={{
                  height: "46px",
                  padding: "12px 16px",
                  width: "47%",
                }}
                onClick={(e) => {
                  handleMakePayment();
                  //   handleApplyFilter();
                  // setOpenFilterModal(false);
                  // handleFilter();
                }}
              >
                Submit
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Container>
  );
};

export default PaymentDetails;
