import {
  CATEGORY_LIST_INVENTORY_ERROR,
  CATEGORY_LIST_INVENTORY_REQUEST,
  CATEGORY_LIST_INVENTORY_SUCCESS,
  CREATE_INVENTORY_PRODUCT_ERROR,
  CREATE_INVENTORY_PRODUCT_REQUEST,
  CREATE_INVENTORY_PRODUCT_SUCCESS,
  DELETE_PRODUCT_ERROR,
  DELETE_PRODUCT_ITEMS_ERROR,
  DELETE_PRODUCT_ITEMS_REQUEST,
  DELETE_PRODUCT_ITEMS_SUCCESS,
  DELETE_PRODUCT_REQUEST,
  DELETE_PRODUCT_SUCCESS,
  DELETEITEM_ERROR,
  DELETEITEM_REQUEST,
  DELETEITEM_SUCCESS,
  EDIT_PRODUCT_ITEMS_ERROR,
  EDIT_PRODUCT_ITEMS_REQUEST,
  EDIT_PRODUCT_ITEMS_SUCCESS,
  INVENTORY_PRODUCT_ITEM_ERROR,
  INVENTORY_PRODUCT_ITEM_REQUEST,
  INVENTORY_PRODUCT_ITEM_SUCCESS,
  LISTQRCODE_DELETE_ERROR,
  LISTQRCODE_DELETE_REQUEST,
  LISTQRCODE_DELETE_SUCCESS,
  MASTERBARCODEVIEW_ERROR,
  MASTERBARCODEVIEW_ITEMVIEW_ERROR,
  MASTERBARCODEVIEW_ITEMVIEW_REQUEST,
  MASTERBARCODEVIEW_ITEMVIEW_SUCCESS,
  MASTERBARCODEVIEW_REQUEST,
  MASTERBARCODEVIEW_SUCCESS,
  MASTERQRCODE_CREATE_ERROR,
  MASTERQRCODE_CREATE_REQUEST,
  MASTERQRCODE_CREATE_SUCCESS,
  MASTERQRCODE_LIST_INVENTORY_ERROR,
  MASTERQRCODE_LIST_INVENTORY_REQUEST,
  MASTERQRCODE_LIST_INVENTORY_SUCCESS,
  PRODUCT_ITEMS_ERROR,
  PRODUCT_ITEMS_REQUEST,
  PRODUCT_ITEMS_SUCCESS,
  PRODUCT_LIST_ERROR,
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_SUCCESS,
  PRODUCT_STORE_ERROR,
  PRODUCT_STORE_REQUEST,
  PRODUCT_STORE_SUCCESS,
  TEMPLATE_DELETE_ERROR,
  TEMPLATE_DELETE_REQUEST,
  TEMPLATE_DELETE_SUCCESS,
  TEMPLATE_LIST_INVENTORY_ERROR,
  TEMPLATE_LIST_INVENTORY_REQUEST,
  TEMPLATE_LIST_INVENTORY_SUCCESS,
  UPDATE_ITEMTOQR_ERROR,
  UPDATE_ITEMTOQR_REQUEST,
  UPDATE_ITEMTOQR_SUCCESS,
  UPLOAD_PRODUCT_MEDIA_QUES_ERROR,
  UPLOAD_PRODUCT_MEDIA_QUES_REQUEST,
  UPLOAD_PRODUCT_MEDIA_QUES_SUCCESS,
} from "../types/Types";

const initialState = {
  loading: false,
  error: "",
  categoryDataInventory: [],
  templateData: [],
  store: [],
  productList: [],
  subproductList: [],
  mediaData: {},
  QRCodeListData: [],
  createQRCode: [],
  QRCodeViewData: [],
  QRCodeViewItemViewData: [],
};

const InventoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case CATEGORY_LIST_INVENTORY_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case CATEGORY_LIST_INVENTORY_SUCCESS: {
      return {
        ...state,
        loading: false,
        categoryDataInventory: action?.payload,
      };
    }
    case CATEGORY_LIST_INVENTORY_ERROR: {
      return {
        ...state,
        loading: false,
        categoryDataInventory: [],
        error: action.error,
      };
    }
    case TEMPLATE_LIST_INVENTORY_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case TEMPLATE_LIST_INVENTORY_SUCCESS: {
      return {
        ...state,
        loading: false,
        templateData: action?.payload,
      };
    }
    case TEMPLATE_LIST_INVENTORY_ERROR: {
      return {
        ...state,
        loading: false,
        templateData: [],
        error: action.error,
      };
    }
    case TEMPLATE_DELETE_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case TEMPLATE_DELETE_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case TEMPLATE_DELETE_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    case PRODUCT_STORE_REQUEST: {
      return {
        ...state,
        loading: true,
        store: [],
        error: "",
      };
    }
    case PRODUCT_STORE_SUCCESS: {
      return {
        ...state,
        loading: false,
        store: action.payload,
      };
    }
    case PRODUCT_STORE_ERROR: {
      return {
        ...state,
        loading: false,
        store: [],
      };
    }
    case UPLOAD_PRODUCT_MEDIA_QUES_REQUEST: {
      return {
        ...state,
        loading: true,
        mediaData: {},
        error: "",
      };
    }
    case UPLOAD_PRODUCT_MEDIA_QUES_SUCCESS: {
      return {
        ...state,
        loading: false,
        mediaData: action.payload,
      };
    }
    case UPLOAD_PRODUCT_MEDIA_QUES_ERROR: {
      return {
        ...state,
        loading: false,
        mediaData: {},
      };
    }
    case CREATE_INVENTORY_PRODUCT_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case CREATE_INVENTORY_PRODUCT_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case CREATE_INVENTORY_PRODUCT_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    case EDIT_PRODUCT_ITEMS_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case EDIT_PRODUCT_ITEMS_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case EDIT_PRODUCT_ITEMS_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    case DELETE_PRODUCT_ITEMS_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case DELETE_PRODUCT_ITEMS_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case DELETE_PRODUCT_ITEMS_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    case DELETE_PRODUCT_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case DELETE_PRODUCT_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case DELETE_PRODUCT_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    case PRODUCT_LIST_REQUEST: {
      return {
        ...state,
        loading: true,
        productList: [],
        error: "",
      };
    }
    case PRODUCT_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        productList: action.payload,
      };
    }
    case PRODUCT_LIST_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
        productList: [],
      };
    }

    case INVENTORY_PRODUCT_ITEM_REQUEST: {
      console.log("action", action.payload);

      return {
        ...state,
        loading: true,
        productList: [],
        error: "",
      };
    }
    case INVENTORY_PRODUCT_ITEM_SUCCESS: {
      return {
        ...state,
        loading: false,
        subproductList: action.payload,
      };
    }
    case INVENTORY_PRODUCT_ITEM_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
        subproductList: [],
      };
    }

    case MASTERQRCODE_LIST_INVENTORY_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case MASTERQRCODE_LIST_INVENTORY_SUCCESS: {
      return {
        ...state,
        loading: false,
        QRCodeListData: action?.payload,
      };
    }
    case MASTERQRCODE_LIST_INVENTORY_ERROR: {
      return {
        ...state,
        loading: false,
        QRCodeListData: [],
        error: action.error,
      };
    }

    case LISTQRCODE_DELETE_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case LISTQRCODE_DELETE_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case LISTQRCODE_DELETE_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    case MASTERQRCODE_CREATE_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case MASTERQRCODE_CREATE_SUCCESS: {
      return {
        ...state,
        loading: false,
        createQRCode: action?.payload,
      };
    }
    case MASTERQRCODE_CREATE_ERROR: {
      return {
        ...state,
        loading: false,
        createQRCode: [],
        error: action.error,
      };
    }
    case PRODUCT_ITEMS_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case PRODUCT_ITEMS_SUCCESS: {
      return {
        ...state,
        loading: false,
        productItems: action?.payload,
      };
    }
    case PRODUCT_ITEMS_ERROR: {
      return {
        ...state,
        loading: false,
        productItems: [],
        error: action.error,
      };
    }

    case MASTERBARCODEVIEW_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case MASTERBARCODEVIEW_SUCCESS: {
      return {
        ...state,
        loading: false,
        QRCodeViewData: action?.payload,
      };
    }
    case MASTERBARCODEVIEW_ERROR: {
      return {
        ...state,
        loading: false,
        QRCodeViewData: [],
        error: action.error,
      };
    }
    case DELETEITEM_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case DELETEITEM_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case DELETEITEM_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }

    case MASTERBARCODEVIEW_ITEMVIEW_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case MASTERBARCODEVIEW_ITEMVIEW_SUCCESS: {
      return {
        ...state,
        loading: false,
        QRCodeViewItemViewData: action?.payload,
      };
    }
    case MASTERBARCODEVIEW_ITEMVIEW_ERROR: {
      return {
        ...state,
        loading: false,
        QRCodeViewItemViewData: [],
        error: action.error,
      };
    }

    case UPDATE_ITEMTOQR_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case UPDATE_ITEMTOQR_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case UPDATE_ITEMTOQR_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }

    default:
      return state;
  }
};

export default InventoryReducer;
