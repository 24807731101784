/** @format */

import React, { useState, useRef } from "react";
import {
  FormControl,
  Box,
  Select,
  MenuItem,
  TextField,
  Grid,
  Card,
  Stack,
  FormControlLabel,
  Typography,
  Tabs,
  Tab,
  RadioGroup,
  Radio,
  Divider,
  IconButton,
  InputLabel,
  Input,
  FormGroup,
  Checkbox,
} from "@mui/material";
import { Container } from "@mui/system";
import { Button } from "material-ui-core";
import { makeStyles, createMuiTheme } from "@material-ui/core/styles";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Fab from "@material-ui/core/Fab";
import Blank from "../../../images/Blank.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect } from "react";
import questiontype, { questiontypeassessment } from "./QuestionType";
import SelectRuleIssueModal from "./SelectRuleIssueModal";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import { BASE_URL } from "../../../redux/apiConfig/BaseUrl";

import {
  RulesAddedApi,
  UploadImageAddQuesApi,
  UploadImgAddQSuccess,
} from "../../../redux/actions/Checklist";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { CompanyGetApi } from "../../../redux/actions/CreateCompany";
import { CheckBox, ConnectingAirportsOutlined } from "@mui/icons-material";
import { Delete as DeleteIcon } from "@mui/icons-material";
import ProgressbarTransparent from "../../../GlobalProgress/ProgressbarTransparent";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const useStyles = makeStyles({
  select: {
    "& li": {
      display: "block",
    },
  },
  radio: {
    "&$checked": {
      color: "#F6F8FC",
    },
  },
  checked: {},
});

const AllFormQuestion = (props) => {
  const { AssessmentQuestion, id, applyValidation, someRequiredField } = props;
  const location = useLocation();
  const returnPageName = location?.state?.addNewAssesment;
  const pathName = location?.pathname;
  const [loader, setLoader] = useState(false);
  const maxImagesAllowed = 2;
  const maxImageSize = 5 * 1024 * 1024;
  const classes = useStyles();
  const optionsAnswer = [
    { title: "Yes", value: "Yes" },
    { title: "No", value: "No" },
    { title: "Acceptable", value: "accetable" },
    { title: "Not Acceptable", value: "notAcceptable" },
    { title: "Discarded", value: "discarded" },
    { title: "Returned", value: "returned" },
    { title: "Not Applicable", value: "notApplicable" },
  ];

  const optionsAns = [
    { title: "Yes", value: false },
    { title: "No", value: false },
    { title: "Acceptable", value: false },
    { title: "Not Acceptable", value: false },
    { title: "Discarded", value: false },
    { title: "Returned", value: false },
    { title: "Not Applicable", value: false },
  ];

  const numericCondition = [
    {
      id: 1,
      title: "=",
      key: "=",
    },
    {
      id: 2,
      title: ">",
      key: ">",
    },
    {
      id: 3,
      title: ">=",
      key: ">=",
    },
    {
      id: 4,
      title: "<",
      key: "<",
    },
    {
      id: 5,
      title: "<=",
      key: "<=",
    },
  ];
  const ref = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [images, setImages] = useState([]);
  const [value, setValue] = useState(0);
  const [val, setVal] = useState("");
  const [vals, setVals] = useState("");
  const [firstData, setFirstData] = useState([]);
  const [formValues, setFormValues] = useState({});
  const [formreset, setFormReset] = useState();
  const [require, setRequire] = useState(optionsAns);
  const [RecordAudio, setRecordAudio] = useState("");
  const [targetvalue, setTargetValue] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [imageKey, setImageKey] = useState();
  const [allKey, setAllKey] = useState([]);
  const [uploadedImages, setUploadedImages] = useState([]);

  const [RecordAudioField, setRecordAudioField] = useState([
    { title: "Yes", value: false },
    { title: "No", value: false },
  ]);
  const [takeImage, setTakeImage] = useState("");
  const [takeImageData, setTakeImageData] = useState([
    { title: "Yes", value: false },
    { title: "No", value: false },
  ]);
  const [takeVideo, setTakeVideo] = useState("");
  const [takeVideoData, setTakeVideoData] = useState([
    { title: "Yes", value: false },
    { title: "No", value: false },
  ]);
  const [allowComment, setAllowComment] = useState("");
  const [allowCommentData, setAllowCommentData] = useState([
    { title: "Yes", value: false },
    { title: "No", value: false },
  ]);
  const [imageOptional, setImageOptional] = useState("");
  const [imageOptionalData, setImageOptionalData] = useState([
    { title: "Yes", value: false },
    { title: "No", value: false },
  ]);
  const [videoOptional, setVideoOptional] = useState("");
  const [videoOptionalData, setVideoOptionalData] = useState([
    { title: "Yes", value: false },
    { title: "No", value: false },
  ]);
  const [requiredComment, setRequiredComment] = useState("");
  const [assesstanswer, setAssesstAnswer] = useState({});
  const [requiredCommentData, setRequiredCommentData] = useState([
    { title: "Yes", value: false },
    { title: "No", value: false },
  ]);
  const [assessmentradio, setAssessmentRadio] = useState([]);
  const [assessmentvalue, setAssessmentValue] = useState({});
  const [validatarr, setValidatarr] = useState([]);
  const [ruleList, setRuleList] = useState([]);
  const [uiRender, setUiRender] = useState(false);
  const [weightScore, setWeightScore] = useState({});
  const [singleandmultichoice, setSingleAndMultiChoice] = useState({});
  const [dynamicweightagscore, setDynamicweightageScore] = useState({});
  const [newFieldValue, setNewFieldValue] = useState({});
  const [multiChoice, setMultiChoice] = useState([]);
  const [multiimageUrl, setMultiimageUrl] = useState([]);

  const rulesdata = useSelector(
    (state) =>
      state.checklist &&
      state.checklist.ruleslist &&
      state.checklist.ruleslist.data &&
      state.checklist.ruleslist.data.data
  );

  const companyData = useSelector(
    (state) =>
      state.company &&
      state.company.companyData &&
      state.company.companyData.data &&
      state.company.companyData.data.data &&
      state.company.companyData.data.data.list
  );

  const getImageKey = useSelector(
    (state) =>
      state.checklist &&
      state.checklist.uploadImgAddQ &&
      state.checklist.uploadImgAddQ.data &&
      state.checklist.uploadImgAddQ.data.data
  );

  // const handleImageUpload = async (e) => {
  //   const files = e.target.files;
  //   const newImages = [...multiimageUrl];
  //   const formData = new FormData();
  //   for (const file of files) {
  //     newImages.push(URL.createObjectURL(file));
  //     formData.append("files", file);
  //   }
  //   try {
  //     const imageData = {
  //       body: formData,
  //     };
  //     imageData.url = BASE_URL + `uploads/any?ngsw-bypass=true`;
  //     await dispatch(UploadImageAddQuesApi(imageData));
  //     setMultiimageUrl(newImages);
  //     e.target.value = [];
  //   } catch (error) {
  //     e.target.value = [];
  //     console.error("Error uploading images:", error);
  //   }
  // };

  const handleImageUpload = async (e) => {
    const files = e.target.files;

    for (const file of files) {
      var idxDot = file.name.lastIndexOf(".") + 1;
      var extFile = file.name.substr(idxDot, file.name.length).toLowerCase();

      if (extFile != "jpg" && extFile != "jpeg" && extFile != "png") {
        alert("Only jpg/jpeg and png files are allowed!");
        e.target.value = [];
        return;
      }

      if (file.size > maxImageSize) {
        toast.error("Only up to 5 MB images are allowed.");
        e.target.value = [];
        return;
      }

      if (files.length + multiimageUrl.length > maxImagesAllowed) {
        toast.error("Only two images upload are allowed.");
        e.target.value = [];
        return;
      }

      const newImages = [...multiimageUrl];
      const formData = new FormData();

      try {
        for (const file of files) {
          if (newImages.length < maxImagesAllowed) {
            formData.append("files", file);
            newImages.push(URL.createObjectURL(file));
          }
        }

        setMultiimageUrl(newImages);
        e.target.value = [];
        if (formData.has("files")) {
          const imageData = {
            body: formData,
          };

          imageData.url = BASE_URL + `uploads/any?ngsw-bypass=true`;
          await dispatch(UploadImageAddQuesApi(imageData));
        }
      } catch (error) {
        e.target.value = [];
        toast.error("Error uploading images: " + error.message);
      }
    }
  };

  // const handleDeleteImage = (item) => {
  //   const newAllKey = [...allKey].filter((img) => img.key !== item);
  //   setAllKey(newAllKey);
  // };

  const handleDeleteImage = (index) => {
    const newImages = [...multiimageUrl];
    const newAllKey = [...allKey];
    newImages.splice(index, 1);
    newAllKey.splice(index, 1);
    setMultiimageUrl(newImages);
    setAllKey(newAllKey);
  };

  useEffect(() => {
    if (getImageKey && getImageKey.length > 0) {
      setAllKey([...allKey, ...getImageKey]);
    }
  }, [getImageKey]);
  console.log("allKey", allKey);

  useEffect(() => {
    setRuleList(rulesdata);
  }, [rulesdata]);
  useEffect(() => {
    const id = localStorage.getItem("companyId");
    const data = { url: BASE_URL + `rule/template?type=2&company_id=${id}` };
    dispatch(RulesAddedApi(data));
  }, []);

  useEffect(() => {
    const validations = [
      {
        id: 1,
        key: "required",
        title: "Is Required?",
        type: "radio",
        answer: [...require],
      },
      {
        id: 2,
        key: "audio",
        title: "Record Audio",
        type: "radio",
        answer: [...RecordAudioField],
      },
      {
        id: 3,
        key: "image",
        title: "Take Image",
        type: "radio",
        answer: [...takeImageData],
      },
      {
        id: 4,
        key: "video",
        title: "Take Video",
        type: "radio",
        answer: [...takeVideoData],
      },
      {
        id: 5,
        key: "comment",
        title: "Always allow Comment",
        type: "radio",
        answer: [...allowCommentData],
      },
      {
        id: 6,
        key: "image_optional",
        title: "Is image optional?",
        type: "radio",
        answer: [...imageOptionalData],
      },
      {
        id: 7,
        key: "video_optional",
        title: "Is video optional?",
        type: "radio",
        answer: [...videoOptionalData],
      },
      {
        id: 8,
        key: "comment_required",
        title: "Is comment required?",
        type: "radio",
        answer: [...requiredCommentData],
      },
    ];
    setValidatarr(validations);
  }, [
    require,
    RecordAudio,
    takeImage,
    takeVideo,
    allowComment,
    imageOptional,
    videoOptional,
    requiredComment,
  ]);
  const DefultValue = localStorage.getItem("default");
  useEffect(() => {
    if (DefultValue) {
      setNewFieldValue({
        acceptable: 1,
        discarded: 0,
        no: 0,
        notAcceptable: 0,
        notApplicable: 0,
        returned: 0,
        yes: 1,
      });
    }
  }, [DefultValue]);
  useEffect(() => {
    localStorage.removeItem("editdata");
    localStorage.removeItem("questiodata");
  }, []);

  const handleDelete = (index) => {
    setFirstData(firstData.filter((item, i) => i !== index));
  };
  const listData = (values) => {
    setFormValues(values);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleRequire = (event) => {
    let events = event.target.value;
    setVal(events);
    for (let item of require) {
      if (events == "yes") {
        require[0].value = true;
        require[1].value = false;
        require[2].value = false;
        require[3].value = false;
        require[4].value = false;
        require[5].value = false;
        require[6].value = false;
      } else if (events == "no") {
        require[0].value = false;
        require[1].value = true;
        require[2].value = false;
        require[3].value = false;
        require[4].value = false;
        require[5].value = false;
        require[6].value = false;
      } else if (events == "acceptable") {
        require[0].value = false;
        require[1].value = false;
        require[2].value = true;
        require[3].value = false;
        require[4].value = false;
        require[5].value = false;
        require[6].value = false;
      } else if (events == "Not Acceptable") {
        require[0].value = false;
        require[1].value = false;
        require[2].value = false;
        require[3].value = true;
        require[4].value = false;
        require[5].value = false;
        require[6].value = false;
      } else if (events == "discarded") {
        require[0].value = false;
        require[1].value = false;
        require[2].value = false;
        require[3].value = false;
        require[4].value = true;
        require[5].value = false;
        require[6].value = false;
      } else if (events == "returned") {
        require[0].value = false;
        require[1].value = false;
        require[2].value = false;
        require[3].value = false;
        require[4].value = false;
        require[5].value = true;
        require[6].value = false;
      } else if (events == "not applicable") {
        require[0].value = false;
        require[1].value = false;
        require[2].value = false;
        require[3].value = false;
        require[4].value = false;
        require[5].value = false;
        require[6].value = true;
      }
    }
    let options = require;
    setRequire(options);
  };
  const handleRecordaudio = (event) => {
    setRecordAudio(event.target.value);
    let events = event.target.value;
    for (let item of RecordAudioField) {
      if (events == "yes") {
        RecordAudioField[0].value = true;
        RecordAudioField[1].value = false;
      } else if (events == "no") {
        RecordAudioField[0].value = false;
        RecordAudioField[1].value = true;
      }
    }
    const options = RecordAudioField;
    setRecordAudioField(options);
  };
  const handleTakeImage = (event) => {
    setTakeImage(event.target.value);
    let events = event.target.value;
    for (let item of takeImageData) {
      if (events == "yes") {
        takeImageData[0].value = true;
        takeImageData[1].value = false;
      } else if (events == "no") {
        takeImageData[0].value = false;
        takeImageData[1].value = true;
      }
    }
    let options = takeImageData;
    setTakeImageData(options);
  };
  const handleTakeVideo = (event) => {
    setTakeVideo(event.target.value);
    let events = event.target.value;
    for (let item of takeVideoData) {
      if (events == "yes") {
        takeVideoData[0].value = true;
        takeVideoData[1].value = false;
      } else if (events == "no") {
        takeVideoData[0].value = false;
        takeVideoData[1].value = true;
      }
    }
    let options = takeVideoData;
    setTakeVideoData(options);
  };
  const handleAllowComment = (event) => {
    setAllowComment(event.target.value);
    let events = event.target.value;
    for (let item of allowCommentData) {
      if (events == "yes") {
        allowCommentData[0].value = true;
        allowCommentData[1].value = false;
      } else if (events == "no") {
        allowCommentData[0].value = false;
        allowCommentData[1].value = true;
      }
    }
    let options = allowCommentData;
    setAllowCommentData(options);
  };
  const handleImageOptional = (event) => {
    setImageOptional(event.target.value);
    let events = event.target.value;
    for (let item of imageOptionalData) {
      if (events == "yes") {
        imageOptionalData[0].value = true;
        imageOptionalData[1].value = false;
      } else if (events == "no") {
        imageOptionalData[0].value = false;
        imageOptionalData[1].value = true;
      }
    }
    let options = imageOptionalData;
    setImageOptionalData(options);
  };

  const handleVideoOptional = (event) => {
    setVideoOptional(event.target.value);
    let events = event.target.value;
    for (let item of videoOptionalData) {
      if (events == "yes") {
        videoOptionalData[0].value = true;
        videoOptionalData[1].value = false;
      } else if (events == "no") {
        videoOptionalData[0].value = false;
        videoOptionalData[1].value = true;
      }
    }
    let options = videoOptionalData;
    setVideoOptionalData(options);
  };

  const handleRequiredComment = (event) => {
    setRequiredComment(event.target.value);

    let events = event.target.value;
    for (let item of requiredCommentData) {
      if (events == "yes") {
        requiredCommentData[0].value = true;
        requiredCommentData[1].value = false;
      } else if (events == "no") {
        requiredCommentData[0].value = false;
        requiredCommentData[1].value = true;
      }
    }
    let options = requiredCommentData;
    setRequiredCommentData(options);
  };
  const handleAnswers = (event) => {
    setVals(event.target.value);
    let events = event.target.value;
    if (events == "yes") {
      assesstanswer.value = true;
      assesstanswer.title = "Yes";
    } else if (events == "no") {
      assesstanswer.value = true;
      assesstanswer.title = "No";
    }
    // }
    let options = assesstanswer;
    setAssesstAnswer(options);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const navigatioToQuestion = () => {
    setLoader(true);
    if (AssessmentQuestion === "newaddNewAssesment") {
      setTimeout(() => {
        navigate("/assessment/create-assessment", {
          state: { addNewAssesment: "newaddNewAssesment" },
        });
        localStorage.removeItem("default");
      }, 1000);
    } else if (AssessmentQuestion === "lms") {
      if (returnPageName == "UpdateAssessment") {
        setTimeout(() => {
          navigate(`/assessment/edit`, {
            state: { id: id, addNewAssesment: "newUpdateAssessment" },
          });
          localStorage.removeItem("default");
        }, 1000);
      } else {
        setTimeout(() => {
          navigate("/assessment/create-assessment", {
            state: { addNewAssesment: "newaddNewAssesment" },
          });
          localStorage.removeItem("default");
        }, 1000);
      }
    } else if (AssessmentQuestion === "UpdateAssessment") {
      setTimeout(() => {
        navigate(`/assessment/edit`, {
          state: { id: id, addNewAssesment: "newUpdateAssessment" },
        });
        localStorage.removeItem("default");
      }, 1000);
    } else {
      setTimeout(() => {
        navigate("/checklist/all-form/add-form");
        localStorage.removeItem("default");
      }, 1000);
    }
    setTimeout(() => {
      dispatch(UploadImgAddQSuccess({ data: { data: [] } }));
      setLoader(false);
    }, 2000);
  };

  // let multiimageUrl =
  //   images &&
  //   images?.map((item) => {
  //     return item?.url;
  //   });

  const handleChangeRules = (event, items, index) => {
    let allRules = ruleList;
    if (event.target.name === "condition") {
      allRules[index].condition = event.target.value;
    } else {
      allRules[index].answer = event.target.value;
    }
    setRuleList(allRules);
    setUiRender(!uiRender);
  };

  const handleWeightScore = (e) => {
    const value = e.target.value;
    setWeightScore({
      ...weightScore,
      [e.target.name]: value,
    });
  };

  const handleSingleAndMultiChoice = (e, i) => {
    var key = e.target.name;
    let singleChoice = singleandmultichoice;
    singleChoice[key] = Number(e.target.value);
    setSingleAndMultiChoice(singleChoice);
    setDynamicweightageScore(e.target.value);
  };
  const [objects, setObject] = useState({});
  const handleChangeobj = (event, index, id) => {
    setObject((state) => {
      const newObject = { ...state.objects };
      newObject[`${id}`] = { value: Number(event.target.value) };
      return { objects: newObject };
    });
  };
  const handleDefaultChoice = (e) => {
    if (!singleandmultichoice) {
      let singleChoice = singleandmultichoice;
      formik?.values?.attachedlist?.items?.map(
        (data) => (singleChoice[data.title] = 1)
      );
      formik?.values?.attachedlist?.items?.forEach((data) => {
        return (singleChoice[data.title] = 1);
      });
      setSingleAndMultiChoice(singleChoice);
    }
  };

  const handleDone = () => {
    let validatorData = [];
    if (require) {
      validatorData.push({
        value: require,
        title: " Is Required? :",
      });
    }
    if (RecordAudio) {
      validatorData.push({
        value: RecordAudio,
        title: "Record Audio? :",
      });
    }
    if (takeImage) {
      validatorData.push({ value: takeImage, title: " Take Image? :" });
    }
    if (imageOptional) {
      validatorData.push({
        value: imageOptional,
        title: "imageOptional :",
      });
    }
    if (takeVideo) {
      validatorData.push({ value: takeVideo, title: " Take Video? :" });
    }
    if (videoOptional) {
      validatorData.push({
        value: videoOptional,
        title: "videoOptional :",
      });
    }
    if (allowComment) {
      validatorData.push({
        value: allowComment,
        title: "allowComment :",
      });
    }
    if (requiredComment) {
      validatorData.push({
        value: requiredComment,
        title: "requiredComment :",
      });
    }
  };

  const handleReset = () => {
    require[0].value = false;
    require[1].value = false;
    require[2].value = false;
    require[3].value = false;
    require[4].value = false;
    require[5].value = false;
    require[6].value = false;
    takeImageData[0].value = false;
    takeImageData[1].value = false;
    takeVideoData[0].value = false;
    takeVideoData[1].value = false;
    RecordAudioField[0].value = false;
    RecordAudioField[1].value = false;
    allowCommentData[0].value = false;
    allowCommentData[1].value = false;
    imageOptionalData[0].value = false;
    imageOptionalData[1].value = false;
    videoOptionalData[0].value = false;
    videoOptionalData[1].value = false;
    requiredCommentData[0].value = false;
    requiredCommentData[1].value = false;
    setVal("");
    setRecordAudio("");
    setTakeImage("");
    setTakeVideo("");
    setAllowComment("");
    setImageOptional("");
    setVideoOptional("");
    setRequiredComment("");
  };

  const isValidationFieldSelected =
    val !== "" ||
    RecordAudio !== "" ||
    takeImage !== "" ||
    takeVideo !== "" ||
    allowComment !== "" ||
    imageOptional !== "" ||
    videoOptional !== "" ||
    requiredComment !== "";

  const validationSchema = Yup.object({
    title: Yup.string("Enter your title").required("Title is required"),
    description: Yup.string("Enter your Description").required(
      "Description is required"
    ),
    questionscore: Yup.string("Enter your Question Score")
      .required("Question Score is required")
      .min(1, "Question Score must be 1 characters at minimum")
      .max(3, "Question Score must be 3 characters at maximum"),
    questiontype: Yup.string("Select your type").required("Type is required"),
  });
  let noFieldsSelected = true;

  validatarr.forEach((obj) => {
    obj.answer.forEach((option) => {
      if (option.value) {
        noFieldsSelected = false;
      }
    });
  });
  const formik = useFormik({
    initialValues: {
      // titleImages:[],
      title: "",
      description: "",
      isQuestionToInspection: false,
      questionscore: "",
      questiontype: "",
      attachedlist: null,
      option_score: newFieldValue || {
        acceptable: "",
        discarded: "",
        no: "",
        notAcceptable: "",
        notApplicable: "",
        returned: "",
        yes: "",
      },
      assesstNumber: "",
    },
    validationSchema: validationSchema,
    enableReinitialize: true,

    onSubmit: (values) => {
      const qustionDataId = localStorage.getItem("qustionDataid");
      // values.images = images;
      // if (noFieldsSelected) {
      //   toast.error("Validation fields are required");
      // }

      // if (!isValidationFieldSelected) {
      //   toast.error("Validation fields are required");
      //   return;
      // }

      if (
        !isValidationFieldSelected &&
        AssessmentQuestion !== "lms" &&
        !someRequiredField
      ) {
        toast.error("Validation fields are required");
        return;
      }

      const types = {
        validations: validatarr,
        option_score:
          singleandmultichoice &&
          singleandmultichoice &&
          values?.questiontype == "singlechoice"
            ? singleandmultichoice
            : singleandmultichoice &&
              singleandmultichoice &&
              values?.questiontype == "multiplechoice"
            ? singleandmultichoice
            : values.option_score,
        key:
          values?.questiontype?.toLowerCase() === "singlechoice"
            ? "single_select"
            : values?.questiontype?.toLowerCase() === "multiplechoice"
            ? "multi_select"
            : values?.questiontype?.toLowerCase() === "numeric"
            ? "number"
            : values?.questiontype?.toLowerCase() === "media"
            ? "file"
            : values?.questiontype?.toLowerCase(),
        title:
          values?.questiontype?.toLowerCase() === "singlechoice"
            ? "Single Choice"
            : values?.questiontype?.toLowerCase() === "multiplechoice"
            ? "Multiple Choice"
            : values?.questiontype?.charAt(0)?.toUpperCase() +
              values?.questiontype?.slice(1),
      };

      types.title_to_show =
        values?.questiontype?.toLowerCase() === "singlechoice"
          ? "Single Choice"
          : values?.questiontype?.toLowerCase() === "multiplechoice"
          ? "Multiple Choice"
          : values?.questiontype?.charAt(0)?.toUpperCase() +
            values?.questiontype?.slice(1);
      types.id = Number(qustionDataId + 1);

      const data = {
        title: values.title,
        desc: values.description,
        question_score: values.questionscore,
        titleImages: allKey?.map((index) => ({
          key: index?.key,
          name: index?.name,
          url: index?.location || index?.url,
        })),
        type: types,
        // attached_list: values.attachedlist,
        rules: firstData,
        // attached_list: null,
        order: Number(qustionDataId),
        // id: Number(qustionDataId + 1),
        id: uuidv4(),
      };
      if (pathName == "/checklist/all-form/add-form/add-question") {
        data.isQuestionToInspection = values.isQuestionToInspection;
      }
      if (AssessmentQuestion) {
        if (assessmentvalue && assessmentvalue?.answer) {
          data.answer = assessmentvalue?.answer;
          data.type.validations = [];
        } else if (values.questiontype === "numeric") {
          data.answer = values.assesstNumber ? values.assesstNumber : "";
          data.type.validations = [];
        } else if (values.questiontype === "text") {
          data.answer = values.assesstNumber ? values.assesstNumber : "";
          data.type.validations = [];
        } else {
          data.answer = assesstanswer;
          data.type.validations = [];
        }
      }
      if (data) {
        let oldQues = localStorage.getItem("questions");
        let oldAssestQus = localStorage.getItem("assestsQustions");
        const editassesquestion = localStorage.getItem("editquestionasses");
        if (AssessmentQuestion) {
          if (
            data?.answer !== "" &&
            data?.answer !== null &&
            data?.answer !== undefined
          ) {
            if (editassesquestion) {
              data.type.option_score = values.option_score;
              data.attached_list = values.attachedlist;
              let oldAssest = JSON.parse(editassesquestion);
              oldAssest.push(data);
              localStorage.setItem(
                "editquestionasses",
                JSON.stringify(oldAssest)
              );
            } else {
              data.attached_list = values.attachedlist;
              let assetArray = [];
              assetArray.push(data);
              localStorage.setItem(
                "editquestionasses",
                JSON.stringify(assetArray)
              );
            }
            if (oldAssestQus !== null) {
              if (
                values.questiontype === "singlechoice" ||
                values.questiontype === "multiplechoice"
              ) {
                if (
                  (values.attachedlist !== null && data?.answer?.title) ||
                  (values.attachedlist !== null && data?.answer[0]?.title)
                ) {
                  data.attached_list = values.attachedlist;
                  let oldAssest = JSON.parse(oldAssestQus);
                  oldAssest.push(data);
                  localStorage.setItem(
                    "assestsQustions",
                    JSON.stringify(oldAssest)
                  );
                  navigatioToQuestion();
                } else {
                  if (values.attachedlist === null) {
                    toast.error("Select item form Main List");
                  } else if (!data?.answer?.title) {
                    toast.error("Answer is Required ");
                  }
                }
              } else {
                if (values.questiontype === "boolean") {
                  if (data?.answer?.title) {
                    data.attached_list = values.attachedlist;
                    let oldAssest = JSON.parse(oldAssestQus);
                    oldAssest.push(data);
                    localStorage.setItem(
                      "assestsQustions",
                      JSON.stringify(oldAssest)
                    );
                    navigatioToQuestion();
                  } else {
                    toast.error("Answer is Required ");
                  }
                } else {
                  data.attached_list = values.attachedlist;
                  let oldAssest = JSON.parse(oldAssestQus);
                  oldAssest.push(data);
                  localStorage.setItem(
                    "assestsQustions",
                    JSON.stringify(oldAssest)
                  );
                  navigatioToQuestion();
                }
              }
            } else {
              if (
                values.questiontype === "singlechoice" ||
                values.questiontype === "multiplechoice"
              ) {
                if (
                  (values.attachedlist !== null && data?.answer?.title) ||
                  (values.attachedlist !== null && data?.answer[0]?.title)
                ) {
                  data.attached_list = values.attachedlist;
                  let assetArray = [];
                  assetArray.push(data);
                  localStorage.setItem(
                    "assestsQustions",
                    JSON.stringify(assetArray)
                  );
                  navigatioToQuestion();
                } else {
                  if (values.attachedlist === null) {
                    toast.error("Select item form Main List");
                  } else if (!data?.answer?.title) {
                    toast.error("Answer is Required ");
                  }
                }
              } else {
                if (values.questiontype === "boolean") {
                  if (data?.answer?.title) {
                    let assetArray = [];
                    data.attached_list = values.attachedlist;
                    assetArray.push(data);
                    localStorage.setItem(
                      "assestsQustions",
                      JSON.stringify(assetArray)
                    );
                    navigatioToQuestion();
                  } else {
                    toast.error("Answer is Required ");
                  }
                } else {
                  let assetArray = [];
                  data.attached_list = values.attachedlist;
                  assetArray.push(data);
                  localStorage.setItem(
                    "assestsQustions",
                    JSON.stringify(assetArray)
                  );
                  navigatioToQuestion();
                }
              }
            }
          } else {
            toast.error("Answer is Required ");
          }
        } else {
          if (oldQues) {
            if (
              values.questiontype === "singlechoice" ||
              values.questiontype === "multiplechoice"
            ) {
              if (values.attachedlist !== null) {
                data.attached_list = values.attachedlist;
                let oldD1 = JSON.parse(oldQues);
                oldD1.push(data);
                localStorage.setItem("questions", JSON.stringify(oldD1));
                navigatioToQuestion();
              } else {
                toast.error("Select item form Main List");
              }
            } else {
              data.attached_list = values.attachedlist;
              let oldD1 = JSON.parse(oldQues);
              oldD1.push(data);
              localStorage.setItem("questions", JSON.stringify(oldD1));
              navigatioToQuestion();
            }
          } else {
            if (
              values.questiontype === "singlechoice" ||
              values.questiontype === "multiplechoice"
            ) {
              if (values.attachedlist !== null) {
                data.attached_list = values.attachedlist;
                let qArray = [];
                qArray.push(data);
                localStorage.setItem("questions", JSON.stringify(qArray));
                navigatioToQuestion();
              } else {
                toast.error("Select item form Main List");
              }
            } else {
              let qArray = [];
              qArray.push(data);
              localStorage.setItem("questions", JSON.stringify(qArray));
              navigatioToQuestion();
            }
          }
        }
      } else {
        toast.error("All fields are required");
      }
    },
  });

  useEffect(() => {
    if (formik?.values?.attachedlist?.items == 0) {
      toast.error("No items in List");
    }
  }, [formik?.values?.attachedlist]);

  const handleFirst = (event, index, formValues) => {
    let item = event;
    if (event.condition && event.answer && formValues.observation) {
      if (
        formik &&
        formik?.values &&
        formik?.values?.questiontype === "multiplechoice"
      ) {
        const data = {
          condition: {
            id: index,
            key: event.condition,
            title_to_show: event.condition,
            title: event.condition,
          },
          answer: event.answer,
          issueTemplate: formValues ? formValues : "",
        };
        const requires = {};
        const newData = JSON.stringify(item);
        let newData1 = JSON.parse(newData);
        newData1.data = data;
        (newData1.requires = requires),
          (newData1.ruleString = `"When Answer is  \"<b>=</b>\"  to  \"<b>${event.answer.title}</b>\"  then allow comment."`);
        newData1.variables[0].options =
          formik?.values && formik?.values?.attachedlist?.items;
        newData1.variables[1].options = [data?.condition];
        delete newData1.condition;
        delete newData1.answer;
        firstData.push(newData1);
        setTimeout(() => {
          let allRules = ruleList;
          allRules[index].answer = "";
          allRules[index].condition = "";
          setFormValues("");
          setRuleList(allRules);
          setUiRender(!uiRender);
        }, 500);
      } else if (
        formik &&
        formik?.values &&
        formik?.values?.questiontype === "singlechoice"
      ) {
        {
          const data = {
            condition: {
              id: index,
              key: event.condition,
              title_to_show: event.condition,
              title: event.condition,
            },
            answer: event.answer,
            issueTemplate: formValues ? formValues : "",
          };
          const newData = JSON.stringify(item);
          let newData1 = JSON.parse(newData);
          newData1.data = data;
          delete newData1.condition;
          delete newData1.answer;
          firstData.push(newData1);
          // setFirstData([...firstData, newData1]);
          setTimeout(() => {
            let allRules = ruleList;
            allRules[index].answer = "";
            allRules[index].condition = "";
            setFormValues("");
            setRuleList(allRules);
            setUiRender(!uiRender);
          }, 500);
        }
      } else if (
        formik &&
        formik?.values &&
        formik?.values?.questiontype === "numeric"
      ) {
        const data = {
          condition: {
            id: event?.condition?.id,
            key: event?.condition?.title,
            title_to_show: event?.condition?.title,
            title: event?.condition?.title,
          },

          answer: Number(event?.answer),
          issueTemplate: formValues ? formValues : "",
        };
        const requires = {};
        const newData = JSON.stringify(item);
        let newData1 = JSON.parse(newData);
        (newData1.requires = requires),
          (newData1.ruleString = `"When Answer is  \"<b>=</b>\"  to  \"<b>${event.answer.title}</b>\"  then allow comment."`);
        newData1.data = data;
        newData1.variables[0].type = "number";
        newData1.variables[1].options = [numericCondition];
        delete newData1.condition;
        delete newData1.answer;
        firstData.push(newData1);
        // setFirstData([...firstData, newData1]);
        setTimeout(() => {
          let allRules = ruleList;
          allRules[index].answer = "";
          allRules[index].condition = "";
          setFormValues("");
          setRuleList(allRules);
          setUiRender(!uiRender);
        }, 500);
      } else {
        const data = {
          condition: {
            id: index,
            key: event.condition,
            title_to_show: event.condition,
            title: event.condition,
          },
          answer: {
            title: event?.answer?.title,
            value: event?.answer?.value,
            title_to_show: event.answer?.title,
          },
          issueTemplate: formValues ? formValues : "",
        };
        const requiresBooleand = {};
        const newData = JSON.stringify(item);
        let newData1 = JSON.parse(newData);
        newData1.data = data;
        newData1.requires = requiresBooleand;
        delete newData1.condition;
        delete newData1.answer;
        firstData.push(newData1);
        // setFirstData([...firstData, newData1]);
        setTimeout(() => {
          let allRules = ruleList;
          allRules[index].answer = "";
          allRules[index].condition = "";
          setFormValues("");
          setRuleList(allRules);
          setUiRender(!uiRender);
        }, 500);
      }
    } else if (
      event.condition &&
      event.answer &&
      event.subType &&
      event.subType.title !== "QuestionAutoIssue"
    ) {
      if (
        formik &&
        formik?.values &&
        formik?.values?.questiontype === "multiplechoice"
      ) {
        const data = {
          condition: {
            id: index,
            key: event.condition,
            title_to_show: event.condition,
            title: event.condition,
          },
          answer: [event.answer],
          // issueTemplate: formValues ? formValues : "",
        };
        const requires = {};
        const newData = JSON.stringify(item);
        let newData1 = JSON.parse(newData);
        newData1.data = data;
        (newData1.requires = requires),
          (newData1.ruleString = `"When Answer is  \"<b>=</b>\"  to  \"<b>${event.answer.title}</b>\"  then allow comment."`);
        newData1.variables[0].options =
          formik?.values && formik?.values?.attachedlist?.items;
        newData1.variables[1].options = [data?.condition];
        delete newData1.condition;
        delete newData1.answer;
        firstData.push(newData1);
        setTimeout(() => {
          let allRules = ruleList;
          allRules[index].answer = "";
          allRules[index].condition = "";
          setRuleList(allRules);
          setUiRender(!uiRender);
        }, 500);
      } else if (
        formik &&
        formik?.values &&
        formik?.values?.questiontype === "singlechoice"
      ) {
        {
          const data = {
            condition: {
              id: index,
              key: event.condition,
              title_to_show: event.condition,
              title: event.condition,
            },
            answer: event.answer,
            // issueTemplate: formValues ? formValues : "",
          };
          const newData = JSON.stringify(item);
          let newData1 = JSON.parse(newData);
          newData1.data = data;
          delete newData1.condition;
          delete newData1.answer;
          firstData.push(newData1);
          // setFirstData([...firstData, newData1]);
          setTimeout(() => {
            let allRules = ruleList;
            allRules[index].answer = "";
            allRules[index].condition = "";
            setRuleList(allRules);
            setUiRender(!uiRender);
          }, 500);
        }
      } else if (
        formik &&
        formik?.values &&
        formik?.values?.questiontype === "numeric"
      ) {
        const data = {
          condition: {
            id: event?.condition?.id,
            key: event?.condition?.title,
            title_to_show: event?.condition?.title,
            title: event?.condition?.title,
          },
          answer: Number(event?.answer),
          // issueTemplate: formValues ? formValues : "",
        };
        const requires = {};
        const newData = JSON.stringify(item);
        let newData1 = JSON.parse(newData);
        newData1.data = data;
        newData1.variables[0].type = "number";
        newData1.variables[1].options = [numericCondition];
        (newData1.requires = requires),
          (newData1.ruleString = `"When Answer is  \"<b>=</b>\"  to  \"<b>${event.answer}</b>\"  then allow comment."`);
        delete newData1.condition;
        delete newData1.answer;
        firstData.push(newData1);
        // setFirstData([...firstData, newData1]);
        setTimeout(() => {
          let allRules = ruleList;
          allRules[index].answer = "";
          allRules[index].condition = "";
          setRuleList(allRules);
          setUiRender(!uiRender);
        }, 500);
      } else {
        const data = {
          condition: {
            id: index,
            key: event.condition,
            title_to_show: event.condition,
            title: event.condition,
          },
          answer: {
            title: event?.answer?.title,
            value: event?.answer?.value,
            title_to_show: event.answer?.title,
          },
          // issueTemplate: formValues ? formValues : "",
        };
        const newData = JSON.stringify(item);
        let newData1 = JSON.parse(newData);
        newData1.data = data;
        delete newData1.condition;
        delete newData1.answer;
        firstData.push(newData1);
        // setFirstData([...firstData, newData1]);
        setTimeout(() => {
          let allRules = ruleList;
          allRules[index].answer = "";
          allRules[index].condition = "";
          setRuleList(allRules);
          setUiRender(!uiRender);
        }, 500);
      }
    } else {
      toast.error("All fields are required");
      return;
    }
    dispatch(UploadImgAddQSuccess({ data: { data: [] } }));
  };
  const getCompanyData = () => {
    const cid = localStorage.getItem("companyId");
    const data = { url: BASE_URL + `companies/${cid}` };
    dispatch(CompanyGetApi(data));
  };
  useEffect(() => {
    getCompanyData();
  }, []);

  useEffect(() => {
    if (
      formik?.values?.questiontype === "text" ||
      "media" ||
      "date " ||
      "time" ||
      "datetime" ||
      "timer" ||
      AssessmentQuestion
    ) {
      setValue(0);
    }
  }, [formik.values.questiontype]);

  useEffect(() => {
    if (
      formik &&
      formik?.values &&
      formik?.values?.attachedlist &&
      formik?.values?.attachedlist?.items
    ) {
      setAssessmentRadio(formik?.values?.attachedlist?.items);
    }
  }, [formik?.values?.attachedlist]);

  useEffect(() => {
    if (multiChoice?.length > 0) {
      for (let item of assessmentradio) {
        assessmentvalue.answer = multiChoice;
      }
      let optionsRadio = assessmentvalue;
      setAssessmentValue(optionsRadio);
    }
  }, [multiChoice]);
  const handleassessts = (event) => {
    let events = event.target.value;
    setVal(events);
    for (let item of assessmentradio) {
      if (events == item.title) {
        assessmentvalue.answer = { id: item.id, title: item.title };
      }
    }
    let optionsRadio = assessmentvalue;
    setAssessmentValue(optionsRadio);
    // setRequire(event.target.value);
  };

  return (
    <>
      <ToastContainer autoClose={3000} />
      <form onSubmit={formik.handleSubmit}>
        {/* header Start */}
        <Box
          sx={{ bgcolor: "#F6F8FC", borderRadius: "8px", mb: 1 }}
          className="header-card"
        >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Stack direction="row" spacing={1} sx={{ float: "right" }}>
                <Button
                  className="cancle-button cancle-hover"
                  onClick={() => {
                    dispatch(UploadImgAddQSuccess({ data: { data: [] } }));
                    navigate(-1);
                  }}
                >
                  <CloseOutlinedIcon sx={{ mr: 1 }} />
                  Cancel
                </Button>

                <Button
                  type="submit"
                  className="header-add cancle-hover"
                  onClick={handleDone}
                >
                  <CheckOutlinedIcon sx={{ mr: 1 }} />
                  Done
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Box>
        {/* header End */}
        {loader ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <ProgressbarTransparent play />
          </div>
        ) : (
          <Box sx={{ bgcolor: "#F6F8FC", p: 1, borderRadius: "8px" }}>
            <Container
              maxWidth="lg"
              sx={{
                backgroundColor: "#fff",
                borderRadius: "10px",
                p: 1,
                textAlign: "left",
              }}
            >
              {/* <FormControl fullWidth sx={{ mb: 2 }}>
              <label>Title Images</label>
              <Box className="attachment-file">
                <input
                  accept="image/*"
                  className="file-upload"
                  id="contained-button-file"
                  multiple
                  type="file"
                  hidden
                  onChange={handleImageUpload}
                />
                <label htmlFor="contained-button-file">
                  <Fab component="span" className="store-file cancle-hover">
                    <AddOutlinedIcon />
                  </Fab>
                </label>
                <Box>
                  <Box>
                    {multiimageUrl?.length > 0 ? (
                      multiimageUrl &&
                      multiimageUrl?.map((item) => {
                        return (
                          <img
                            className="file-preview"
                            src={item}
                            alt="attachment"
                          />
                        );
                      })
                    ) : (
                      <Box>
                        <img
                          className="file-preview"
                          src={Blank}
                          alt="attachment"
                        />
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
            </FormControl> */}

              <FormControl fullWidth sx={{ mb: 2 }}>
                <label>Title Images</label>
                <Box className="attachment-file">
                  <input
                    accept="image/png, image/jpeg"
                    className="file-upload"
                    id="contained-button-file"
                    multiple
                    type="file"
                    hidden
                    onChange={handleImageUpload}
                  />
                  <label htmlFor="contained-button-file">
                    <Fab component="span" className="store-file cancle-hover">
                      <AddOutlinedIcon />
                    </Fab>
                  </label>
                  <Box display="flex" flexDirection="row">
                    {multiimageUrl && multiimageUrl.length > 0 ? (
                      multiimageUrl.map((url, index) => {
                        return (
                          <div key={index} className="image-container">
                            <div className="image-wrapper">
                              <img
                                className="file-preview"
                                src={url}
                                alt={`attachment-${index}`}
                              />
                              <div className="delete-container">
                                <DeleteIcon
                                  className="delete-icon"
                                  onClick={() => handleDeleteImage(index)}
                                  style={{
                                    width: "24px",
                                    height: "24px",
                                    // color: "#ff0000",
                                    color: "#FFFFFF",
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <Box>
                        <img
                          className="file-preview"
                          src={Blank}
                          alt="attachment"
                        />
                      </Box>
                    )}
                  </Box>
                </Box>
              </FormControl>
              {pathName == "/checklist/all-form/add-form/add-question" && (
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <FormControlLabel
                    className="label-name"
                    control={
                      <Checkbox
                        type="checkbox"
                        name="isQuestionToInspection"
                        onChange={formik.handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                        sx={{ color: "#196773" }}
                        checked={formik?.values?.isQuestionToInspection}
                      />
                    }
                    label="Add Question to Inspection"
                  />
                </FormControl>
              )}
              <FormControl fullWidth sx={{ mb: 2 }}>
                <label>Title</label>
                <TextField
                  name="title"
                  value={formik?.values?.title}
                  onChange={formik.handleChange}
                  error={formik.touched.title && Boolean(formik.errors.title)}
                  helperText={formik.touched.title && formik.errors.title}
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  className="date-input"
                />
              </FormControl>

              <FormControl fullWidth sx={{ mb: 2 }}>
                <label>Description</label>
                <TextField
                  name="description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.description &&
                    Boolean(formik.errors.description)
                  }
                  helperText={
                    formik.touched.description && formik.errors.description
                  }
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  multiline
                  className="date-input"
                />
              </FormControl>

              <FormControl fullWidth sx={{ mb: 2 }}>
                <label>Question Score</label>
                <TextField
                  name="questionscore"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{ inputProps: { min: pathName.includes("assessment") ? 0 : 1 } }}
                  type="number"
                  value={formik?.values?.questionscore}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.questionscore &&
                    Boolean(formik.errors.questionscore)
                  }
                  helperText={
                    formik.touched.questionscore && formik.errors.questionscore
                  }
                  id="filled-number"
                  variant="outlined"
                  size="small"
                  className="date-input"
                />
              </FormControl>

              {AssessmentQuestion == "lms" ? (
                <>
                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <label>Select Question Type</label>
                    <Select
                      size="small"
                      name="questiontype"
                      value={formik?.values?.questiontype}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.questiontype &&
                        Boolean(formik.errors.questiontype)
                      }
                      helperText={
                        formik.touched.questiontype &&
                        formik.errors.questiontype
                      }
                      MenuProps={{
                        disableScrollLock: true,
                        classes: { paper: classes.select },
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "center",
                        },

                        sx: {
                          height: "400px",
                          display: "block",
                        },
                      }}
                      sx={{ borderRadius: "8px" }}
                    >
                      <MenuItem disabled value="" className="select-item">
                        Type
                      </MenuItem>
                      {questiontypeassessment &&
                        questiontypeassessment?.map((option, i) => {
                          return (
                            <MenuItem
                              key={i}
                              value={option.key}
                              className="select-item"
                            >
                              {option.title ?? option.id}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </>
              ) : (
                <>
                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <label>Select Question Type</label>
                    <Select
                      size="small"
                      name="questiontype"
                      value={formik?.values?.questiontype}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.questiontype &&
                        Boolean(formik.errors.questiontype)
                      }
                      helperText={
                        formik.touched.questiontype &&
                        formik.errors.questiontype
                      }
                      MenuProps={{
                        disableScrollLock: true,
                        classes: { paper: classes.select },
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "center",
                        },

                        sx: {
                          height: "400px",
                          display: "block",
                        },
                      }}
                      sx={{ borderRadius: "8px" }}
                    >
                      <MenuItem disabled value="" className="select-item">
                        Type
                      </MenuItem>
                      {questiontype?.map((option, i) => {
                        return (
                          <MenuItem
                            key={i}
                            value={option.key}
                            className="select-item"
                          >
                            {option.title ?? option.id}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </>
              )}
              {formik &&
                formik?.values &&
                formik?.values?.questiontype === "singlechoice" && (
                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <label>Main List</label>
                    <Select
                      // onBlur={onKeyUp}
                      size="small"
                      name="attachedlist"
                      value={formik?.values?.attachedlist}
                      onChange={(event) => {
                        formik.handleChange(event);
                        let singleChoicesss = {};
                        event.target.value?.items?.map(
                          (data) => (singleChoicesss[data.title] = 1)
                        );
                        setSingleAndMultiChoice(singleChoicesss);
                      }}
                      error={
                        formik.touched.mainlist &&
                        Boolean(formik.errors.mainlist)
                      }
                      helperText={
                        formik.touched.mainlist && formik.errors.mainlist
                      }
                      MenuProps={{
                        disableScrollLock: true,
                        classes: { paper: classes.select },
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "center",
                        },

                        sx: {
                          height: "400px",
                          display: "block",
                        },
                      }}
                      sx={{
                        borderRadius: "8px",
                        textAlign: "left",
                        pl: "10px",
                      }}
                    >
                      <MenuItem disabled value="" className="select-item">
                        Type
                      </MenuItem>
                      {companyData &&
                        companyData?.map((option, i) => {
                          return (
                            <MenuItem
                              key={i}
                              value={option}
                              className="select-item"
                            >
                              {option.title ?? option.id}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                )}
              {formik &&
                formik?.values &&
                formik?.values?.questiontype === "multiplechoice" && (
                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <label>Main List</label>
                    <Select
                      size="small"
                      name="attachedlist"
                      value={formik?.values?.mainlist}
                      onChange={(event) => {
                        formik.handleChange(event);
                        let singleChoicesss = {};
                        event.target.value?.items?.map(
                          (data) => (singleChoicesss[data.title] = 1)
                        );
                        setSingleAndMultiChoice(singleChoicesss);
                      }}
                      error={
                        formik.touched.mainlist &&
                        Boolean(formik.errors.mainlist)
                      }
                      helperText={
                        formik.touched.mainlist && formik.errors.mainlist
                      }
                      MenuProps={{
                        disableScrollLock: true,
                        classes: { paper: classes.select },
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "center",
                        },
                        sx: {
                          height: "400px",
                          display: "block",
                        },
                      }}
                      sx={{ borderRadius: "8px" }}
                    >
                      <MenuItem disabled value="" className="select-item">
                        Type
                      </MenuItem>
                      {companyData &&
                        companyData?.map((option, i) => {
                          return (
                            <MenuItem
                              key={i}
                              value={option}
                              className="select-item"
                            >
                              {option.title ?? option.id}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                )}

              {formik.values.questiontype ? (
                <Box className="tab-box">
                  <Box
                    sx={{
                      borderBottom: 1,
                      borderColor: "divider",
                      background: "#F6F8FC",
                    }}
                  >
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      aria-label="basic tabs example"
                    >
                      <Tab
                        label={AssessmentQuestion ? "Answer" : "Validation"}
                        {...a11yProps(0)}
                        className="tab-item"
                      />
                      {formik &&
                      formik?.values &&
                      formik?.values?.questiontype === "text" ? (
                        ""
                      ) : formik &&
                        formik?.values &&
                        formik?.values?.questiontype === "numeric" ? (
                        ""
                      ) : formik &&
                        formik?.values &&
                        formik?.values?.questiontype === "multiplechoice" ? (
                        ""
                      ) : formik &&
                        formik?.values &&
                        formik?.values?.questiontype === "media" ? (
                        ""
                      ) : formik &&
                        formik?.values &&
                        formik?.values?.questiontype === "date" ? (
                        ""
                      ) : formik &&
                        formik?.values &&
                        formik?.values?.questiontype === "Time" ? (
                        ""
                      ) : formik &&
                        formik?.values &&
                        formik?.values?.questiontype === "DateTime" ? (
                        ""
                      ) : formik &&
                        formik?.values &&
                        formik?.values?.questiontype === "Timer" ? (
                        ""
                      ) : formik &&
                        formik?.values &&
                        formik?.values?.questiontype === "timer" ? (
                        ""
                      ) : AssessmentQuestion === "AssessmentQuestion" ||
                        AssessmentQuestion === "newaddNewAssesment" ||
                        AssessmentQuestion === "UpdateAssessment" ? (
                        ""
                      // ) : pathName ==
                      //   "/assessment/create-assessment/assessment-question" ? (
                      //   ""
                      ) : (
                        <Tab
                          label="Rules"
                          {...a11yProps(1)}
                          className="tab-item"
                        />
                      )}
                      {formik &&
                      formik?.values &&
                      formik?.values?.questiontype === "text" &&
                      AssessmentQuestion === undefined ? (
                        ""
                      ) : formik &&
                        formik?.values &&
                        formik?.values?.questiontype === "media" ? (
                        ""
                      ) : formik &&
                        formik?.values &&
                        formik?.values?.questiontype === "numeric" ? (
                        ""
                      ) : formik &&
                        formik?.values &&
                        formik?.values?.questiontype === "multiplechoice" ? (
                        ""
                      ) : //  formik &&
                      //   formik?.values &&
                      //   formik?.values?.questiontype === "date" ? (
                      //   ""
                      // ) :
                      // formik &&
                      //   formik?.values &&
                      //   formik?.values?.questiontype === "Time" ? (
                      //   ""
                      // ) : formik &&
                      //   formik?.values &&
                      //   formik?.values?.questiontype === "DateTime" ? (
                      //   ""
                      // )
                      // AssessmentQuestion != "lms" &&

                        formik &&
                        formik?.values &&
                        (formik?.values?.questiontype === "date" ||
                          formik?.values?.questiontype === "Time" ||
                          formik?.values?.questiontype === "DateTime") ? (
                        <Tab
                          label="Rules"
                          {...a11yProps(3)}
                          className="tab-item"
                        />
                      ) : formik &&
                        formik?.values &&
                        formik?.values?.questiontype === "Timer" || 
                        formik?.values?.questiontype === "text" ? (
                        ""
                      ) :
                      // AssessmentQuestion != "lms" ?
                       (
                        <Tab
                          label="Weightage Score"
                          {...a11yProps(2)}
                          className="tab-item"
                        />
                      ) 
                      // : null
                      }
                    </Tabs>
                  </Box>
                  <TabPanel value={value} index={0}>
                    <>
                      {" "}
                      {AssessmentQuestion &&
                      formik?.values?.questiontype === "singlechoice"
                        ? formik &&
                          formik?.values &&
                          formik?.values?.attachedlist &&
                          formik?.values?.attachedlist?.items.map(
                            (data, index) => {
                              return (
                                <>
                                  <FormControl fullWidth key={index}>
                                    <RadioGroup
                                      onChange={handleassessts}
                                      value={val ? val : ""}
                                      row
                                      name="row-radio-buttons-group"
                                      sx={{ p: 0 }}
                                      className="radio-btn"
                                    >
                                      <FormControlLabel
                                        value={data.title}
                                        control={
                                          <Radio
                                            size="small"
                                            sx={{
                                              "&, &.Mui-checked": {
                                                color: "#196773",
                                              },
                                            }}
                                          />
                                        }
                                        label={data.title}
                                      />
                                    </RadioGroup>
                                  </FormControl>
                                </>
                              );
                            }
                          )
                        : AssessmentQuestion &&
                          formik?.values?.questiontype === "multiplechoice"
                        ? formik &&
                          formik?.values &&
                          formik?.values?.attachedlist &&
                          formik?.values?.attachedlist?.items.map(
                            (data, index) => {
                              return (
                                <FormControl fullWidth key={index}>
                                  <Box
                                    sx={{ display: "block", margin: "5px 0" }}
                                  >
                                    <input
                                      value={multiChoice}
                                      type="checkbox"
                                      onChange={(event) => {
                                        if (event.target.checked) {
                                          setMultiChoice([
                                            ...multiChoice,
                                            { id: data.id, title: data.title },
                                          ]);
                                        } else {
                                          setMultiChoice(
                                            multiChoice.filter(
                                              (choice) => choice.id !== data.id
                                            )
                                          );
                                        }
                                      }}
                                    />
                                    <label
                                      style={{
                                        "&, &.Mui-checked": {
                                          color: "#196773",
                                        },
                                      }}
                                    >
                                      {data?.title}
                                    </label>
                                  </Box>
                                </FormControl>
                              );
                            }
                          )
                        : ""}
                      {AssessmentQuestion &&
                      formik?.values?.questiontype == "boolean" ? (
                        <FormControl fullWidth>
                          <RadioGroup
                            value={vals ? vals : ""}
                            onChange={handleAnswers}
                            row
                            name="row-radio-buttons-group"
                            sx={{ p: 0 }}
                            className="radio-btn"
                          >
                            <FormControlLabel
                              value="yes"
                              control={
                                <Radio
                                  size="small"
                                  sx={{
                                    "&, &.Mui-checked": {
                                      color: "#196773",
                                    },
                                  }}
                                />
                              }
                              label="Yes"
                            />
                            <FormControlLabel
                              value="no"
                              control={
                                <Radio
                                  size="small"
                                  sx={{
                                    "&, &.Mui-checked": {
                                      color: "#196773",
                                    },
                                  }}
                                />
                              }
                              label="No"
                            />
                          </RadioGroup>
                        </FormControl>
                      ) : (
                        ""
                      )}
                      {AssessmentQuestion &&
                      formik?.values?.questiontype == "numeric" ? (
                        <FormControl hiddenLabel sx={{ mb: 2 }}>
                          <TextField
                            name="assesstNumber"
                            autoComplete="off"
                            sx={{
                              width: { sm: 80, md: 150 },
                              "& .MuiInputBase-root": {
                                height: 35,
                              },
                            }}
                            variant="outlined"
                            disableUnderline={true}
                            InputProps={{
                              inputProps: { min: 1 },
                            }}
                            type="text"
                            value={formik?.values?.assesstNumber}
                            onChange={formik.handleChange}
                            id="filled-number"
                            size="md"
                            className="date-input"
                          />
                        </FormControl>
                      ) : (
                        ""
                      )}
                      {AssessmentQuestion &&
                      formik?.values?.questiontype == "text" ? (
                        <FormControl hiddenLabel sx={{ mb: 2 }}>
                          <TextField
                            name="assesstNumber"
                            autoComplete="off"
                            sx={{
                              width: { sm: 80, md: 150 },
                              "& .MuiInputBase-root": {
                                height: 35,
                              },
                            }}
                            variant="outlined"
                            disableUnderline={true}
                            InputProps={{
                              inputProps: { min: 1 },
                            }}
                            type="text"
                            value={formik?.values?.assesstNumber}
                            onChange={formik.handleChange}
                            id="filled-number"
                            size="md"
                            className="date-input"
                          />
                        </FormControl>
                      ) : (
                        ""
                      )}
                      {AssessmentQuestion ? (
                        ""
                      ) : (
                        <>
                          <FormControl fullWidth>
                            <label>1. Is Required?</label>
                            <RadioGroup
                              onChange={handleRequire}
                              value={val ? val : ""}
                              row
                              name="row-radio-buttons-group"
                              sx={{ p: 0 }}
                              className="radio-btn"
                            >
                              <FormControlLabel
                                value="yes"
                                control={
                                  <Radio
                                    size="small"
                                    sx={{
                                      "&, &.Mui-checked": {
                                        color: "#196773",
                                      },
                                    }}
                                  />
                                }
                                label="Yes"
                              />

                              <FormControlLabel
                                value="no"
                                control={
                                  <Radio
                                    size="small"
                                    sx={{
                                      "&, &.Mui-checked": {
                                        color: "#196773",
                                      },
                                    }}
                                  />
                                }
                                label="No"
                              />
                            </RadioGroup>
                          </FormControl>
                          <FormControl fullWidth>
                            <label>2. Record Audio</label>
                            <RadioGroup
                              value={RecordAudio}
                              onChange={handleRecordaudio}
                              row
                              name="row-radio-buttons-group"
                              sx={{ p: 0 }}
                              className="radio-btn"
                            >
                              <FormControlLabel
                                value="yes"
                                control={
                                  <Radio
                                    size="small"
                                    sx={{
                                      "&, &.Mui-checked": {
                                        color: "#196773",
                                      },
                                    }}
                                  />
                                }
                                label="Yes"
                              />
                              <FormControlLabel
                                value="no"
                                control={
                                  <Radio
                                    size="small"
                                    sx={{
                                      "&, &.Mui-checked": {
                                        color: "#196773",
                                      },
                                    }}
                                  />
                                }
                                label="No"
                              />
                            </RadioGroup>
                          </FormControl>
                          <FormControl fullWidth>
                            <label>3. Take Image</label>
                            <RadioGroup
                              value={takeImage}
                              onChange={handleTakeImage}
                              row
                              name="row-radio-buttons-group"
                              sx={{ p: 0 }}
                              className="radio-btn"
                            >
                              <FormControlLabel
                                value="yes"
                                control={
                                  <Radio
                                    size="small"
                                    sx={{
                                      "&, &.Mui-checked": {
                                        color: "#196773",
                                      },
                                    }}
                                  />
                                }
                                label="Yes"
                              />
                              <FormControlLabel
                                value="no"
                                control={
                                  <Radio
                                    size="small"
                                    sx={{
                                      "&, &.Mui-checked": {
                                        color: "#196773",
                                      },
                                    }}
                                  />
                                }
                                label="No"
                              />
                            </RadioGroup>
                          </FormControl>
                          <FormControl fullWidth>
                            <label>4. Take Video</label>
                            <RadioGroup
                              value={takeVideo}
                              onChange={handleTakeVideo}
                              row
                              name="row-radio-buttons-group"
                              sx={{ p: 0 }}
                              className="radio-btn"
                            >
                              <FormControlLabel
                                value="yes"
                                control={
                                  <Radio
                                    size="small"
                                    sx={{
                                      "&, &.Mui-checked": {
                                        color: "#196773",
                                      },
                                    }}
                                  />
                                }
                                label="Yes"
                              />
                              <FormControlLabel
                                value="no"
                                control={
                                  <Radio
                                    size="small"
                                    sx={{
                                      "&, &.Mui-checked": {
                                        color: "#196773",
                                      },
                                    }}
                                  />
                                }
                                label="No"
                              />
                            </RadioGroup>
                          </FormControl>
                          <FormControl fullWidth>
                            <label>5. Always allow Comment</label>
                            <RadioGroup
                              value={allowComment ? allowComment : ""}
                              onChange={handleAllowComment}
                              row
                              name="row-radio-buttons-group"
                              sx={{ p: 0 }}
                              className="radio-btn"
                            >
                              <FormControlLabel
                                value="yes"
                                control={
                                  <Radio
                                    size="small"
                                    sx={{
                                      "&, &.Mui-checked": {
                                        color: "#196773",
                                      },
                                    }}
                                  />
                                }
                                label="Yes"
                              />
                              <FormControlLabel
                                value="no"
                                control={
                                  <Radio
                                    size="small"
                                    sx={{
                                      "&, &.Mui-checked": {
                                        color: "#196773",
                                      },
                                    }}
                                  />
                                }
                                label="No"
                              />
                            </RadioGroup>
                          </FormControl>
                          <FormControl fullWidth>
                            <label>6. Is image optional?</label>
                            <RadioGroup
                              value={imageOptional}
                              onChange={handleImageOptional}
                              row
                              name="row-radio-buttons-group"
                              sx={{ p: 0 }}
                              className="radio-btn"
                            >
                              <FormControlLabel
                                value="yes"
                                control={
                                  <Radio
                                    size="small"
                                    sx={{
                                      "&, &.Mui-checked": {
                                        color: "#196773",
                                      },
                                    }}
                                  />
                                }
                                label="Yes"
                              />
                              <FormControlLabel
                                value="no"
                                control={
                                  <Radio
                                    size="small"
                                    sx={{
                                      "&, &.Mui-checked": {
                                        color: "#196773",
                                      },
                                    }}
                                  />
                                }
                                label="No"
                              />
                            </RadioGroup>
                          </FormControl>
                          <FormControl fullWidth>
                            <label>7. Is video optional?</label>
                            <RadioGroup
                              value={videoOptional}
                              onChange={handleVideoOptional}
                              row
                              name="row-radio-buttons-group"
                              sx={{ p: 0 }}
                              className="radio-btn"
                            >
                              <FormControlLabel
                                value="yes"
                                control={
                                  <Radio
                                    size="small"
                                    sx={{
                                      "&, &.Mui-checked": {
                                        color: "#196773",
                                      },
                                    }}
                                  />
                                }
                                label="Yes"
                              />
                              <FormControlLabel
                                value="no"
                                control={
                                  <Radio
                                    size="small"
                                    sx={{
                                      "&, &.Mui-checked": {
                                        color: "#196773",
                                      },
                                    }}
                                  />
                                }
                                label="No"
                              />
                            </RadioGroup>
                          </FormControl>
                          <FormControl fullWidth>
                            <label>8. Is comment required?</label>
                            <RadioGroup
                              value={requiredComment ? requiredComment : ""}
                              onChange={handleRequiredComment}
                              row
                              name="row-radio-buttons-group"
                              sx={{ p: 0 }}
                              className="radio-btn"
                            >
                              <FormControlLabel
                                value="yes"
                                control={
                                  <Radio
                                    size="small"
                                    sx={{
                                      "&, &.Mui-checked": {
                                        color: "#196773",
                                      },
                                    }}
                                  />
                                }
                                label="Yes"
                              />
                              <FormControlLabel
                                value="no"
                                control={
                                  <Radio
                                    size="small"
                                    sx={{
                                      "&, &.Mui-checked": {
                                        color: "#196773",
                                      },
                                    }}
                                  />
                                }
                                label="No"
                              />
                            </RadioGroup>
                          </FormControl>

                          <Button
                            // type="submit"
                            className="header-add cancle-hover"
                            onClick={handleReset}
                          >
                            Reset All
                          </Button>
                        </>
                      )}
                    </>
                  </TabPanel>
                  <TabPanel
                    value={value}
                    index={
                      AssessmentQuestion === "newaddNewAssesment" ||
                      AssessmentQuestion === "UpdateAssessment"
                        ? 2
                        : 1
                    }
                  >
                    {formik &&
                    formik?.values &&
                    formik?.values?.questiontype === "text" ? (
                      ""
                    ) : formik &&
                      formik?.values &&
                      formik?.values?.questiontype === "numeric" ? (
                      ""
                    ) : formik &&
                      formik?.values &&
                      formik?.values?.questiontype === "multiplechoice" ? (
                      ""
                    ) : formik &&
                      formik?.values &&
                      formik?.values?.questiontype === "media" ? (
                      ""
                    ) : formik &&
                      formik?.values &&
                      formik?.values?.questiontype === "date" ? (
                      ""
                    ) : formik &&
                      formik?.values &&
                      formik?.values?.questiontype === "Time" ? (
                      ""
                    ) : formik &&
                      formik?.values &&
                      formik?.values?.questiontype === "DateTime" ? (
                      ""
                    ) : formik &&
                      formik?.values &&
                      formik?.values?.questiontype === "Timer" ? (
                      ""
                    ) : (
                      <>
                        {" "}
                        <Grid container spacing={1} sx={{ textAlign: "left" }}>
                          <Grid item xs={12}>
                            <Box sx={{ fontWeight: "bold" }}>Rules</Box>
                            <Box sx={{ fontWeight: "medium", mt: 1, mb: 2 }}>
                              Rules Preset
                            </Box>
                            {ruleList &&
                              ruleList.map((items, i) => {
                                return (
                                  <Card sx={{ p: 3, mb: 2 }} key={i}>
                                    <Grid
                                      container
                                      spacing={1}
                                      sx={{
                                        textAlign: "left",
                                        padding: "20px 0px 20px 0px",
                                      }}
                                    >
                                      <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={11}
                                      >
                                        <Stack
                                          direction="row"
                                          spacing={1}
                                          className="list-btn third-list"
                                        >
                                          <Typography>
                                            {items?.template?.split(/[{,}]/)[0]}
                                          </Typography>
                                          {items?.template?.split(
                                            /[{,}]/
                                          )[1] === "condition" ? (
                                            formik &&
                                            formik?.values &&
                                            formik?.values?.questiontype ===
                                              "numeric" ? (
                                              <FormControl
                                                sx={{
                                                  width: { sm: 80, md: 150 },
                                                  "& .MuiInputBase-root": {
                                                    height: 35,
                                                  },
                                                }}
                                                className="Rules-preset-form"
                                              >
                                                {formik &&
                                                formik?.values &&
                                                formik?.values?.questiontype ===
                                                  "numeric" ? (
                                                  <Select
                                                    labelId="demo-simple-select-label"
                                                    size="small"
                                                    id="demo-simple-select"
                                                    name="condition"
                                                    defaultValue="select"
                                                    value={
                                                      items && items.condition
                                                        ? items.condition.title
                                                        : "Select Condition"
                                                    }
                                                    renderValue={(
                                                      selectvalue
                                                    ) => selectvalue}
                                                    onChange={(e) => {
                                                      handleChangeRules(
                                                        e,
                                                        items,
                                                        i
                                                      );
                                                    }}
                                                    sx={{ borderRadius: "8px" }}
                                                  >
                                                    <MenuItem
                                                      disabled
                                                      value="select"
                                                    >
                                                      <em
                                                        style={{
                                                          fontStyle: "unset",
                                                          fontSize: "12px",
                                                          color: "darkgrey",
                                                        }}
                                                      >
                                                        Select Condition
                                                      </em>
                                                    </MenuItem>
                                                    {numericCondition &&
                                                      numericCondition.map(
                                                        (select, i) => {
                                                          return (
                                                            <MenuItem
                                                              key={i}
                                                              value={select}
                                                              className="select-item"
                                                            >
                                                              {select?.title}
                                                            </MenuItem>
                                                          );
                                                        }
                                                      )}
                                                  </Select>
                                                ) : (
                                                  <Select
                                                    labelId="demo-simple-select-label"
                                                    size="small"
                                                    id="demo-simple-select"
                                                    name="condition"
                                                    defaultValue="select"
                                                    value={
                                                      items && items.condition
                                                        ? items.condition
                                                        : "select"
                                                    }
                                                    onChange={(e) =>
                                                      handleChangeRules(
                                                        e,
                                                        items,
                                                        i
                                                      )
                                                    }
                                                    sx={{ borderRadius: "8px" }}
                                                  >
                                                    <MenuItem
                                                      value="="
                                                      className="select-item"
                                                    >
                                                      =
                                                    </MenuItem>
                                                  </Select>
                                                )}
                                              </FormControl>
                                            ) : (
                                              <FormControl
                                                sx={{
                                                  width: { sm: 80, md: 150 },
                                                  "& .MuiInputBase-root": {
                                                    height: 35,
                                                  },
                                                }}
                                                className="Rules-preset-form"
                                              >
                                                <Select
                                                  labelId="demo-simple-select-label"
                                                  size="small"
                                                  id="demo-simple-select"
                                                  name="condition"
                                                  defaultValue="select"
                                                  value={
                                                    items && items.condition
                                                      ? items.condition
                                                      : "select"
                                                  }
                                                  onChange={(e) => {
                                                    handleChangeRules(
                                                      e,
                                                      items,
                                                      i
                                                    );
                                                  }}
                                                  sx={{ borderRadius: "8px" }}
                                                >
                                                  <MenuItem
                                                    disabled
                                                    value="select"
                                                  >
                                                    <em
                                                      style={{
                                                        fontStyle: "unset",
                                                        fontSize: "12px",
                                                        color: "darkgrey",
                                                      }}
                                                    >
                                                      Select Condition
                                                    </em>
                                                  </MenuItem>
                                                  <MenuItem
                                                    value="="
                                                    className="select-item"
                                                  >
                                                    =
                                                  </MenuItem>
                                                </Select>
                                              </FormControl>
                                            )
                                          ) : (
                                            ""
                                          )}
                                          <Typography>
                                            {items?.template?.split(/[{,}]/)[2]}
                                          </Typography>
                                          {items?.template?.split(
                                            /[{,}]/
                                          )[3] === "answer" ? (
                                            formik &&
                                            formik?.values &&
                                            formik?.values?.questiontype ===
                                              "singlechoice" &&
                                            formik?.values &&
                                            formik?.values?.attachedlist ? (
                                              <FormControl className="Rules-preset-form">
                                                <Select
                                                  size="small"
                                                  defaultValue="select"
                                                  labelId="demo-simple-select-label"
                                                  id="demo-simple-select"
                                                  name="answer"
                                                  value={
                                                    items && items.answer
                                                      ? items?.answer
                                                      : "select"
                                                  }
                                                  onChange={(e) => {
                                                    handleChangeRules(
                                                      e,
                                                      items,
                                                      i
                                                    );
                                                  }}
                                                  MenuProps={{
                                                    disableScrollLock: true,
                                                    anchorOrigin: {
                                                      vertical: "bottom",
                                                      horizontal: "center",
                                                    },
                                                    PaperProps: {
                                                      sx: {
                                                        height: "150px",
                                                      },
                                                    },
                                                  }}
                                                  sx={{ borderRadius: "8px" }}
                                                >
                                                  <MenuItem
                                                    disabled
                                                    value="select"
                                                    className="select-item"
                                                  >
                                                    <em
                                                      style={{
                                                        fontStyle: "unset",
                                                        color: "darkgrey",
                                                      }}
                                                    >
                                                      Select Answer
                                                    </em>
                                                  </MenuItem>
                                                  {formik &&
                                                    formik?.values &&
                                                    formik?.values
                                                      ?.attachedlist &&
                                                    formik?.values?.attachedlist?.items.map(
                                                      (select, i) => {
                                                        return (
                                                          <MenuItem
                                                            key={i}
                                                            value={select}
                                                            className="select-item"
                                                          >
                                                            {select?.title}
                                                          </MenuItem>
                                                        );
                                                      }
                                                    )}
                                                </Select>
                                              </FormControl>
                                            ) : formik &&
                                              formik?.values &&
                                              formik?.values?.questiontype ===
                                                "multiplechoice" &&
                                              formik?.values &&
                                              formik?.values?.attachedlist ? (
                                              <FormControl className="Rules-preset-form">
                                                <Select
                                                  size="small"
                                                  defaultValue="select"
                                                  labelId="demo-simple-select-label"
                                                  id="demo-simple-select"
                                                  name="answer"
                                                  value={
                                                    items && items.answer
                                                      ? items.answer
                                                      : "select"
                                                  }
                                                  onChange={(e) =>
                                                    handleChangeRules(
                                                      e,
                                                      items,
                                                      i
                                                    )
                                                  }
                                                  MenuProps={{
                                                    disableScrollLock: true,
                                                    anchorOrigin: {
                                                      vertical: "bottom",
                                                      horizontal: "center",
                                                    },
                                                    PaperProps: {
                                                      sx: {
                                                        height: "150px",
                                                      },
                                                    },
                                                  }}
                                                  sx={{ borderRadius: "8px" }}
                                                >
                                                  <MenuItem
                                                    disabled
                                                    value="select"
                                                    className="select-item"
                                                  >
                                                    <em
                                                      style={{
                                                        fontStyle: "unset",
                                                        color: "darkgrey",
                                                      }}
                                                    >
                                                      Select Answer
                                                    </em>
                                                  </MenuItem>
                                                  {formik &&
                                                    formik?.values &&
                                                    formik?.values
                                                      ?.attachedlist &&
                                                    formik?.values?.attachedlist?.items.map(
                                                      (select, i) => {
                                                        return (
                                                          <MenuItem
                                                            key={i}
                                                            value={select}
                                                            className="select-item"
                                                          >
                                                            {select?.title}
                                                          </MenuItem>
                                                        );
                                                      }
                                                    )}
                                                </Select>
                                              </FormControl>
                                            ) : formik &&
                                              formik?.values &&
                                              formik?.values?.questiontype ===
                                                "numeric" ? (
                                              <FormControl
                                                hiddenLabel
                                                sx={{ mb: 2 }}
                                              >
                                                <TextField
                                                  name="answer"
                                                  autoComplete="off"
                                                  sx={{
                                                    width: { sm: 80, md: 150 },
                                                    "& .MuiInputBase-root": {
                                                      height: 35,
                                                    },
                                                  }}
                                                  variant="outlined"
                                                  disableUnderline={true}
                                                  InputProps={{
                                                    inputProps: { min: 1 },
                                                  }}
                                                  type="number"
                                                  value={
                                                    items && items.answer
                                                      ? items.answer
                                                      : ""
                                                  }
                                                  onChange={(e) => {
                                                    handleChangeRules(
                                                      e,
                                                      items,
                                                      i
                                                    );
                                                  }}
                                                  error={
                                                    formik.touched
                                                      .questionscore &&
                                                    Boolean(
                                                      formik.errors
                                                        .questionscore
                                                    )
                                                  }
                                                  helperText={
                                                    formik.touched
                                                      .questionscore &&
                                                    formik.errors.questionscore
                                                  }
                                                  id="filled-number"
                                                  size="md"
                                                  className="date-input"
                                                />
                                              </FormControl>
                                            ) : (
                                              <FormControl
                                                className="Rules-preset-form"
                                                sx={{
                                                  width: { sm: 80, md: 150 },
                                                  "& .MuiInputBase-root": {
                                                    height: 35,
                                                  },
                                                }}
                                              >
                                                <Select
                                                  size="small"
                                                  labelId="demo-simple-select-label"
                                                  id="demo-simple-select"
                                                  name="answer"
                                                  value={
                                                    items && items.answer
                                                      ? items.answer.title
                                                      : "Select Answer"
                                                  }
                                                  renderValue={(selectvalue) =>
                                                    selectvalue
                                                  }
                                                  onChange={(e) => {
                                                    handleChangeRules(
                                                      e,
                                                      items,
                                                      i
                                                    );
                                                  }}
                                                  MenuProps={{
                                                    disableScrollLock: true,
                                                    anchorOrigin: {
                                                      vertical: "bottom",
                                                      horizontal: "center",
                                                    },
                                                    PaperProps: {
                                                      sx: {
                                                        height: "150px",
                                                      },
                                                    },
                                                  }}
                                                  sx={{ borderRadius: "8px" }}
                                                >
                                                  <MenuItem
                                                    disabled
                                                    value="select"
                                                  >
                                                    <em
                                                      style={{
                                                        fontStyle: "unset",
                                                        color: "darkgrey",
                                                      }}
                                                    >
                                                      Select Answer
                                                    </em>
                                                  </MenuItem>
                                                  {formik &&
                                                    formik?.values &&
                                                    formik?.values
                                                      ?.questiontype !==
                                                      "singlechoice" &&
                                                    optionsAnswer &&
                                                    optionsAnswer.map(
                                                      (select, i) => {
                                                        return (
                                                          <MenuItem
                                                            key={i}
                                                            value={select}
                                                            className="select-item"
                                                          >
                                                            {select?.title}
                                                          </MenuItem>
                                                        );
                                                      }
                                                    )}
                                                </Select>
                                              </FormControl>
                                            )
                                          ) : (
                                            ""
                                          )}
                                          <Typography>
                                            {items?.template?.split(/[{,}]/)[4]}
                                          </Typography>
                                          {items?.template?.split(
                                            /[{,}]/
                                          )[5] === "issueTemplate" ? (
                                            <Stack
                                              direction="row"
                                              spacing={1}
                                              className="list-btn"
                                              sx={{
                                                alignItems: "center",
                                                margin: "5px 0 0 0",
                                              }}
                                            >
                                              <Button
                                                onClick={handleClickOpen}
                                                className="select-issues cancle-hover"
                                              >
                                                Select Issue Template
                                              </Button>
                                            </Stack>
                                          ) : (
                                            ""
                                          )}
                                          {items?.template?.split(/[{,}]/)[6]}
                                        </Stack>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={1}
                                        className="plus-icon-grid"
                                      >
                                        <Box
                                          sx={{ float: "right" }}
                                          className="plus-icon"
                                        >
                                          <AddOutlinedIcon
                                            onClick={(e) =>
                                              handleFirst(items, i, formValues)
                                            }
                                            sx={{ mr: 1 }}
                                            className="add-icon"
                                          />
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  </Card>
                                );
                              })}
                          </Grid>
                        </Grid>
                        <Card sx={{ p: 3, mb: 1 }} className="card">
                          <h3>Your Rules</h3>
                          {firstData &&
                            firstData.map((items, i) => (
                              <Grid
                                container
                                spacing={1}
                                sx={{
                                  textAlign: "left",
                                  margin: "5px",
                                  padding: "5px",
                                }}
                              >
                                <Grid item xs={12} sm={12} md={12} lg={11}>
                                  <Box>
                                    <Box sx={{ display: "flex" }}>
                                      <Typography>
                                        {items?.template?.split(/[{,}]/)[0]}
                                      </Typography>
                                      <Typography>
                                        &nbsp; "
                                        <b>
                                          {items?.template?.split(
                                            /[{,}]/
                                          )[1] === "condition"
                                            ? items.data?.condition?.title
                                            : ""}
                                        </b>
                                        " &nbsp;{" "}
                                      </Typography>
                                      <Typography>
                                        {items?.template?.split(/[{,}]/)[2]}
                                      </Typography>
                                      <Typography>
                                        {
                                          <>
                                            {" "}
                                            &nbsp; "{" "}
                                            <b>
                                              {items &&
                                              items?.template?.split(
                                                /[{,}]/
                                              )[3] === "answer" ? (
                                                <>
                                                  {items &&
                                                  items.data &&
                                                  Array.isArray(
                                                    items?.data?.answer
                                                  )
                                                    ? items.data?.answer?.map(
                                                        (data) => (
                                                          <>{data.title}</>
                                                        )
                                                      )
                                                    : items.data?.answer?.title
                                                    ? items.data?.answer?.title
                                                    : items.data?.answer}

                                                  {/* {
                                                    <>
                                                      {" "}
                                                      <b>
                                                        {items &&
                                                        items?.template?.split(
                                                          /[{,}]/
                                                        )[3] === "answer"
                                                          ? items &&
                                                            items.data &&
                                                            items.data
                                                              ?.answer &&
                                                            items.data?.answer
                                                              ?.title
                                                            ? items.data?.answer
                                                                ?.title
                                                            : items.data?.answer?.map(
                                                              (data) => (
                                                                <>{data.title}</>
                                                              )
                                                            )
                                                          : ""}
                                                      </b>
                                                    </>
                                                  } */}
                                                </>
                                              ) : (
                                                ""
                                              )}
                                            </b>
                                            " &nbsp;
                                          </>
                                        }
                                      </Typography>
                                      <Typography>
                                        {items?.template?.split(/[{,}]/)[4]}
                                      </Typography>
                                      <Divider />

                                      <Divider />
                                    </Box>
                                    {items?.template?.split(/[{,}]/)[5] ===
                                    "issueTemplate" ? (
                                      <Box>
                                        <h4>
                                          <b>
                                            Observation :{" "}
                                            {
                                              items?.data?.issueTemplate
                                                ?.observation
                                            }
                                          </b>
                                        </h4>
                                        <p>
                                          {items?.data?.issueTemplate?.category}{" "}
                                          {">"}{" "}
                                          {
                                            items?.data?.issueTemplate
                                              ?.subCategory
                                          }
                                        </p>
                                        <p>
                                          IssuePriority :{" "}
                                          <b>
                                            {
                                              items?.data?.issueTemplate
                                                ?.severity
                                            }
                                          </b>
                                        </p>
                                      </Box>
                                    ) : (
                                      ""
                                    )}
                                    <Typography>
                                      {" "}
                                      {items?.template?.split(/[{,}]/)[6]}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={1}
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <IconButton onClick={() => handleDelete(i)}>
                                    <DeleteOutlineIcon
                                      sx={{ color: "#e74a3b" }}
                                    />
                                  </IconButton>
                                </Grid>
                              </Grid>
                            ))}
                        </Card>
                      </>
                    )}
                  </TabPanel>
                  <TabPanel
                    value={value}
                    index={
                      AssessmentQuestion === "newaddNewAssesment" ||
                      AssessmentQuestion === "UpdateAssessment"
                        ? 1
                        : 2
                    }
                  >
                    {formik &&
                    formik?.values &&
                    formik?.values?.questiontype === "text" &&
                    AssessmentQuestion === undefined ? (
                      ""
                    ) : formik &&
                      formik?.values &&
                      formik?.values?.questiontype === "media" ? (
                      ""
                    ) : formik &&
                      formik?.values &&
                      formik?.values?.questiontype === "numeric" ? (
                      ""
                    ) : formik &&
                      formik?.values &&
                      formik?.values?.questiontype === "multiplechoice" ? (
                      ""
                    ) : formik &&
                      formik?.values &&
                      formik?.values?.questiontype === "date" ? (
                      ""
                    ) : formik &&
                      formik?.values &&
                      formik?.values?.questiontype === "Time" ? (
                      ""
                    ) : formik &&
                      formik?.values &&
                      formik?.values?.questiontype === "dateTime" ? (
                      ""
                    ) : formik &&
                      formik?.values &&
                      formik?.values?.questiontype === "Timer" ? (
                      ""
                    ) : AssessmentQuestion === "AssessmentQuestion" ? (
                      ""
                    ) : (
                      <>
                        {formik &&
                        formik?.values &&
                        formik?.values?.questiontype === "singlechoice" &&
                        formik?.values &&
                        formik?.values?.attachedlist ? (
                          <>
                            {formik &&
                              formik?.values &&
                              formik?.values?.attachedlist &&
                              formik?.values?.attachedlist?.items.map(
                                (data, i) => {
                                  return (
                                    <Grid
                                      container
                                      spacing={1}
                                      className="marginbottom"
                                      key={i}
                                    >
                                      <Grid item md={6} xs={12} sm={12} lg={2}>
                                        <Typography>{data.title}</Typography>
                                      </Grid>
                                      <Grid item md={6} xs={12} sm={12} lg={6}>
                                        <TextField
                                          id="outlined-basic"
                                          type="number"
                                          variant="outlined"
                                          size="small"
                                          fullWidth
                                          ref={ref}
                                          name={data.title}
                                          datatype={data.title}
                                          value={
                                            objects?.objects?.[data?.id]?.value
                                          }
                                          defaultValue={1}
                                          onChange={(e) => {
                                            handleSingleAndMultiChoice(e, i);
                                            handleChangeobj(e, i, data?.id);
                                          }}
                                          className="date-input"
                                        />
                                      </Grid>
                                    </Grid>
                                  );
                                }
                              )}{" "}
                          </>
                        ) : formik &&
                          formik?.values &&
                          formik?.values?.questiontype === "multiplechoice" &&
                          formik?.values &&
                          formik?.values?.attachedlist ? (
                          <>
                            {formik &&
                              formik?.values &&
                              formik?.values?.attachedlist &&
                              formik?.values?.attachedlist?.items.map(
                                (data, i) => {
                                  return (
                                    <Grid
                                      container
                                      spacing={1}
                                      className="marginbottom"
                                      key={i}
                                    >
                                      <Grid item md={6} xs={12} sm={12} lg={2}>
                                        <Typography>{data.title}</Typography>
                                      </Grid>
                                      <Grid item md={6} xs={12} sm={12} lg={6}>
                                        <TextField
                                          id="outlined-basic"
                                          type="number"
                                          ref={ref}
                                          variant="outlined"
                                          size="small"
                                          fullWidth
                                          name={data.title}
                                          defaultValue={1}
                                          // value={singleandmultichoice.value}
                                          onChange={(e) => {
                                            handleSingleAndMultiChoice(e, i);
                                          }}
                                          className="date-input"
                                        />
                                      </Grid>
                                    </Grid>
                                  );
                                }
                              )}
                          </>
                        ) : (
                          <>
                            {" "}
                            <Grid
                              container
                              spacing={1}
                              className="marginbottom"
                            >
                              <Grid item md={6} xs={12} sm={12} lg={2}>
                                <Typography>Yes</Typography>
                              </Grid>
                              <Grid item md={6} xs={12} sm={12} lg={6}>
                                <TextField
                                  id="outlined-basic"
                                  type="number"
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                  name="option_score.yes"
                                  value={formik?.values?.option_score?.yes}
                                  onChange={formik.handleChange}
                                  className="date-input"
                                />
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              spacing={1}
                              className="marginbottom"
                            >
                              <Grid item md={6} xs={12} sm={12} lg={2}>
                                <Typography>No</Typography>
                              </Grid>
                              <Grid item md={6} xs={12} sm={12} lg={6}>
                                <TextField
                                  id="outlined-basic"
                                  type="number"
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                  name="option_score.no"
                                  value={formik?.values?.option_score?.no}
                                  onChange={formik.handleChange}
                                  className="date-input"
                                />
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              spacing={1}
                              className="marginbottom"
                            >
                              <Grid item md={6} xs={12} sm={12} lg={2}>
                                <Typography>Acceptable</Typography>
                              </Grid>
                              <Grid item md={6} xs={12} sm={12} lg={6}>
                                <TextField
                                  id="outlined-basic"
                                  type="number"
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                  name="option_score.acceptable"
                                  value={formik.values.option_score?.acceptable}
                                  onChange={formik.handleChange}
                                  className="date-input"
                                />
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              spacing={1}
                              className="marginbottom"
                            >
                              <Grid item md={6} xs={12} sm={12} lg={2}>
                                <Typography>Not Acceptable</Typography>
                              </Grid>
                              <Grid item md={6} xs={12} sm={12} lg={6}>
                                <TextField
                                  id="outlined-basic"
                                  type="number"
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                  name="option_score.notAcceptable"
                                  value={
                                    formik?.values?.option_score?.notAcceptable
                                  }
                                  onChange={formik.handleChange}
                                  className="date-input"
                                />
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              spacing={1}
                              className="marginbottom"
                            >
                              <Grid item md={6} xs={12} sm={12} lg={2}>
                                <Typography>Discarded</Typography>
                              </Grid>
                              <Grid item md={6} xs={12} sm={12} lg={6}>
                                <TextField
                                  id="outlined-basic"
                                  type="number"
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                  name="option_score.discarded"
                                  value={formik.values?.option_score?.discarded}
                                  onChange={formik.handleChange}
                                  className="date-input"
                                />
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              spacing={1}
                              className="marginbottom"
                            >
                              <Grid item md={6} xs={12} sm={12} lg={2}>
                                <Typography>Returned</Typography>
                              </Grid>
                              <Grid item md={6} xs={12} sm={12} lg={6}>
                                <TextField
                                  id="outlined-basic"
                                  type="number"
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                  name="option_score.returned"
                                  value={formik.values?.option_score?.returned}
                                  onChange={formik.handleChange}
                                  className="date-input"
                                />
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              spacing={1}
                              className="marginbottom"
                            >
                              <Grid item md={6} xs={12} sm={12} lg={2}>
                                <Typography>Not Applicable</Typography>
                              </Grid>
                              <Grid item md={6} xs={12} sm={12} lg={6}>
                                <TextField
                                  id="outlined-basic"
                                  type="number"
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                  name="option_score.notApplicable"
                                  value={
                                    formik?.values?.option_score?.notApplicable
                                  }
                                  onChange={formik.handleChange}
                                  className="date-input"
                                />
                              </Grid>
                            </Grid>
                          </>
                        )}
                      </>
                    )}
                  </TabPanel>
                  {/* For Time Date and Date Time started*/}
                  <TabPanel
                    value={value}
                    index={
                      AssessmentQuestion === "newaddNewAssesment" ||
                      AssessmentQuestion === "UpdateAssessment"
                        ? 2
                        : 1
                    }
                  >
                    {formik &&
                    formik?.values &&
                    formik?.values?.questiontype === "text" ? (
                      ""
                    ) : formik &&
                      formik?.values &&
                      formik?.values?.questiontype === "numeric" ? (
                      ""
                    ) : formik &&
                      formik?.values &&
                      formik?.values?.questiontype === "multiplechoice" ? (
                      ""
                    ) : formik &&
                      formik?.values &&
                      formik?.values?.questiontype === "media" ? (
                      ""
                    ) : formik &&
                      formik?.values &&
                      formik?.values?.questiontype === "boolean" ? (
                      ""
                    ) : formik &&
                      formik?.values &&
                      formik?.values?.questiontype === "Timer" ? (
                      ""
                    ) : AssessmentQuestion === "AssessmentQuestion" ||
                      AssessmentQuestion === "newaddNewAssesment" ? (
                      ""
                    ) : (
                      <>
                        {" "}
                        <Grid container spacing={1} sx={{ textAlign: "left" }}>
                          <Grid item xs={12}>
                            <Box sx={{ fontWeight: "bold" }}>Rules</Box>
                            <Box sx={{ fontWeight: "medium", mt: 1, mb: 2 }}>
                              Rules Preset
                            </Box>
                            {ruleList &&
                              ruleList.map((items, i) => {
                                if (i !== 1) return null;
                                return (
                                  <Card sx={{ p: 3, mb: 2 }} key={i}>
                                    <Grid
                                      container
                                      spacing={1}
                                      sx={{
                                        textAlign: "left",
                                        padding: "20px 0px 20px 0px",
                                      }}
                                    >
                                      <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={11}
                                      >
                                        <Stack
                                          direction="row"
                                          spacing={1}
                                          className="list-btn third-list"
                                        >
                                          <Typography>
                                            {items?.template?.split(/[{,}]/)[0]}
                                          </Typography>
                                          {items?.template?.split(
                                            /[{,}]/
                                          )[1] === "condition" ? (
                                            formik &&
                                            formik?.values &&
                                            formik?.values?.questiontype ===
                                              "numeric" ? (
                                              <FormControl
                                                sx={{
                                                  width: { sm: 80, md: 150 },
                                                  "& .MuiInputBase-root": {
                                                    height: 35,
                                                  },
                                                }}
                                                className="Rules-preset-form"
                                              >
                                                {formik &&
                                                formik?.values &&
                                                formik?.values?.questiontype ===
                                                  "numeric" ? (
                                                  <Select
                                                    labelId="demo-simple-select-label"
                                                    size="small"
                                                    id="demo-simple-select"
                                                    name="condition"
                                                    defaultValue="select"
                                                    value={
                                                      items && items.condition
                                                        ? items.condition.title
                                                        : "Select Condition"
                                                    }
                                                    renderValue={(
                                                      selectvalue
                                                    ) => selectvalue}
                                                    onChange={(e) => {
                                                      handleChangeRules(
                                                        e,
                                                        items,
                                                        i
                                                      );
                                                    }}
                                                    sx={{ borderRadius: "8px" }}
                                                  >
                                                    <MenuItem
                                                      disabled
                                                      value="select"
                                                    >
                                                      <em
                                                        style={{
                                                          fontStyle: "unset",
                                                          fontSize: "12px",
                                                          color: "darkgrey",
                                                        }}
                                                      >
                                                        Select Condition
                                                      </em>
                                                    </MenuItem>
                                                    {numericCondition &&
                                                      numericCondition.map(
                                                        (select, i) => {
                                                          return (
                                                            <MenuItem
                                                              key={i}
                                                              value={select}
                                                              className="select-item"
                                                            >
                                                              {select?.title}
                                                            </MenuItem>
                                                          );
                                                        }
                                                      )}
                                                  </Select>
                                                ) : (
                                                  <Select
                                                    labelId="demo-simple-select-label"
                                                    size="small"
                                                    id="demo-simple-select"
                                                    name="condition"
                                                    defaultValue="select"
                                                    value={
                                                      items && items.condition
                                                        ? items.condition
                                                        : "select"
                                                    }
                                                    onChange={(e) =>
                                                      handleChangeRules(
                                                        e,
                                                        items,
                                                        i
                                                      )
                                                    }
                                                    sx={{ borderRadius: "8px" }}
                                                  >
                                                    <MenuItem
                                                      value="="
                                                      className="select-item"
                                                    >
                                                      =
                                                    </MenuItem>
                                                  </Select>
                                                )}
                                              </FormControl>
                                            ) : (
                                              <FormControl
                                                sx={{
                                                  width: { sm: 80, md: 150 },
                                                  "& .MuiInputBase-root": {
                                                    height: 35,
                                                  },
                                                }}
                                                className="Rules-preset-form"
                                              >
                                                <Select
                                                  labelId="demo-simple-select-label"
                                                  size="small"
                                                  id="demo-simple-select"
                                                  name="condition"
                                                  defaultValue="select"
                                                  value={
                                                    items && items.condition
                                                      ? items.condition
                                                      : "select"
                                                  }
                                                  onChange={(e) => {
                                                    handleChangeRules(
                                                      e,
                                                      items,
                                                      i
                                                    );
                                                  }}
                                                  sx={{ borderRadius: "8px" }}
                                                >
                                                  <MenuItem
                                                    disabled
                                                    value="select"
                                                  >
                                                    <em
                                                      style={{
                                                        fontStyle: "unset",
                                                        fontSize: "12px",
                                                        color: "darkgrey",
                                                      }}
                                                    >
                                                      Select Condition
                                                    </em>
                                                  </MenuItem>
                                                  <MenuItem
                                                    value="="
                                                    className="select-item"
                                                  >
                                                    =
                                                  </MenuItem>
                                                  <MenuItem
                                                    value=">"
                                                    className="select-item"
                                                  >
                                                    &gt;
                                                  </MenuItem>
                                                  <MenuItem
                                                    value="<"
                                                    className="select-item"
                                                  >
                                                    &lt;
                                                  </MenuItem>
                                                </Select>
                                              </FormControl>
                                            )
                                          ) : (
                                            ""
                                          )}
                                          <Typography>
                                            {items?.template?.split(/[{,}]/)[2]}
                                          </Typography>
                                          {items?.template?.split(
                                            /[{,}]/
                                          )[3] === "answer" ? (
                                            formik &&
                                            formik?.values &&
                                            formik?.values?.questiontype ===
                                              "singlechoice" &&
                                            formik?.values &&
                                            formik?.values?.attachedlist ? (
                                              <FormControl className="Rules-preset-form">
                                                <Select
                                                  size="small"
                                                  defaultValue="select"
                                                  labelId="demo-simple-select-label"
                                                  id="demo-simple-select"
                                                  name="answer"
                                                  value={
                                                    items && items.answer
                                                      ? items?.answer
                                                      : "select"
                                                  }
                                                  onChange={(e) => {
                                                    handleChangeRules(
                                                      e,
                                                      items,
                                                      i
                                                    );
                                                  }}
                                                  MenuProps={{
                                                    disableScrollLock: true,
                                                    anchorOrigin: {
                                                      vertical: "bottom",
                                                      horizontal: "center",
                                                    },
                                                    PaperProps: {
                                                      sx: {
                                                        height: "150px",
                                                      },
                                                    },
                                                  }}
                                                  sx={{ borderRadius: "8px" }}
                                                >
                                                  <MenuItem
                                                    disabled
                                                    value="select"
                                                    className="select-item"
                                                  >
                                                    <em
                                                      style={{
                                                        fontStyle: "unset",
                                                        color: "darkgrey",
                                                      }}
                                                    >
                                                      Select Answer
                                                    </em>
                                                  </MenuItem>
                                                  {formik &&
                                                    formik?.values &&
                                                    formik?.values
                                                      ?.attachedlist &&
                                                    formik?.values?.attachedlist?.items.map(
                                                      (select, i) => {
                                                        return (
                                                          <MenuItem
                                                            key={i}
                                                            value={select}
                                                            className="select-item"
                                                          >
                                                            {select?.title}
                                                          </MenuItem>
                                                        );
                                                      }
                                                    )}
                                                </Select>
                                              </FormControl>
                                            ) : formik &&
                                              formik?.values &&
                                              formik?.values?.questiontype ===
                                                "multiplechoice" &&
                                              formik?.values &&
                                              formik?.values?.attachedlist ? (
                                              <FormControl className="Rules-preset-form">
                                                <Select
                                                  size="small"
                                                  defaultValue="select"
                                                  labelId="demo-simple-select-label"
                                                  id="demo-simple-select"
                                                  name="answer"
                                                  value={
                                                    items && items.answer
                                                      ? items.answer
                                                      : "select"
                                                  }
                                                  onChange={(e) =>
                                                    handleChangeRules(
                                                      e,
                                                      items,
                                                      i
                                                    )
                                                  }
                                                  MenuProps={{
                                                    disableScrollLock: true,
                                                    anchorOrigin: {
                                                      vertical: "bottom",
                                                      horizontal: "center",
                                                    },
                                                    PaperProps: {
                                                      sx: {
                                                        height: "150px",
                                                      },
                                                    },
                                                  }}
                                                  sx={{ borderRadius: "8px" }}
                                                >
                                                  <MenuItem
                                                    disabled
                                                    value="select"
                                                    className="select-item"
                                                  >
                                                    <em
                                                      style={{
                                                        fontStyle: "unset",
                                                        color: "darkgrey",
                                                      }}
                                                    >
                                                      Select Answer
                                                    </em>
                                                  </MenuItem>
                                                  {formik &&
                                                    formik?.values &&
                                                    formik?.values
                                                      ?.attachedlist &&
                                                    formik?.values?.attachedlist?.items.map(
                                                      (select, i) => {
                                                        return (
                                                          <MenuItem
                                                            key={i}
                                                            value={select}
                                                            className="select-item"
                                                          >
                                                            {select?.title}
                                                          </MenuItem>
                                                        );
                                                      }
                                                    )}
                                                </Select>
                                              </FormControl>
                                            ) : formik &&
                                              formik?.values &&
                                              formik?.values?.questiontype ===
                                                "numeric" ? (
                                              <FormControl
                                                hiddenLabel
                                                sx={{ mb: 2 }}
                                              >
                                                <TextField
                                                  name="answer"
                                                  autoComplete="off"
                                                  sx={{
                                                    width: { sm: 80, md: 150 },
                                                    "& .MuiInputBase-root": {
                                                      height: 35,
                                                    },
                                                  }}
                                                  variant="outlined"
                                                  disableUnderline={true}
                                                  InputProps={{
                                                    inputProps: { min: 1 },
                                                  }}
                                                  type="number"
                                                  value={
                                                    items && items.answer
                                                      ? items.answer
                                                      : ""
                                                  }
                                                  onChange={(e) => {
                                                    handleChangeRules(
                                                      e,
                                                      items,
                                                      i
                                                    );
                                                  }}
                                                  error={
                                                    formik.touched
                                                      .questionscore &&
                                                    Boolean(
                                                      formik.errors
                                                        .questionscore
                                                    )
                                                  }
                                                  helperText={
                                                    formik.touched
                                                      .questionscore &&
                                                    formik.errors.questionscore
                                                  }
                                                  id="filled-number"
                                                  size="md"
                                                  className="date-input"
                                                />
                                              </FormControl>
                                            ) : (
                                              <FormControl
                                                className="Rules-preset-form"
                                                sx={{
                                                  width: { sm: 80, md: 150 },
                                                  "& .MuiInputBase-root": {
                                                    height: 35,
                                                  },
                                                }}
                                              >
                                                <Select
                                                  size="small"
                                                  labelId="demo-simple-select-label"
                                                  id="demo-simple-select"
                                                  name="answer"
                                                  value={
                                                    items && items.answer
                                                      ? items.answer.title
                                                      : "Select Answer"
                                                  }
                                                  renderValue={(selectvalue) =>
                                                    selectvalue
                                                  }
                                                  onChange={(e) => {
                                                    handleChangeRules(
                                                      e,
                                                      items,
                                                      i
                                                    );
                                                  }}
                                                  MenuProps={{
                                                    disableScrollLock: true,
                                                    anchorOrigin: {
                                                      vertical: "bottom",
                                                      horizontal: "center",
                                                    },
                                                    PaperProps: {
                                                      sx: {
                                                        height: "150px",
                                                      },
                                                    },
                                                  }}
                                                  sx={{ borderRadius: "8px" }}
                                                >
                                                  <MenuItem
                                                    disabled
                                                    value="select"
                                                  >
                                                    <em
                                                      style={{
                                                        fontStyle: "unset",
                                                        color: "darkgrey",
                                                      }}
                                                    >
                                                      Select Answer
                                                    </em>
                                                  </MenuItem>
                                                  {formik &&
                                                    formik?.values &&
                                                    formik?.values
                                                      ?.questiontype !==
                                                      "singlechoice" &&
                                                    optionsAnswer &&
                                                    optionsAnswer.map(
                                                      (select, i) => {
                                                        return (
                                                          <MenuItem
                                                            key={i}
                                                            value={select}
                                                            className="select-item"
                                                          >
                                                            {select?.title}
                                                          </MenuItem>
                                                        );
                                                      }
                                                    )}
                                                </Select>
                                              </FormControl>
                                            )
                                          ) : (
                                            ""
                                          )}
                                          <Typography>
                                            {items?.template?.split(/[{,}]/)[4]}
                                          </Typography>
                                          {items?.template?.split(
                                            /[{,}]/
                                          )[5] === "issueTemplate" ? (
                                            <Stack
                                              direction="row"
                                              spacing={1}
                                              className="list-btn"
                                              sx={{
                                                alignItems: "center",
                                                margin: "5px 0 0 0",
                                              }}
                                            >
                                              <Button
                                                onClick={handleClickOpen}
                                                className="select-issues cancle-hover"
                                              >
                                                Select Issue Template
                                              </Button>
                                            </Stack>
                                          ) : (
                                            ""
                                          )}
                                          {items?.template?.split(/[{,}]/)[6]}
                                        </Stack>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={1}
                                        className="plus-icon-grid"
                                      >
                                        <Box
                                          sx={{ float: "right" }}
                                          className="plus-icon"
                                        >
                                          <AddOutlinedIcon
                                            onClick={(e) =>
                                              handleFirst(items, i, formValues)
                                            }
                                            sx={{ mr: 1 }}
                                            className="add-icon"
                                          />
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  </Card>
                                );
                              })}
                          </Grid>
                        </Grid>
                        <Card sx={{ p: 3, mb: 1 }} className="card">
                          <h3>Your Rules</h3>
                          {firstData &&
                            firstData.map((items, i) => (
                              <Grid
                                container
                                spacing={1}
                                sx={{
                                  textAlign: "left",
                                  margin: "5px",
                                  padding: "5px",
                                }}
                              >
                                <Grid item xs={12} sm={12} md={12} lg={11}>
                                  <Box>
                                    <Box sx={{ display: "flex" }}>
                                      <Typography>
                                        {items?.template?.split(/[{,}]/)[0]}
                                      </Typography>
                                      <Typography>
                                        &nbsp; "
                                        <b>
                                          {items?.template?.split(
                                            /[{,}]/
                                          )[1] === "condition"
                                            ? items.data?.condition?.title
                                            : ""}
                                        </b>
                                        " &nbsp;{" "}
                                      </Typography>
                                      <Typography>
                                        {items?.template?.split(/[{,}]/)[2]}
                                      </Typography>
                                      <Typography>
                                        {
                                          <>
                                            {" "}
                                            &nbsp; "{" "}
                                            <b>
                                              {items &&
                                              items?.template?.split(
                                                /[{,}]/
                                              )[3] === "answer" ? (
                                                <>
                                                  {items &&
                                                  items.data &&
                                                  Array.isArray(
                                                    items?.data?.answer
                                                  )
                                                    ? items.data?.answer?.map(
                                                        (data) => (
                                                          <>{data.title}</>
                                                        )
                                                      )
                                                    : items.data?.answer?.title
                                                    ? items.data?.answer?.title
                                                    : items.data?.answer}

                                                  {/* {
                                                    <>
                                                      {" "}
                                                      <b>
                                                        {items &&
                                                        items?.template?.split(
                                                          /[{,}]/
                                                        )[3] === "answer"
                                                          ? items &&
                                                            items.data &&
                                                            items.data
                                                              ?.answer &&
                                                            items.data?.answer
                                                              ?.title
                                                            ? items.data?.answer
                                                                ?.title
                                                            : items.data?.answer?.map(
                                                              (data) => (
                                                                <>{data.title}</>
                                                              )
                                                            )
                                                          : ""}
                                                      </b>
                                                    </>
                                                  } */}
                                                </>
                                              ) : (
                                                ""
                                              )}
                                            </b>
                                            " &nbsp;
                                          </>
                                        }
                                      </Typography>
                                      <Typography>
                                        {items?.template?.split(/[{,}]/)[4]}
                                      </Typography>
                                      <Divider />

                                      <Divider />
                                    </Box>
                                    {items?.template?.split(/[{,}]/)[5] ===
                                    "issueTemplate" ? (
                                      <Box>
                                        <h4>
                                          <b>
                                            Observation :{" "}
                                            {
                                              items?.data?.issueTemplate
                                                ?.observation
                                            }
                                          </b>
                                        </h4>
                                        <p>
                                          {items?.data?.issueTemplate?.category}{" "}
                                          {">"}{" "}
                                          {
                                            items?.data?.issueTemplate
                                              ?.subCategory
                                          }
                                        </p>
                                        <p>
                                          IssuePriority :{" "}
                                          <b>
                                            {
                                              items?.data?.issueTemplate
                                                ?.severity
                                            }
                                          </b>
                                        </p>
                                      </Box>
                                    ) : (
                                      ""
                                    )}
                                    <Typography>
                                      {" "}
                                      {items?.template?.split(/[{,}]/)[6]}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={1}
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <IconButton onClick={() => handleDelete(i)}>
                                    <DeleteOutlineIcon
                                      sx={{ color: "#e74a3b" }}
                                    />
                                  </IconButton>
                                </Grid>
                              </Grid>
                            ))}
                        </Card>
                      </>
                    )}
                  </TabPanel>
                  {/* For Time Date and Date Time ended  */}
                </Box>
              ) : (
                ""
              )}
            </Container>
          </Box>
        )}
      </form>
      <SelectRuleIssueModal
        open={open}
        handleClose={handleClose}
        listData={listData}
        formValues={formValues}
        setFormValues={setFormValues}
      />
    </>
  );
};

export default AllFormQuestion;
