// import { Box, Card, CardContent } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { BASE_URL } from "../../Store/Redux/apiConfig/BaseUrl";
// import Box from "@mui/joy/Box";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  Modal,
  Radio,
  Select,
  Stack,
  styled,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import moment from "moment";
import { DataGrid } from "@mui/x-data-grid";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import CircleIcon from "@mui/icons-material/Circle";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { green } from "@mui/material/colors";
import { blue } from "@mui/material/colors";
import { red } from "@mui/material/colors";
import { yellow } from "@mui/material/colors";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import UpdateIcon from "@mui/icons-material/Update";
import Badge from "@mui/material/Badge";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate, useParams } from "react-router-dom";
import ViewHover from "../../assets/icons/svg-icons/ViewHover.svg";
import View from "../../assets/icons/svg-icons/View.svg";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { BASE_URL } from "../redux/apiConfig/BaseUrl";
import { handleVerifyPayNowApi, PaymenHistoryApi } from "../redux/actions/PaymenHistory";
import axios from "axios";
import ProgressbarTransparent from "../GlobalProgress/ProgressbarTransparent";
import { PayNowListApi,  VerifyCreditApi } from "../redux/actions/PaymenHistory";
import { toast } from "react-toastify";
import {
  //CompanyInfoDetailListApi,
  PaymentListApi,
  //PaymentSuccessListApi,
} from "../redux/actions/PaymenHistory";
import CreditPopup from './CreditPopup';

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

const downloadAllstyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  height: 500,
  overflow: "scroll",
  bgcolor: "#fff",
  // border: "2px solid rgb(12 36 49)",
  borderRadius: "6px",
  boxShadow: 24,
  p: 4,
};

const MenuProps = {
  disableScrollLock: true,
};

function PaymentHistory(props) {
  const tableHeight = 517;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const paymentHistoryListArr = useSelector(
    (state) =>
      state.paymentHistory &&
      state.paymentHistory.PaymentHistoryList &&
      state.paymentHistory.PaymentHistoryList.data &&
      state.paymentHistory.PaymentHistoryList.data.data
  );

  const PaymentHistoryListLoading = useSelector(
    (state) =>
      state.paymentHistory &&
      state.paymentHistory.PaymentHistoryListLoading
  );

  const companyListStatus = useSelector(
    (state) =>
      state.MyProfileDetails &&
      state.MyProfileDetails.progileMeGetApiRes &&
      state.MyProfileDetails.progileMeGetApiRes.data &&
      state.MyProfileDetails.progileMeGetApiRes.data.data
  );

  const [hover, setHover] = useState({
    View: false,
  });
  const [totalUser, setTotalUser] = useState();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [checkedArchive, setCheckedArchive] = useState(false);
  const [indexHover, setIndexHover] = useState(0);
  const [value, setValue] = useState(0);
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [statusType, setStatusType] = useState(0);
  const [paymentType, setPaymentType] = useState(0);
  const [transactionId, setTransactionId] = useState("");
  const [uniqueInvoiceId, setUniqueInvoiceId] = useState("");
  const [paymentDate, setPaymentDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [invoiceReferenceId, setInvoiceReferenceId] = useState(null);

  let paymentHistoryList =
    paymentHistoryListArr && paymentHistoryListArr.finalData.length;

  useEffect(() => {
    listPaymentHistory();
  }, []);

  const payNowListArr = useSelector(
    (state) =>
      state.paymentHistory &&
      state.paymentHistory.PayNowList &&
      state.paymentHistory.PayNowList.data &&
      state.paymentHistory.PayNowList.data.razorPayOrder &&
      state.paymentHistory.PayNowList.data.razorPayOrder.data
  );
  console.log("payNowListArr", payNowListArr );
  const payNowMsg = useSelector(
    (state) =>
      state.paymentHistory &&
      state.paymentHistory.PayNowList &&
      state.paymentHistory.PayNowList.data &&
      state.paymentHistory.PayNowList.data.razorPayOrder &&
      state.paymentHistory.PayNowList.data.razorPayOrder.message
  );
 
  const [isInitialized, setIsInitialized] = useState(false);
  const [isPopupTriggered, setIsPopupTriggered] = useState(false);

  useEffect(() => {
    if (!isInitialized) {
      setIsInitialized(true); // Skip the first render
      return;
    }
      if (payNowListArr && payNowListArr.id && !isPopupTriggered) {
        console.log("payNowVerify.....");
        setIsPopupTriggered(true);
        handleVerifyPayNow(invoiceReferenceId);
      } else {
        if(payNowMsg){
          toast.success(payNowMsg);
          setTimeout(() => {
            navigate("/payment_history");
          }, 3000);
        }
      }
    }, [payNowListArr, payNowMsg, invoiceReferenceId]);

    const handleVerifyPayNow = async (invoiceId) => {
      const options = {
        key: "rzp_test_zv6ZfAcp5zacnk", // Your Razorpay API Key
        amount: parseInt( payNowListArr.amount ) * 100, //parseInt(extractToken.amount)  100, // Amount in paise (Example: for Rs. 500, amount should be 50000)
        currency: "INR",
        name: "KURMAR Research Private Limited",
        description: "Payment for services/products",
        order_id: payNowListArr.id, //paymentListArray.id,
        //invoice_id: rowData?.uniqueInvoiceId,
        handler: function (response) {
          console.log("response", response);
         //setPaymentIds(response);
         toast.success(`Payment successful`,{ //payment successful message
          autoClose: 5000,
          });
         const id = localStorage.getItem("companyId");
          const data = {
            url: BASE_URL +`razorPay/verifyCredit?company_id=${id}`,
            body: {...response,
              invoiceReferenceId: invoiceId,
             },
          };
          dispatch(handleVerifyPayNowApi(data));
        },
        prefill: {
          name:companyListStatus.name,
          email: companyListStatus.email,
          contact:companyListStatus.contact,
        },
        modal : { 
          ondismiss: function () {
          setIsPopupTriggered(false);
        },
      },
      };
      const rzp = new window.Razorpay(options);
      rzp.open();
    };

    const handlePay = async (rowData) => {
      setInvoiceReferenceId(rowData.uniqueInvoiceId);
      const bodyData = {
        amount: rowData?.amount,
        currency: "INR",
        receipt: "Payment for services/products",
        invoiceReferenceId: rowData?.uniqueInvoiceId,
      };
      const id = localStorage.getItem("companyId");
      const data = {
        url: BASE_URL +`razorPay/addCredit?company_id=${id}`,
        body: bodyData,
      };
      dispatch(PayNowListApi(data));
    };

      const [openPopup, setOpenPopup] = useState(false);

      const handleClickOpen = () => {
        setOpenPopup(true);  // Opens the popup
      };
      
      const handleClose = () => {
        setOpenPopup(false);  // Closes the popup
      };
              
  const handleApplyFilter = () => {
    const companyId = localStorage.getItem("companyId");
    const bodyObject = {
      status: statusType,
      transactionId: transactionId,
      uniqueInvoiceId: uniqueInvoiceId,
      paymentMethod: paymentType,
      paymentDate: paymentDate,
    };
    const data = {
      url: BASE_URL + `payment/getAll?company_id=${companyId}`,
      body: bodyObject,
    };
    dispatch(PaymenHistoryApi(data));
  };
  const listPaymentHistory = () => {
    const companyId = localStorage.getItem("companyId");
    const data = { url: BASE_URL + `payment/getAll?company_id=${companyId}` };
    dispatch(PaymenHistoryApi(data));
  };

  const handleClick = (row) => {
    navigate(`/user-list/user-activity`, { state: row });
  };

  const columns = [
    {
      field: "S.No.",
      headerName: "S.No.",
      renderHeader: (params) => <strong>{"S.No"}</strong>,
      minWidth: 70,
      maxWidth: 70,
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => params.rowIndex + 1 + (page - 1) * rowsPerPage,
      renderCell: (params) => {
        const index =
          paymentHistoryListArr &&
          paymentHistoryListArr.finalData?.indexOf(params.row) +
            1 +
            (page - 1) * rowsPerPage;
        return (
          <div
            style={{
              display: "flex",
              width: "133px",
            }}
          >
            <p
              className="text-short"
              style={{ marginLeft: "10px", fontWeight: "normal" }} // Update fontWeight to "normal"
            >
              {index}
            </p>
          </div>
        );
      },
    },
    // {
    //   field: "monthlyActiveDays",
    //   headerName: "Monthly Active Days",
    //   renderHeader: () => <strong>{"Monthly Active Days"}</strong>,
    //   flex: 2,
    //   align: "center",
    //   headerAlign: "center",
    //   renderCell: (params) => {
    //     return (
    //       <div
    //         style={{
    //           display: "flex",
    //         }}
    //       >
    //         <div
    //           style={{
    //             display: "flex",
    //             flexDirection: "column",
    //             justifyContent: "flex-start",
    //             alignItems: "flex-start",
    //           }}
    //         >
    //           <LightTooltip title={`${params?.row?.monthlyActiveDays} days`}>
    //             <p
    //               className="text-short"
    //               style={{
    //                 color: "black",
    //                 fontSize: "14px",
    //               }}
    //             >
    //               {params?.row?.monthlyActiveDays} days
    //             </p>
    //           </LightTooltip>
    //         </div>
    //       </div>
    //     );
    //   },
    // },
    {
      field: "status",
      headerName: "Status",
      renderHeader: () => <strong>{"Status"}</strong>,
      flex: 2,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div
            style={{
              display: "flex",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              <LightTooltip title={`${params?.row?.status}`}>
                <p
                  className="text-short"
                  style={{
                    color: "black",
                    fontSize: "14px",
                  }}
                >
                  {params?.row?.status}
                </p>
              </LightTooltip>
            </div>
          </div>
        );
      },
    },
    {
      field: "PayNow",
      headerName: "Pay Now",
      renderHeader: () => <strong>{"Pay Now"}</strong>,
      flex: 2,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div
            style={{
              display: "flex",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              {params?.row?.status == "pending" ? (
                <Button 
                style={{
                  padding: "5px 5px",
                  backgroundColor: "#196773",
                  color: "#ffffff",
                  border: "none",
                  borderRadius: "7px",
                  cursor: "pointer",
                  fontSize: "10px",
                }}
                onClick={() => handlePay(params.row)
                  // Add your payment handling logic here
                  //console.log("Pay Now clicked for", params.row);
                }>
                  PayNow
                </Button>
              ): "--"}
            </div>
          </div>
        );
      },
    },
    /*{
      field: "paymentMethod",
      headerName: "Payment Method",
      renderHeader: () => <strong>{"Payment Method"}</strong>,
      flex: 2,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div
            style={{
              display: "flex",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              <LightTooltip title={`${params?.row?.paymentMethod}`}>
                <p
                  className="text-short"
                  style={{
                    color: "black",
                    fontSize: "14px",
                  }}
                >
                  {params?.row?.paymentMethod}
                </p>
              </LightTooltip>
            </div>
          </div>
        );
      },
    },*/
    {
      field: "pdfLink",
      headerName: "Invoice",
      renderHeader: () => <strong>{"Invoice"}</strong>,
      flex: 2,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div
            style={{
              display: "flex",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              <LightTooltip title={`${params?.row?.paymentMethod}`}>
                <p
                  className="text-short"
                  style={{
                    color: "black",
                    fontSize: "14px",
                  }}
                >
                  <a href={params?.row?.pdfLink} target="_blank">
                    Link
                  </a>
                </p>
              </LightTooltip>
            </div>
          </div>
        );
      },
    },
    /*{
      field: "planId",
      headerName: "Plan",
      renderHeader: () => <strong>{"Plan"}</strong>,
      flex: 2,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        // console.log("params?.row", params?.row);
        return (
          <div
            style={{
              display: "flex",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              <LightTooltip title={`${params?.row?.planName}`}>
                <p
                  className="text-short"
                  style={{
                    color: "black",
                    fontSize: "14px",
                  }}
                >
                  {params?.row?.planName}
                </p>
              </LightTooltip>
            </div>
          </div>
        );
      },
    },
    {
      field: "transactionId",
      headerName: "Transaction Id",
      renderHeader: () => <strong>{"Transaction Id"}</strong>,
      flex: 2,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div
            style={{
              display: "flex",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              {params?.row?.manualPayment?.transactionId ? (
                <LightTooltip
                  title={`${params?.row?.manualPayment?.transactionId}`}
                >
                  <p
                    className="text-short"
                    style={{
                      color: "black",
                      fontSize: "14px",
                    }}
                  >
                    {params?.row?.manualPayment?.transactionId || "-"}
                  </p>
                </LightTooltip>
              ) : (
                <LightTooltip title={`${params?.row?.transactionId}`}>
                  <p
                    className="text-short"
                    style={{
                      color: "black",
                      fontSize: "14px",
                    }}
                  >
                    {params?.row?.transactionId || "-"}
                  </p>
                </LightTooltip>
              )}
            </div>
          </div>
        );
      },
    },*/
    {
      field: "uniqueInvoiceId",
      headerName: "Invoice Id",
      renderHeader: () => <strong>{"Invoice Id"}</strong>,
      flex: 2,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div
            style={{
              display: "flex",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              <LightTooltip title={`${params?.row?.uniqueInvoiceId}`}>
                <p
                  className="text-short"
                  style={{
                    color: "black",
                    fontSize: "14px",
                  }}
                >
                  {params?.row?.uniqueInvoiceId || "-"}
                </p>
              </LightTooltip>
            </div>
          </div>
        );
      },
    },
    {
      field: "amount",
      headerName: "Amount",
      renderHeader: () => <strong>{"Amount"}</strong>,
      flex: 2,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div
            style={{
              display: "flex",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              <LightTooltip title={`${params?.row?.amount}`}>
                <p
                  className="text-short"
                  style={{
                    color: "black",
                    fontSize: "14px",
                  }}
                >
                  {params?.row?.amount}
                </p>
              </LightTooltip>
            </div>
          </div>
        );
      },
    },
    {
      field: "paymentDate",
      headerName: "Payment Date",
      renderHeader: () => <strong>{"Payment Date"}</strong>,
      align: "center",
      minWidth: 200,
      headerAlign: "center",
      flex: 1,
      valueGetter: (params) => {
        let timeFormate = moment(params?.row?.paymentDate).format(
          "MMMM D, YYYY LTS"
        );
        // let dateFormate = moment(params?.row?.updatedAt).format("MMMM D, YYYY");
        let result = [];
        if (timeFormate) {
          result.push(timeFormate);
        } else {
          result = ["N/A"];
        }
        return result.join(", ");
      },
    },
    // {
    //   field: "Created",
    //   headerName: "Created",
    //   align: "center",
    //   renderHeader: () => <strong>{"Created"}</strong>,
    //   minWidth: 200,
    //   headerAlign: "center",
    //   flex: 1,
    //   valueGetter: (params) => {
    //     let timeFormate = moment(params?.row?.createdAt).format(
    //       "MMMM D, YYYY LTS"
    //     );
    //     // let dateFormate = moment(params?.row?.updatedAt).format("MMMM D, YYYY");
    //     let result = [];
    //     if (timeFormate) {
    //       result.push(timeFormate);
    //     } else {
    //       result = ["N/A"];
    //     }
    //     return result.join(", ");
    //   },
    // },
    {
      field: "Updated",
      headerName: "Updated",
      align: "center",
      renderHeader: () => <strong>{"Updated"}</strong>,
      minWidth: 190,
      headerAlign: "center",
      flex: 1,
      valueGetter: (params) => {
        // let timeFormate = moment(params?.row?.createdAt).format("LTS");
        let dateFormate = moment(params?.row?.updatedAt).format(
          "MMMM D, YYYY LTS"
        );
        let result = [];
        if (dateFormate) {
          result.push(dateFormate);
        } else {
          result = ["N/A"];
        }
        return result.join(", ");
      },
    },
    {
      field: "Action",
      headerName: "Action",
      renderHeader: () => <strong>{"Action"}</strong>,
      flex: 1,
      minWidth: 100,
      align: "center",
      headerAlign: "center",
      hide: checkedArchive === true ? true : false,
      renderCell: (params) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
            }}
          >
            <img
              src={
                hover.View && indexHover === params?.row?._id ? ViewHover : View
              }
              alt="VIEW"
              style={{
                height: "35px",
                cursor: "pointer",
                margin: "0px 3px 0px 3px",
              }}
              onClick={() => handleClickAction(params?.row)}
              onMouseOver={() => {
                setHover({ View: true });
                setIndexHover(params?.row?._id);
              }}
              onMouseLeave={() => {
                setHover({ View: false });
              }}
            />
          </div>
        );
      },
    },
    // {
    //   field: "Action",
    //   headerName: "Action",
    //   renderHeader: () => <strong>{"Action"}</strong>,
    //   flex: 1,
    //   minWidth: 100,
    //   align: "center",
    //   headerAlign: "center",
    //   hide: checkedArchive === true ? true : false,
    //   renderCell: (params) => {
    //     return (
    //       <div
    //         style={{
    //           display: "flex",
    //           justifyContent: "space-evenly",
    //         }}
    //       >
    //         <img
    //           src={
    //             hover.View && indexHover === params?.row?._id ? ViewHover : View
    //           }
    //           alt="VIEW"
    //           style={{
    //             height: "35px",
    //             cursor: "pointer",
    //             margin: "0px 3px 0px 3px",
    //           }}
    //           onClick={() => handleClick(params?.row)}
    //           onMouseOver={() => {
    //             setHover({ View: true });
    //             setIndexHover(params?.row?._id);
    //           }}
    //           onMouseLeave={() => {
    //             setHover({ View: false });
    //           }}
    //         />
    //       </div>
    //     );
    //   },
    // },
  ];

  const handleClickAction = (row) => {
    navigate(`/payment_history/payment-details`, { state: row });
  };

  const controlProps = (item) => ({
    checked: props.selectedValue === item,
    onChange: props.handleChange,
    value: item,
    name: "color-radio-button-demo",
    inputProps: { "aria-label": item },
  });

  const handleChangeTabs = (newValue) => {
    props.setPage(1);
    setValue(newValue);

    // if (newValue === 0) {
    //   props.setCheckedArchive(false);
    // } else {
    //   props.setCheckedArchive(true);
    // }
  };

  const generateInvoice = async () => {
    setLoading(true);

    const companyId = localStorage.getItem("companyId");
    const token = localStorage.getItem("token");

    const data = {
      url: BASE_URL + `create-payment-invoice?company_id=${companyId}`,
      body: {},
    };

    try {
      const response = await axios.post(data?.url, data?.body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
      listPaymentHistory();
      return response;
    } catch (error) {
      setLoading(false);
      return error;
    }
  };

  return (
    <Box
    //   sx={{
    //     display: "flex",
    //     justifyContent: "space-between",
    //     alignItems: "center",
    //     flexWrap: "wrap",
    //     gap: "20px",
    //   }}
    >
      {loading && <ProgressbarTransparent play />}

      <Grid
        container
        sx={{
          bgcolor: "#F6F8FC",
          width: "100%",
          borderRadius: "8px",
          p: 1,
          justifyContent: "center",
        }}
        alignItems="center"
        className="header-card"
      >
        {/* <Grid item xs={12} sm={8} md={8} lg={8} className="colomn-padding">
          <Tabs
            value={value}
            onChange={handleChangeTabs}
            variant="scrollable"
            scrollButtons={false}
            aria-label="scrollable prevent tabs example"
            className="tabs-content-parent"
          >
            <Tab
              style={{ marginLeft: "8px" }}
              label="Active"
              icon={<p className="active-tabs-content">{props?.totalitems}</p>}
              iconPosition="end"
              className={
                props.checkedArchive === false
                  ? "active-bg"
                  : "active-tabs tabs-content"
              }
              onClick={() => props.setCheckedArchive(false)}
            />
            <Tab
              label="Archived"
              icon={
                <p className="active-tabs-content">
                  {props?.totalUserArchived}
                </p>
              }
              iconPosition="end"
              className={
                props?.checkedArchive === true
                  ? "active-bg"
                  : "Archived-tabs tabs-content"
              }
              onClick={() => props.setCheckedArchive(true)}
            />
          </Tabs>
        </Grid> */}
        <Grid item xs={12} sm={12} md={12} lg={12} sx={{ float: "right" }}>
          <Stack direction="row" spacing={1} sx={{ float: "right" }}>
            <div>
          <Button
              className="header-add cancle-hover"
              style={{
                height: "40px",
                borderRadius: "8px",
                marginTop: "3px",
                padding: "12px 16px",
                float: "right",
              }}
              onClick={(e) => handleClickOpen()}
            >
              {/* <FilterAltOutlinedIcon sx={{ mr: 1 }} /> */}
              Add Credit
            </Button>
            <CreditPopup open={openPopup} onClose={handleClose} />
            </div>           
            <Button
              className="header-add cancle-hover"
              style={{
                height: "40px",
                borderRadius: "8px",
                marginTop: "3px",
                padding: "12px 16px",
                float: "right",
              }}
              onClick={(e) => generateInvoice()}
            >
              {/* <FilterAltOutlinedIcon sx={{ mr: 1 }} /> */}
              Generate Invoice
            </Button>
            <Button
              className="header-add cancle-hover"
              style={{
                height: "40px",
                borderRadius: "8px",
                marginTop: "3px",
                padding: "12px 16px",
                float: "right",
              }}
              onClick={(e) => setOpenFilterModal(true)}
            >
              <FilterAltOutlinedIcon sx={{ mr: 1 }} />
              Filter
            </Button>
          </Stack>
        </Grid>
      </Grid>
      <Box
        sx={{
          bgcolor: "#F6F8FC",
          p: 1,
          borderRadius: "8px",
          height: `${tableHeight + 62}px`,
          display: "flex",
          flexDirection: "column",
          marginTop: "8px",
          marginBottom: "2px",
          width: "100%",
        }}
      >
        <div
          style={{
            height: `${tableHeight}px`,
            overflowY: "auto",
          }}
        >
          {" "}
          <DataGrid
            disableColumnMenu
            rows={
              (!PaymentHistoryListLoading && paymentHistoryListArr && paymentHistoryListArr.finalData) || []
            }
            columns={columns}
            getRowId={(data) => data._id}
            getRowHeight={() => "auto"}
            hideFooter={true}
            loading={PaymentHistoryListLoading}
          />
        </div>
      </Box>
      <Modal
        open={openFilterModal}
        onClose={() => setOpenFilterModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableEscapeKeyDown={false}
      >
        <Box sx={{ ...downloadAllstyle, p: 0 }}>
          <Box
            sx={{
              position: "sticky",
              right: "15px",
              top: "15px",
              cursor: "pointer",
              textAlign: "right",
              padding: "0px 10px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Box onClick={() => setOpenFilterModal(false)}>
              <CloseIcon />
            </Box>
          </Box>
          <Box>
            <Box sx={{ pt: 2, px: 4 }}>
              <Stack direction="row" className="stack">
                <FormControl
                  fullWidth
                  sx={{ background: "#FFFFFF", borderRadius: "5px" }}
                >
                  <label>Status</label>
                  <Select
                    name="type"
                    value={statusType}
                    onChange={(e) => setStatusType(e.target.value)}
                    size="small"
                    MenuProps={MenuProps}
                    sx={{ borderRadius: "8px", marginTop: "10px" }}
                    placeholder="Select type"
                  >
                    <MenuItem value={0}>Select type</MenuItem>
                    <MenuItem value={"Pending"}>Pending</MenuItem>
                    <MenuItem value={"Successful"}>Successful</MenuItem>
                    <MenuItem value={"Failed"}>Failed</MenuItem>
                    <MenuItem value={"Created"}>Created</MenuItem>
                  </Select>
                </FormControl>
              </Stack>
              <FormControl fullWidth sx={{ mt: 2 }}>
                <label>Transaction Id</label>
                <TextField
                  size="small"
                  fullWidth
                  sx={{ marginTop: "10px" }}
                  id="outlined-start-adornment"
                  placeholder="Transaction Id"
                  onChange={(e) => {
                    setTransactionId(e.target.value);
                  }}
                  value={transactionId}
                />
              </FormControl>
              <FormControl fullWidth sx={{ mb: 2, mt: 2 }}>
                <label>Unique Invoice Id</label>
                <TextField
                  size="small"
                  fullWidth
                  sx={{ marginTop: "10px" }}
                  id="outlined-start-adornment"
                  placeholder="Unique Invoice Id"
                  onChange={(e) => {
                    setUniqueInvoiceId(e.target.value);
                  }}
                  value={uniqueInvoiceId}
                />
              </FormControl>
              <Stack direction="row" className="stack">
                <FormControl
                  fullWidth
                  sx={{ background: "#FFFFFF", borderRadius: "5px" }}
                >
                  <label>Payment Method</label>
                  <Select
                    name="type"
                    value={paymentType}
                    onChange={(e) => setPaymentType(e.target.value)}
                    size="small"
                    MenuProps={MenuProps}
                    sx={{ borderRadius: "8px", marginTop: "10px" }}
                    placeholder="Select type"
                  >
                    <MenuItem value={0}>Select type</MenuItem>
                    <MenuItem value={"Credit Card"}>Credit Card</MenuItem>
                    <MenuItem value={"RazorPay"}>Razor Pay</MenuItem>
                  </Select>
                </FormControl>
                <FormControl fullWidth>
                  <label style={{ marginTop: "10px" }}>Payment Date</label>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      inputFormat="DD/MM/YYYY"
                      size="small"
                      value={paymentDate}
                      onChange={(newValue) => {
                        let dateSort = moment(newValue?.$d)
                          .format("YYYY-MM-DD")
                          .split("/")
                          .join("");
                        setPaymentDate(dateSort);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                      className="date-pick date-pick-submision"
                      maxDate={new Date()}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Stack>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              position: "fixed",
              bottom: 0,
              width: "100%",
              gap: "30px",
              px: 3,
              pt: 1,
              background: "#fff",
            }}
          >
            <Button
              className="cancle-button cancle-hover"
              style={{
                height: "46px",
                padding: "12px 16px",
                width: "47%",
                background: "#fff",
              }}
              onClick={() => {
                setStatusType(0);
                props.handleChange({ target: { value: "" } });
                props.searchOnChange("");
                setFrequency(0);
              }}
            >
              Clear
            </Button>
            <Button
              className="header-add cancle-hover"
              style={{
                height: "46px",
                padding: "12px 16px",
                width: "47%",
              }}
              onClick={(e) => {
                handleApplyFilter();
                // setOpenFilterModal(false);
                // handleFilter();
              }}
            >
              Apply
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

export default PaymentHistory;
