import {
  Box,
  Button,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Navigate, useNavigate, useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ProgressbarTransparent from "../GlobalProgress/ProgressbarTransparent";
import {
  CompanyInfoDetailListApi,
  PaymentListApi,
  PaymentSuccessListApi,
} from "../redux/actions/PaymenHistory";
import { BASE_URL } from "../redux/apiConfig/BaseUrl";
import { ProfileMeGetApiAction } from "../redux/actions/MyProfileDetailsAction";
import { toast } from "react-toastify";

const CheckoutForm = () => {
  // let totalAddOnPrice = 0;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { paymentToken } = useParams();
  const [loading, setLoading] = useState(true);
  const [companyDetails, setCompanyDetails] = useState();
  const [paymentIds, setPaymentIds] = useState();
  const [basicAmount, setBasicAmount] = useState(0);
  const [addOnAmount, setAddOnAmount] = useState(0);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [gstAmount, setGstAmount] = useState(0);
  const [payableAmount, setPayableAmount] = useState(0);
  const [totalAddOnPrice, setTotalAddOnPrice] = useState(0);
  const queryParams = new URLSearchParams(location.search);
  const uniqueInvoiceId = queryParams.get('uniqueInvoiceId');

  console.log("uniqueInvoiceId,",uniqueInvoiceId);

  const extractToken = JSON.parse(atob(paymentToken));

  const paymentListArr = useSelector(
    (state) =>
      state.paymentHistory &&
      state.paymentHistory.PaymentList &&
      state.paymentHistory.PaymentList.data &&
      state.paymentHistory.PaymentList.data.razorPayOrder &&
      state.paymentHistory.PaymentList.data.razorPayOrder.data
  );
  const paymentMsg = useSelector(
    (state) =>
      state.paymentHistory &&
      state.paymentHistory.PaymentList &&
      state.paymentHistory.PaymentList.data &&
      state.paymentHistory.PaymentList.data.razorPayOrder &&
      state.paymentHistory.PaymentList.data.razorPayOrder.message
  );

  const paymentLoading = useSelector(
    (state) => state.paymentHistory && state.paymentHistory.paymentLoading
  );

  const PaymentDoneListArr = useSelector(
    (state) =>
      state.paymentHistory &&
      state.paymentHistory.PaymentDoneList &&
      state.paymentHistory.PaymentDoneList.data
  );

  const CompanyInfoDetailListArr = useSelector(
    (state) =>
      state.paymentHistory &&
      state.paymentHistory.CompanyInfoDetailList &&
      state.paymentHistory.CompanyInfoDetailList.data &&
      state.paymentHistory.CompanyInfoDetailList.data.data
  );
  const CompanyInfoDetailListErr = useSelector(
    (state) =>
      state.paymentHistory && state.paymentHistory.CompanyInfoDetailListError
  );

  const companyListStatus = useSelector(
    (state) =>
      state.MyProfileDetails &&
      state.MyProfileDetails.progileMeGetApiRes &&
      state.MyProfileDetails.progileMeGetApiRes.data &&
      state.MyProfileDetails.progileMeGetApiRes.data.data
  );

  useEffect(() => {
    if (CompanyInfoDetailListArr) {
      setCompanyDetails(CompanyInfoDetailListArr);
      setLoading(false);
    }
  }, [CompanyInfoDetailListArr]);

  useEffect(() => {
    if (CompanyInfoDetailListErr) {
      setLoading(false);
    }
  }, [CompanyInfoDetailListErr]);

  useEffect(() => {
    if (PaymentDoneListArr && PaymentDoneListArr.isVerified) {
      printDocument();
    }
  }, [PaymentDoneListArr]);

  useEffect(() => {
    getCompanyInfo();
    getProfile();
  }, []);

  useEffect(() => {
    if (paymentListArr && paymentListArr.id) {
      handleConfirmPayment();
    } else {
      if(paymentMsg){
        toast.success(paymentMsg);
        setTimeout(() => {
          navigate("/payment_history");
        }, 3000);
      }
    }
  }, [paymentListArr]);

  const printDocument = () => {
    let printableArea = document.getElementById("invoicePrint").innerHTML;
    let originalContent = document.body.innerHTML;
    document.body.innerHTML = printableArea;
    window.print();
    document.body.innerHTML = originalContent;
  };

  useEffect(() => {
    let tAddOn = 0;
    companyDetails?.getCompanyInfo?.companyAddOn?.map((addOn) => {
      addOn.subModules.map((subModule) => {
        subModule.features.map((feature) => {
          tAddOn += feature?.price;
        });
      });
    });

    setTotalAddOnPrice(tAddOn);

    const basic =
      (companyDetails?.userCount || 1) *
      (companyDetails?.planDetail.price || 0);
    const addOn = (companyDetails?.userCount || 1) * (tAddOn || 0);

    const discount =
      ((basic + addOn) * (companyDetails?.companyDetail?.discount || 0)) / 100;
    const total = basic + addOn - discount;

    const gst = (total * 9) / 100;
    const payable = total + gst + gst;

    setBasicAmount(basic.toFixed(2));
    setAddOnAmount(addOn.toFixed(2));
    setDiscountAmount(discount.toFixed(2));
    setTotalAmount(total.toFixed(2));
    setGstAmount(gst.toFixed(2));
    setPayableAmount(payable.toFixed(2));
  }, [companyDetails]);

  const getCompanyInfo = async () => {
    const data = {
      url:
        BASE_URL +
        `company-info-detail?company_id=${extractToken.company_id}&token=${paymentToken}`,
    };
    dispatch(CompanyInfoDetailListApi(data));
  };

  const getProfile = () => {
    const data = { url: BASE_URL + `profile/me` };
    dispatch(ProfileMeGetApiAction(data));
  };

  const handleConfirmPayment = () => {
    const options = {
      key: "rzp_test_zv6ZfAcp5zacnk", // Your Razorpay API Key
      amount: parseInt(extractToken.amount) * 100, // Amount in paise (Example: for Rs. 500, amount should be 50000)
      currency: "INR",
      name: "KURMAR Research Private Limited",
      description: "Payment for services/products",
      order_id: paymentListArr.id,
      handler: function (response) {
        console.log("response", response);
        setPaymentIds(response);
        const id = localStorage.getItem("companyId");
        const data = {
          url:
            BASE_URL +
            `razorPay/verifyCredit?company_id=${id}`,
          body: { ...response,
                  invoiceReferenceId:uniqueInvoiceId,
           },
        };
        dispatch(PaymentSuccessListApi(data));
      },
      prefill: {
        name: companyListStatus.name,
        email: companyListStatus.email,
        contact: companyListStatus.contact,
      },
    };
    const rzp = new window.Razorpay(options);
    rzp.open();
  };

  const handleMakePayment = async (event) => {
    const bodyData = {
      amount: extractToken.amount,
      currency: "INR",
      description: "Payment for services/products",
      invoiceReferenceId:uniqueInvoiceId,
    };
    const id = localStorage.getItem("companyId");
    const data = {
      url:
        BASE_URL +
        `razorPay/addCredit?company_id=${id}`,
      body: bodyData,
    };
    dispatch(PaymentListApi(data));
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
      }}
    >
      {(loading || paymentLoading) && <ProgressbarTransparent play />}
      {/* {PaymentLoading && <ProgressbarTransparent play />} */}
      {!CompanyInfoDetailListErr ? (
        <div id="invoicePrint">
          <Box
            sx={{
              border: "1px solid #cbd4e1",
              borderRadius: "8px",
              background: "#FFFFFF",
              p: 2,
              width: "100%",
              // minWidth: "200px",
              // maxWidth: "300px",
              // minHeight: "300px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              alignItems: "flex-start",
              gap: "15px",
              // position: "relative",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                width: "100%",
              }}
            >
              <Box sx={{ fontSize: "2.25rem", color: "#196773" }}>INVOICE</Box>
              <Divider
                orientation="horizontal"
                flexItem
                sx={{
                  backgroundColor: "#27364B",
                  width: "100%",
                  height: "1px",
                  borderRadius: "10px",
                  marginBottom: "10px",
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "50px",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    textAlign: "left",
                  }}
                >
                  <Box
                    sx={{
                      fontSize: "15px",
                      fontWeight: 600,
                      fontFamily: "Inter",
                    }}
                  >
                    KURMAR RESEARCH PRIVATE LIMITED
                  </Box>
                  <Box sx={{ fontSize: "14px", fontFamily: "Inter" }}>
                    26/A, 2ND FLOOR, KRISHNA REDDYLAYOUT,
                  </Box>
                  <Box sx={{ fontSize: "14px", fontFamily: "Inter" }}>
                    DOMLUR, BANGALORE, Karnataka
                  </Box>
                  <Box sx={{ fontSize: "14px", fontFamily: "Inter" }}>
                    GSTIN/UIN: 29AAGCK6235Q1ZS
                  </Box>
                </Box>
                <Box
                  sx={{
                    textAlign: "left",
                    minWidth: "250px",
                  }}
                >
                  <Box sx={{ fontSize: "15px", fontWeight: 600 }}>BILL TO</Box>
                  <Box sx={{ fontSize: "14px", fontWeight: 600 }}>
                    {companyDetails &&
                      companyDetails.getCompanyInfo.companyName}
                  </Box>
                  <Box sx={{ fontSize: "14px" }}>
                    {companyDetails &&
                      companyDetails.getCompanyInfo.address.line1}
                    ,
                    {companyDetails &&
                      companyDetails.getCompanyInfo.address.city}
                    ,{companyDetails && companyDetails.getCompanyInfo.state},
                    {companyDetails &&
                      companyDetails.getCompanyInfo.address.postal_code}
                  </Box>
                  <Box sx={{ fontSize: "1rem" }}>
                    GSTIN/UIN :{" "}
                    {companyDetails && companyDetails.getCompanyInfo.gst}
                  </Box>
                  <Box sx={{ fontSize: "14px", fontFamily: "Inter" }}>
                    Company Slag:{" "}
                    {companyDetails && companyDetails.getCompanyInfo.uniqueslag}
                  </Box>
                </Box>
              </Box>
              <Divider
                orientation="horizontal"
                flexItem
                sx={{
                  backgroundColor: "#27364B",
                  width: "100%",
                  height: "1px",
                  borderRadius: "10px",
                  marginTop: "3px",
                  marginBottom: "10px",
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "10px",
                  width: "100%",
                }}
              >
                <Box sx={{ fontSize: "1rem" }}>
                  Plan Title :{" "}
                  <strong>
                    {companyDetails && companyDetails.planDetail.title}
                  </strong>
                </Box>
                <Box sx={{ fontSize: "1rem" }}>
                  Total Active Store :{" "}
                  <strong>{companyDetails && companyDetails.storeCount}</strong>
                </Box>
                <Box sx={{ fontSize: "1rem" }}>
                  Total Active User :{" "}
                  <strong>{companyDetails && companyDetails.userCount}</strong>
                </Box>
                <Box sx={{ fontSize: "1rem" }}>
                  Payment Status :{" "}
                  {paymentIds && paymentIds.razorpay_payment_id ? (
                    <strong style={{ color: "green" }}>Success</strong>
                  ) : (
                    <strong>Created</strong>
                  )}
                </Box>
              </Box>
              <Divider
                orientation="horizontal"
                flexItem
                sx={{
                  backgroundColor: "#27364B",
                  width: "100%",
                  height: "1px",
                  borderRadius: "10px",
                  marginTop: "3px",
                  marginBottom: "10px",
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                width: "100%",
              }}
            >
              <Box sx={{ fontWeight: 600, fontSize: "1rem" }}>
                AddOn Features :
              </Box>
              {companyDetails &&
                companyDetails.getCompanyInfo.companyAddOn.map((addOn) => {
                  return addOn.subModules.map((subModule) => {
                    return subModule.features.map((feature) => {
                      return (
                        <Box
                          sx={{
                            fontSize: "1rem",
                            display: "flex",
                            justifyContent: "space-between",
                            marginBottom: "10px",
                            width: "100%",
                          }}
                        >
                          <Box sx={{ fontSize: "1rem" }}>
                            {"- " + feature.title}
                          </Box>
                          <Box sx={{ fontSize: "1rem" }}>
                            {paymentIds && paymentIds.razorpay_payment_id ? (
                              ""
                            ) : (
                              <strong>{"₹ " + feature.price}</strong>
                            )}
                          </Box>
                        </Box>
                      );
                    });
                  });
                })}
            </Box>

            <Divider
              orientation="horizontal"
              flexItem
              sx={{
                backgroundColor: "#27364B",
                width: "100%",
                height: "1px",
                borderRadius: "10px",
                marginTop: "3px",
                marginBottom: "10px",
              }}
            />

            <Table width={"100%"}>
              <TableHead>
                <TableRow>
                  <TableCell>Particulars</TableCell>
                  <TableCell align="right">HSN/SAC</TableCell>
                  <TableCell align="right">Total Users</TableCell>
                  <TableCell align="right">Rate Per</TableCell>
                  <TableCell align="right">Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {companyDetails?.planDetail?.title}
                  </TableCell>
                  <TableCell align="right">998313</TableCell>
                  <TableCell align="right">
                    {companyDetails?.userCount || 0}
                  </TableCell>
                  <TableCell align="right">
                    {companyDetails?.planDetail?.price || 0}
                  </TableCell>
                  <TableCell align="right">{basicAmount}</TableCell>
                </TableRow>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Additional Features
                  </TableCell>
                  <TableCell align="right">-</TableCell>
                  <TableCell align="right">
                    {companyDetails?.userCount || 0}
                  </TableCell>
                  <TableCell align="right">{totalAddOnPrice || 0}</TableCell>
                  <TableCell align="right">{addOnAmount}</TableCell>
                </TableRow>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Discount Amount
                  </TableCell>
                  <TableCell align="right">-</TableCell>
                  <TableCell align="right">-</TableCell>
                  <TableCell align="right">
                    <strong>
                      {(companyDetails?.companyDetail &&
                        companyDetails?.companyDetail?.discount) ||
                        0}
                      %
                    </strong>
                  </TableCell>
                  <TableCell align="right">{discountAmount}</TableCell>
                </TableRow>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Total Amount
                  </TableCell>
                  <TableCell align="right">-</TableCell>
                  <TableCell align="right">-</TableCell>
                  <TableCell align="right">-</TableCell>
                  <TableCell align="right">{totalAmount}</TableCell>
                </TableRow>
              </TableBody>
            </Table>

            <Divider
              orientation="horizontal"
              flexItem
              sx={{
                backgroundColor: "#27364B",
                width: "100%",
                height: "1px",
                borderRadius: "10px",
                marginTop: "3px",
                marginBottom: "10px",
              }}
            />

            <Table width={"100%"}>
              <TableHead>
                <TableRow>
                  <TableCell>Particulars</TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right">Rate Per</TableCell>
                  <TableCell align="right">Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row" colSpan={3}>
                    Output Cgst
                  </TableCell>
                  {/* <TableCell align="right">998313</TableCell> */}
                  {/* <TableCell align="right">
                    {companyDetails?.userCount || 0}
                  </TableCell> */}
                  <TableCell align="right">9%</TableCell>
                  <TableCell align="right">{gstAmount}</TableCell>
                </TableRow>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row" colSpan={3}>
                    Output Sgst
                  </TableCell>
                  {/* <TableCell align="right">998313</TableCell> */}
                  {/* <TableCell align="right">
                    {companyDetails?.userCount || 0}
                  </TableCell> */}
                  <TableCell align="right">9%</TableCell>
                  <TableCell align="right">{gstAmount}</TableCell>
                </TableRow>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row" colSpan={3}>
                    Payable Amount
                  </TableCell>
                  {/* <TableCell align="right">998313</TableCell> */}
                  {/* <TableCell align="right">
                    {companyDetails?.userCount || 0}
                  </TableCell> */}
                  <TableCell align="right"></TableCell>
                  <TableCell align="right">{payableAmount}</TableCell>
                </TableRow>
              </TableBody>
            </Table>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                bottom: "10px",
              }}
            >
              <Box sx={{ fontSize: "2.25rem", color: "#f26539" }}>
                Rs. {extractToken.amount}
              </Box>

              {paymentIds && paymentIds.razorpay_payment_id ? (
                <Box>Payment Id : {paymentIds.razorpay_payment_id}</Box>
              ) : (
                <Button
                  onClick={(e) => handleMakePayment(e)}
                  fullWidth
                  variant="contained"
                  sx={{
                    width: "200px",
                    background: "#196773",
                  }}
                >
                  Make Payment
                </Button>
              )}
            </Box>
          </Box>
        </div>
      ) : (
        <Box>{CompanyInfoDetailListErr} and make payment</Box>
      )}
    </Box>
  );
};

export default CheckoutForm;
