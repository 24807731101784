/** @format */

import React, { useState } from "react";
import {
  FormControl,
  Box,
  Select,
  MenuItem,
  TextField,
  Grid,
  Card,
  Stack,
  FormControlLabel,
  Typography,
  Tabs,
  Tab,
  RadioGroup,
  Radio,
  Divider,
  IconButton,
  Checkbox,
} from "@mui/material";
import { Container } from "@mui/system";
import { Button } from "material-ui-core";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Fab from "@material-ui/core/Fab";
import Blank from "../../../images/Blank.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect } from "react";
import questiontype from "./QuestionType";
import SelectRuleIssueModal from "./SelectRuleIssueModal";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import { BASE_URL } from "../../../redux/apiConfig/BaseUrl";
import {
  RulesAddedApi,
  UploadImageEditQuesApi,
  UploadImgEditQSuccess,
} from "../../../redux/actions/Checklist";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { CompanyGetApi } from "../../../redux/actions/CreateCompany";
import { allFormEditQustionUpdateData } from "../../../redux/actions/AllformStore";
import { assessmentsEditQustionUpdateData } from "../../../redux/actions/AllformStore";
import { DataArrayRounded } from "@mui/icons-material";
import { Delete as DeleteIcon } from "@mui/icons-material";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const AddQustionEdit = (props) => {
  const { EditAssessment, id } = props;
  const optionsAnswer = [
    { title: "Yes", value: "yes" },
    { title: "No", value: "no" },
    { title: "Acceptable", value: "accetable" },
    { title: "Not Acceptable", value: "notAcceptable" },
    { title: "Discarded", value: "discarded" },
    { title: "Returned", value: "returned" },
    { title: "Not Applicable", value: "notApplicable" },
  ];

  const numericCondition = [
    {
      id: 1,
      title: "=",
      key: "=",
    },
    {
      id: 2,
      title: ">",
      key: ">",
    },
    {
      id: 3,
      title: ">=",
      key: ">=",
    },
    {
      id: 4,
      title: "<",
      key: "<",
    },
    {
      id: 5,
      title: "<=",
      key: "<=",
    },
  ];
  const addQustionEditData = localStorage.getItem("addQustionEditData");
  const addQustionsEditData = JSON.parse(addQustionEditData);
  const assesstQustionEditData = localStorage.getItem("assesstQustionEditData");
  const assesstQustionsEditData = JSON.parse(assesstQustionEditData);
  const editassesquestion = localStorage.getItem("editquestionasses");
  const editAssesQuestions = JSON.parse(editassesquestion);
  const optionsAns = [
    {
      title: "Yes",
      value:
        addQustionsEditData &&
        addQustionsEditData?.type?.validations[0]?.answer[0]?.value,
    },
    {
      title: "No",
      value: addQustionsEditData?.type?.validations[0]?.answer[1]?.value,
    },
    {
      title: "Acceptable",
      value: addQustionsEditData?.type?.validations[0]?.answer[2]?.value,
    },
    {
      title: "Not Acceptable",
      value: addQustionsEditData?.type?.validations[0]?.answer[3]?.value,
    },
    {
      title: "Discarded",
      value: addQustionsEditData?.type?.validations[0]?.answer[4]?.value,
    },
    {
      title: "Returned",
      value: addQustionsEditData?.type?.validations[0]?.answer[5]?.value,
    },
    {
      title: "Not Applicable",
      value: addQustionsEditData?.type?.validations[0]?.answer[6]?.value,
    },
  ];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const pathName = location?.pathname;
  const [open, setOpen] = useState(false);
  const [images, setImages] = useState([]);
  const [value, setValue] = useState(0);
  const [val, setVal] = useState("");
  const [radioval, setRadioVal] = useState("");
  const [assessmentvalue, setAssessmentValue] = useState({});
  const [assessmentradio, setAssessmentRadio] = useState([]);
  const [firstData, setFirstData] = useState([]);
  const [formValues, setFormValues] = useState({});
  const [require, setRequire] = useState(optionsAns);
  const [RecordAudio, setRecordAudio] = useState("");
  const [takeImage, setTakeImage] = useState("");
  const [takeVideo, setTakeVideo] = useState("");
  const [takeVideoData, setTakeVideoData] = useState([
    { title: "Yes", value: addQustionsEditData?.type?.validations[3]?.answer[0]?.value },
    { title: "No", value: addQustionsEditData?.type?.validations[3]?.answer[1]?.value },
  ]);
  const [allowComment, setAllowComment] = useState("");
  const [imageOptional, setImageOptional] = useState("");
  const [requiredComment, setRequiredComment] = useState("");
  const [validatarr, setValidatarr] = useState([]);
  const [ruleList, setRuleList] = useState([]);
  const [assesstanswers, setAssesstAnswers] = useState(
    assesstQustionsEditData?.answer
  );
  const [assessmentVals, setAssessmentVals] = useState(
    assesstQustionsEditData?.answer?.title
  );
  const [uiRender, setUiRender] = useState(false);
  const [weightScore, setWeightScore] = useState({});
  const [singlemultichoice, setMultiSingleChoice] = useState();
  const [singleandmultichoice, setSingleAndMultiChoice] = useState({});
  const [dynamicweightagscore, setDynamicweightageScore] = useState({});
  const [isQuestionToInspections, setIsQuestionToInspections] = useState(false);
  const [newfield, setNewField] = useState({});
  const [allKey, setAllKey] = useState([]);
  const maxImagesAllowed = 2;
  const maxImageSize = 5 * 1024 * 1024;
  const [RecordAudioField, setRecordAudioField] = useState([
    {
      title: "Yes",
      value: addQustionsEditData?.type?.validations[1]?.answer[0]?.value,
    },
    {
      title: "No",
      value: addQustionsEditData?.type?.validations[1]?.answer[1]?.value,
    },
  ]);
  const [takeImageData, setTakeImageData] = useState([
    {
      title: "Yes",
      value: addQustionsEditData?.type?.validations[2]?.answer[0]?.value,
    },
    {
      title: "No",
      value: addQustionsEditData?.type?.validations[2]?.answer[1]?.value,
    },
  ]);
  const [allowCommentData, setAllowCommentData] = useState([
    {
      title: "Yes",
      value: addQustionsEditData?.type?.validations[3]?.answer[0]?.value,
    },
    {
      title: "No",
      value: addQustionsEditData?.type?.validations[3]?.answer[1]?.value,
    },
  ]);
  const [imageOptionalData, setImageOptionalData] = useState([
    {
      title: "Yes",
      value: addQustionsEditData?.type?.validations[4]?.answer[0]?.value,
    },
    {
      title: "No",
      value: addQustionsEditData?.type?.validations[4]?.answer[1]?.value,
    },
  ]);
  const [requiredCommentData, setRequiredCommentData] = useState([
    {
      title: "Yes",
      value: addQustionsEditData?.type?.validations[5]?.answer[0]?.value,
    },
    {
      title: "No",
      value: addQustionsEditData?.type?.validations[5]?.answer[1]?.value,
    },
  ]);
  const [multiChoice, setMultiChoice] = useState([]);

  const [videoOptional, setVideoOptional] = useState("");
  const [videoOptionalData, setVideoOptionalData] = useState([
    { title: "Yes", value: addQustionsEditData?.type?.validations[6]?.answer[0]?.value },
    { title: "No", value: addQustionsEditData?.type?.validations[6]?.answer[1]?.value },
  ]);

  const rulesdata = useSelector(
    (state) =>
      state.checklist &&
      state.checklist.ruleslist &&
      state.checklist.ruleslist.data &&
      state.checklist.ruleslist.data.data
  );
  const companyData = useSelector(
    (state) =>
      state.company &&
      state.company.companyData &&
      state.company.companyData.data &&
      state.company.companyData.data.data &&
      state.company.companyData.data.data.list
  );

  const getImageKey = useSelector(
    (state) =>
      state.checklist &&
      state.checklist.uploadImgEditQ &&
      state.checklist.uploadImgEditQ.data &&
      state.checklist.uploadImgEditQ.data.data
  );

  useEffect(() => {
    if (getImageKey && getImageKey.length > 0) {
      setAllKey([...allKey, ...getImageKey]);
    }
  }, [getImageKey]);

  useEffect(() => {
    setRuleList(rulesdata);
  }, [rulesdata]);

  useEffect(() => {
    const id = localStorage.getItem("companyId");
    const data = { url: BASE_URL + `rule/template?type=2&company_id=${id}` };
    dispatch(RulesAddedApi(data));
  }, []);

  useEffect(() => {
    const validations = [
      {
        id: 1,
        key: "required",
        title: "Is Required?",
        type: "radio",
        answer: [...require],
      },
      {
        id: 2,
        key: "audio",
        title: "Record Audio",
        type: "radio",
        answer: [...RecordAudioField],
      },
      {
        id: 3,
        key: "image",
        title: "Take Image",
        type: "radio",
        answer: [...takeImageData],
      },
      {
        id: 4,
        key: "video",
        title: "Take Video",
        type: "radio",
        answer: [...takeVideoData],
      },
      {
        id: 5,
        key: "comment",
        title: "Always allow Comment",
        type: "radio",
        answer: [...allowCommentData],
      },
      {
        id: 6,
        key: "image_optional",
        title: "Is image optional?",
        type: "radio",
        answer: [...imageOptionalData],
      },
      {
        id: 7,
        key: "video_optional",
        title: "Is video optional?",
        type: "radio",
        answer: [...videoOptionalData],
      },
      {
        id: 8,
        key: "comment_required",
        title: "Is comment required?",
        type: "radio",
        answer: [...requiredCommentData],
      },
    ];
    setValidatarr(validations);
  }, [
    require,
    RecordAudio,
    takeImage,
    takeVideo,
    allowComment,
    imageOptional,
    videoOptional,
    requiredComment,
  ]);

  useEffect(() => {
    localStorage.removeItem("editdata");
    localStorage.removeItem("questiodata");
  }, []);

  useEffect(() => {
    if (addQustionsEditData || assesstQustionsEditData) {
      setAllKey((addQustionsEditData && addQustionsEditData.titleImages) || []);
      setIsQuestionToInspections(EditAssessment ? assesstQustionsEditData?.isQuestionToInspection : addQustionsEditData.isQuestionToInspection)
      setNewField({
        titleImages:
          (assesstQustionsEditData &&
            assesstQustionsEditData.titleImages &&
            assesstQustionsEditData.titleImages.map((image) => image.url)) ||
          [],
        title: EditAssessment
          ? assesstQustionsEditData?.title
          : addQustionsEditData.title,
        isQuestionToInspection: EditAssessment ? assesstQustionsEditData?.isQuestionToInspection : addQustionsEditData.isQuestionToInspection,
        description: EditAssessment
          ? assesstQustionsEditData?.desc
          : addQustionsEditData.desc,
        questionscore: EditAssessment
          ? assesstQustionsEditData?.question_score
          : addQustionsEditData.question_score,
        questiontype: EditAssessment
          ? assesstQustionsEditData?.type?.title
          : addQustionsEditData?.type?.title,
        attachedlist: EditAssessment
          ? assesstQustionsEditData?.attached_list
          : addQustionsEditData.attached_list,
        type: EditAssessment
          ? assesstQustionsEditData?.type
          : addQustionsEditData.type,
        rules:
          addQustionsEditData &&
          addQustionsEditData.rules &&
          addQustionsEditData.rules,
        id: addQustionsEditData && addQustionsEditData.id,
        validations:
          addQustionsEditData && addQustionsEditData.type.validations,

        option_score: {
          acceptable:
            addQustionsEditData ?
            addQustionsEditData.type &&
            addQustionsEditData.type.option_score &&
            addQustionsEditData.type.option_score.acceptable : 
              assesstQustionsEditData ?
              assesstQustionsEditData.type &&
              assesstQustionsEditData.type.option_score &&
              assesstQustionsEditData.type.option_score.acceptable : 
                editAssesQuestions ?
                editAssesQuestions.type &&
                editAssesQuestions.type.option_score &&
                editAssesQuestions.type.option_score.acceptable : ""
            ,
          discarded:
            addQustionsEditData ?
            addQustionsEditData.type &&
            addQustionsEditData.type.option_score &&
            addQustionsEditData.type.option_score.discarded : 
              assesstQustionsEditData ?
              assesstQustionsEditData.type &&
              assesstQustionsEditData.type.option_score &&
              assesstQustionsEditData.type.option_score.discarded : 
                editAssesQuestions ?
                editAssesQuestions.type &&
                editAssesQuestions.type.option_score &&
                editAssesQuestions.type.option_score.discarded : "",
          no:
            addQustionsEditData ?
            addQustionsEditData.type &&
            addQustionsEditData.type.option_score &&
            addQustionsEditData.type.option_score.no : 
              assesstQustionsEditData ?
              assesstQustionsEditData.type &&
              assesstQustionsEditData.type.option_score &&
              assesstQustionsEditData.type.option_score.no : 
                editAssesQuestions ?
                editAssesQuestions.type &&
                editAssesQuestions.type.option_score &&
                editAssesQuestions.type.option_score.no : "",
          notAcceptable:
            addQustionsEditData ?
            addQustionsEditData.type &&
            addQustionsEditData.type.option_score &&
            addQustionsEditData.type.option_score.notAcceptable : 
              assesstQustionsEditData ?
              assesstQustionsEditData.type &&
              assesstQustionsEditData.type.option_score &&
              assesstQustionsEditData.type.option_score.notAcceptable : 
                editAssesQuestions ?
                editAssesQuestions.type &&
                editAssesQuestions.type.option_score &&
                editAssesQuestions.type.option_score.notAcceptable : "",
          notApplicable:
            addQustionsEditData ?
            addQustionsEditData.type &&
            addQustionsEditData.type.option_score &&
            addQustionsEditData.type.option_score.notApplicable : 
              assesstQustionsEditData ?
              assesstQustionsEditData.type &&
              assesstQustionsEditData.type.option_score &&
              assesstQustionsEditData.type.option_score.notApplicable : 
                editAssesQuestions ?
                editAssesQuestions.type &&
                editAssesQuestions.type.option_score &&
                editAssesQuestions.type.option_score.notApplicable : "",
          returned:
            addQustionsEditData ?
            addQustionsEditData.type &&
            addQustionsEditData.type.option_score &&
            addQustionsEditData.type.option_score.returned : 
              assesstQustionsEditData ?
              assesstQustionsEditData.type &&
              assesstQustionsEditData.type.option_score &&
              assesstQustionsEditData.type.option_score.returned : 
                editAssesQuestions ?
                editAssesQuestions.type &&
                editAssesQuestions.type.option_score &&
                editAssesQuestions.type.option_score.returned : "",
          yes:
            addQustionsEditData ?
            addQustionsEditData.type &&
            addQustionsEditData.type.option_score &&
            addQustionsEditData.type.option_score.yes : 
              assesstQustionsEditData ?
              assesstQustionsEditData.type &&
              assesstQustionsEditData.type.option_score &&
              assesstQustionsEditData.type.option_score.yes : 
                editAssesQuestions ?
                editAssesQuestions.type &&
                editAssesQuestions.type.option_score &&
                editAssesQuestions.type.option_score.yes : "",
        },
      });
      if (assesstQustionsEditData) {
        if (assesstQustionsEditData?.answer?.length > 0) {
          setMultiChoice(assesstQustionsEditData?.answer);
          setAssesstAnswers(assesstQustionsEditData?.answer);
        }
        setRadioVal(assesstQustionsEditData?.answer?.title);
        newfield.assesstNumber =
          EditAssessment && assesstQustionsEditData?.answer;
      }
      if (
        (!EditAssessment &&
          addQustionsEditData &&
          addQustionsEditData.type &&
          addQustionsEditData.type.key === "single_select") ||
        "multi_select"
      ) {
        // const singlechoicearr = [];
        // singlechoicearr.push(editData.type.option_score);
        setMultiSingleChoice(addQustionsEditData?.type?.option_score);
        setSingleAndMultiChoice(addQustionsEditData?.type?.option_score);
      }
      if (!EditAssessment) {
        let tmpRequired = addQustionsEditData?.type.validations.filter(
          (val) => val.key === "required"
        );
        let required =
          tmpRequired && tmpRequired.length > 0
            ? tmpRequired[0]?.answer?.filter((ans) => ans.value === true)
            : false;
        setVal(required && required.length > 0 ? required[0].title : "");

        let tmpRecordAudio = addQustionsEditData.type.validations.filter(
          (val) => val.key === "audio"
        );
        let recordAudio =
          tmpRecordAudio && tmpRecordAudio.length > 0
            ? tmpRecordAudio[0]?.answer?.filter((ans) => ans.value === true)
            : false;
        setRecordAudio(
          recordAudio && recordAudio.length > 0
            ? recordAudio[0].title.toLowerCase()
            : ""
        );

        let tmpRecordImage = addQustionsEditData.type.validations.filter(
          (val) => val.key === "image"
        );
        let recordImage =
          tmpRecordImage && tmpRecordImage.length > 0
            ? tmpRecordImage[0]?.answer?.filter((ans) => ans.value === true)
            : false;
        setTakeImage(
          recordImage && recordImage.length > 0
            ? recordImage[0].title.toLowerCase()
            : ""
        );

        let tmpRecordVideo = addQustionsEditData.type.validations.filter(
          (val) => val.key === "video"
        );
        let recordVideo =
          tmpRecordVideo && tmpRecordVideo.length > 0
            ? tmpRecordVideo[0]?.answer?.filter((ans) => ans.value === true)
            : false;
        setTakeVideo(
          recordVideo && recordVideo.length > 0
            ? recordVideo[0].title.toLowerCase()
            : ""
        );  

        let tmpRecordComment = addQustionsEditData.type.validations.filter(
          (val) => val.key === "comment"
        );
        let recordComment =
          tmpRecordComment && tmpRecordComment.length > 0
            ? tmpRecordComment[0]?.answer?.filter((ans) => ans.value === true)
            : false;
        setAllowComment(
          recordComment && recordComment.length > 0
            ? recordComment[0].title.toLowerCase()
            : ""
        );

        let tmpRecordOptional = addQustionsEditData.type.validations.filter(
          (val) => val.key === "image_optional"
        );
        let recordOptional =
          tmpRecordOptional && tmpRecordOptional.length > 0
            ? tmpRecordOptional[0]?.answer?.filter((ans) => ans.value === true)
            : false;
        setImageOptional(
          recordOptional && recordOptional.length > 0
            ? recordOptional[0].title.toLowerCase()
            : ""
        );

        let tmpVideoOptional = addQustionsEditData.type.validations.filter(
          (val) => val.key === "video_optional"
        );
        let videoOptional =
          tmpVideoOptional && tmpVideoOptional.length > 0
            ? tmpVideoOptional[0]?.answer?.filter((ans) => ans.value === true)
            : false;
        setVideoOptional(
          videoOptional && videoOptional.length > 0
            ? videoOptional[0].title.toLowerCase()
            : ""
        );

        let tmpRecordRequiredComment =
          addQustionsEditData.type.validations.filter(
            (val) => val.key === "comment_required"
          );
        let recordRequiredComment =
          tmpRecordRequiredComment && tmpRecordRequiredComment.length > 0
            ? tmpRecordRequiredComment[0]?.answer?.filter(
                (ans) => ans.value === true
              )
            : false;
        setRequiredComment(
          recordRequiredComment && recordRequiredComment.length > 0
            ? recordRequiredComment[0].title.toLowerCase()
            : ""
        );
      }
    }

    if (addQustionsEditData && addQustionsEditData.rules) {
      setFirstData(addQustionsEditData && addQustionsEditData.rules);
    } else if (assesstQustionsEditData && assesstQustionsEditData.rules) {
      setFirstData(assesstQustionsEditData && assesstQustionsEditData.rules);
    } else if (editAssesQuestions && editAssesQuestions.rules) {
      setFirstData(editAssesQuestions && editAssesQuestions.rules);
    }
  }, [addQustionEditData]);

  const handleDelete = (index) => {
    setFirstData(firstData.filter((item, i) => i !== index));
  };
  const listData = (values) => {
    setFormValues(values);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleRequire = (event) => {
    setRequire(event.target.value);
  };
  const handleRecordaudio = (event) => {
    setRecordAudio(event.target.value);
  };
  const handleTakeImage = (event) => {
    setTakeImage(event.target.value);
  };
  const handleAllowComment = (event) => {
    setAllowComment(event.target.value);
  };
  const handleImageOptional = (event) => {
    setImageOptional(event.target.value);
  };
  const handleRequiredComment = (event) => {
    setRequiredComment(event.target.value);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const navigatioToQuestion = (data) => {
    if (data === "UpdateAssessment") {
      setTimeout(() => {
        navigate(`/assessment/edit`, {
          state: { id: id, addNewAssesment: "UpdateAssessment" },
        });
      }, 1000);
    } else if (data == "newaddNewAssesment") {
      setTimeout(() => {
        navigate("/assessment/create-assessment", {
          state: { addNewAssesment: "newaddNewAssesment" },
        });
      }, 1000);
    } else {
      setTimeout(() => {
        navigate("/checklist/all-form/add-form");
      }, 1000);
    }
  };

  let multiimageUrl =
    images &&
    images?.map((item) => {
      return item?.url;
    });

  // const handleImageUpload = (e) => {
  //   const tempArr = [];

  //   [...e.target.files].forEach((file) => {
  //     tempArr.push({
  //       data: file,
  //       url: URL.createObjectURL(file),
  //     });
  //   });
  //   setImages(tempArr);
  // };

  const handleDeleteImage = (item) => {
    const newAllKey = [...allKey].filter((img) => img.key !== item);
    setAllKey(newAllKey);
  };

  const handleImageUpload = async (e) => {
    const files = e.target.files;

    for (const file of files) {
      var idxDot = file.name.lastIndexOf(".") + 1;
      var extFile = file.name.substr(idxDot, file.name.length).toLowerCase();
      
      if (extFile != "jpg" && extFile != "jpeg" && extFile != "png") {
        alert("Only jpg/jpeg and png files are allowed!");
        e.target.value = [];
        return;
      }

      if (file.size > maxImageSize) {
        toast.error("Only up to 5 MB images are allowed.");
        e.target.value = [];
        return;
      }
    }
    if (files.length + allKey.length > maxImagesAllowed) {
      toast.error(`Only ${maxImagesAllowed} images are allowed.`);
      e.target.value = [];
      return;
    }
    const formData = new FormData();
    for (const file of files) {
      formData.append("files", file);
    }

    try {
      const imageData = {
        body: formData,
      };
      imageData.url = BASE_URL + `uploads/any?ngsw-bypass=true`;
      await dispatch(UploadImageEditQuesApi(imageData));

      const newAllKey = [...allKey];
      for (let i = 0; i < files.length; i++) {
        if (newAllKey.length === maxImagesAllowed) {
          newAllKey.pop();
        }
      }
      setAllKey(newAllKey);
      e.target.value = [];
    } catch (error) {
      e.target.value = [];
      console.error("Error uploading images:", error);
    }
  };

  const handleChangeRules = (event, items, index) => {
    let allRules = ruleList;
    if (event.target.name === "condition") {
      allRules[index].condition = event.target.value;
      // items.condition = event.target.value
    } else {
      allRules[index].answer = event.target.value;
      // items.answer = event.target.value
    }
    setRuleList(allRules);
    setUiRender(!uiRender);
  };

  const handleWeightScore = (e) => {
    const value = e.target.value;
    setWeightScore({
      ...weightScore,
      [e.target.name]: value,
    });
  };

  const handleSingleAndMultiChoice = (e, key, val) => {
    var key = e.target.name;
    const value = e.target.value;
    var obj = {};
    let singleChoice = singleandmultichoice;
    singleChoice[key] = e.target.value;
    // setSingleAndMultiChoice({
    //   ...singleandmultichoice,
    //   [e.target.name]: value,
    // });
    setSingleAndMultiChoice(singleChoice);
    setUiRender(!uiRender);
    setDynamicweightageScore([e.target.name]);
  };
  const handleDone = () => {
    let validatorData = [];
    if (require) {
      validatorData.push({
        value: require,
        title: " Is Required? :",
      });
    }
    if (RecordAudio) {
      validatorData.push({
        value: RecordAudio,
        title: "Record Audio? :",
      });
    }
    if (takeImage) {
      validatorData.push({ value: takeImage, title: " Take Image? :" });
    }
    if (imageOptional) {
      validatorData.push({
        value: imageOptional,
        title: "imageOptional :",
      });
    }
    if (allowComment) {
      validatorData.push({
        value: allowComment,
        title: "allowComment :",
      });
    }
    if (requiredComment) {
      validatorData.push({
        value: requiredComment,
        title: "requiredComment :",
      });
    }
    // if (validatorData) {
    //   localStorage.setItem("validatorData", JSON.stringify(validatorData));
    // }
  };
  const handleRequires = (event) => {
    let events = event.target.value;
    setVal(events);
    for (let item of require) {
      if (events == "Yes") {
        require[0].value = true;
        require[1].value = false;
        require[2].value = false;
        require[3].value = false;
        require[4].value = false;
        require[5].value = false;
        require[6].value = false;
      } else if (events == "No") {
        require[0].value = false;
        require[1].value = true;
        require[2].value = false;
        require[3].value = false;
        require[4].value = false;
        require[5].value = false;
        require[6].value = false;
      } else if (events == "Acceptable") {
        require[0].value = false;
        require[1].value = false;
        require[2].value = true;
        require[3].value = false;
        require[4].value = false;
        require[5].value = false;
        require[6].value = false;
      } else if (events == "Not Acceptable") {
        require[0].value = false;
        require[1].value = false;
        require[2].value = false;
        require[3].value = true;
        require[4].value = false;
        require[5].value = false;
        require[6].value = false;
      } else if (events == "Discarded") {
        require[0].value = false;
        require[1].value = false;
        require[2].value = false;
        require[3].value = false;
        require[4].value = true;
        require[5].value = false;
        require[6].value = false;
      } else if (events == "Returned") {
        require[0].value = false;
        require[1].value = false;
        require[2].value = false;
        require[3].value = false;
        require[4].value = false;
        require[5].value = true;
        require[6].value = false;
      } else if (events == "Not applicable") {
        require[0].value = false;
        require[1].value = false;
        require[2].value = false;
        require[3].value = false;
        require[4].value = false;
        require[5].value = false;
        require[6].value = true;
      }
    }
    let options = require;
    setRequire(options);
    // setRequire(event.target.value);
  };
  const handlesTakeImage = (event) => {
    setTakeImage(event.target.value);
    let events = event.target.value;
    for (let item of takeImageData) {
      if (events == "yes") {
        takeImageData[0].value = true;
        takeImageData[1].value = false;
      } else if (events == "no") {
        takeImageData[0].value = false;
        takeImageData[1].value = true;
      }
    }
    let options = takeImageData;
    setTakeImageData(options);
  };
  
  const handleTakeVideo = (event) => {
    setTakeVideo(event.target.value);
    let events = event.target.value;
    for (let item of takeVideoData) {
      if (events == "yes") {
        takeVideoData[0].value = true;
        takeVideoData[1].value = false;
      } else if (events == "no") {
        takeVideoData[0].value = false;
        takeVideoData[1].value = true;
      }
    }
    let options = takeVideoData;
    setTakeVideoData(options);
  };

  const handlesRecordaudio = (event) => {
    setRecordAudio(event.target.value);
    let events = event.target.value;
    for (let item of RecordAudioField) {
      if (events == "yes") {
        RecordAudioField[0].value = true;
        RecordAudioField[1].value = false;
      } else if (events == "no") {
        RecordAudioField[0].value = false;
        RecordAudioField[1].value = true;
      }
    }
    let options = RecordAudioField;
    setRecordAudioField(options);
  };
  const handlesAllowComment = (event) => {
    setAllowComment(event.target.value);
    let events = event.target.value;
    for (let item of allowCommentData) {
      if (events == "yes") {
        allowCommentData[0].value = true;
        allowCommentData[1].value = false;
      } else if (events == "no") {
        allowCommentData[0].value = false;
        allowCommentData[1].value = true;
      }
    }
    let options = allowCommentData;
    setAllowCommentData(options);
  };

  const handleImageOptionals = (event) => {
    setImageOptional(event.target.value);
    let events = event.target.value;
    for (let item of imageOptionalData) {
      if (events == "yes") {
        imageOptionalData[0].value = true;
        imageOptionalData[1].value = false;
      } else if (events == "no") {
        imageOptionalData[0].value = false;
        imageOptionalData[1].value = true;
      }
    }
    let options = imageOptionalData;
    setImageOptionalData(options);
  };

  const handleVideoOptional = (event) => {
    setVideoOptional(event.target.value);
    let events = event.target.value;
    for (let item of videoOptionalData) {
      if (events == "yes") {
        videoOptionalData[0].value = true;
        videoOptionalData[1].value = false;
      } else if (events == "no") {
        videoOptionalData[0].value = false;
        videoOptionalData[1].value = true;
      }
    }
    let options = videoOptionalData;
    setVideoOptionalData(options);
  };

  const handleRequiredComments = (event) => {
    setRequiredComment(event.target.value);
    let events = event.target.value;
    for (let item of requiredCommentData) {
      if (events == "yes") {
        requiredCommentData[0].value = true;
        requiredCommentData[1].value = false;
      } else if (events == "no") {
        requiredCommentData[0].value = false;
        requiredCommentData[1].value = true;
      }
    }
    let options = requiredCommentData;
    setRequiredCommentData(options);
  };
  const validationSchema = Yup.object({
    title: Yup.string("Enter your title").required("Title is required"),
    description: Yup.string("Enter your Description").required(
      "Description is required"
    ),
    questionscore: Yup.string("Enter your Question Score")
      .required("Question Score is required")
      .min(1, "Question Score must be 1 characters at minimum")
      .max(3, "Question Score must be 3 characters at maximum"),
    questiontype: Yup.string("Select your type").required("Type is required"),
  });
  let noFieldsSelected = true;

  validatarr.forEach((obj) => {
    obj.answer.forEach((option) => {
      if (option.value) {
        noFieldsSelected = false;
      }
    });
  });
  const formik = useFormik({
    initialValues: newfield || {
      title: "",
      isQuestionToInspection: false,
      description: "",
      questionscore: "",
      // questiontype: "",
      attachedlist: "",
      assesstNumber: "",
      questiontype: "",
      option_score: {
        acceptable: "",
        discarded: "",
        no: "",
        notAcceptable: "",
        notApplicable: "",
        returned: "",
        yes: "",
      },
      rules: [],

      validations: [],
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      values.images = allKey?.map((index) => ({
        key: index?.key,
        name: index?.name,
        url: index?.location || index?.url,
      }));

      const types = {
        validations: validatarr,
        // option_score: singleandmultichoice || {
        //   acceptable: weightScore.acceptable ? weightScore.acceptable : 1,
        //   discarded: weightScore.discarded ? weightScore.discarded : 0,
        //   no: weightScore.no ? weightScore.no : 0,
        //   notAcceptable: weightScore.notacceptable
        //     ? weightScore.notacceptable
        //     : 0,
        //   notApplicable: weightScore.notapplicable
        //     ? weightScore.notapplicable
        //     : 0,
        //   returned: weightScore.returned ? weightScore.returned : 0,
        //   yes: weightScore.yes ? weightScore.yes : 1,
        // },
        option_score: singleandmultichoice || formik?.values?.option_score,
        key:
          values?.questiontype === "Single Choice"
            ? "single_select"
            : values?.questiontype === "Multiple Choice"
            ? "multi_select"
            : values?.questiontype?.toLowerCase() === "numeric"
            ? "number"
            : values?.questiontype?.toLowerCase() === "media"
            ? "file"
            : values?.questiontype.toLowerCase(),
        title: formik?.values?.questiontype,
        title_to_show: formik.values.questiontype,
      };
      types.title = values.questiontype;
      const data = {
        title: values.title,
        titleImages: values.images,
        desc: values.description,
        question_score: values.questionscore,
        type: types,
        attached_list: values.attachedlist,
        rules: firstData,
        // attached_list: null,
        order: 0,
      };
      if(pathName == "/questions/add-question-editform"){
        data.isQuestionToInspection = values.isQuestionToInspection
      }
      if (data && !EditAssessment) {
        if (!noFieldsSelected) {
          data.id = addQustionsEditData && addQustionsEditData.id;
          const idqustion = localStorage.getItem("qustioneditid");
          data.id = addQustionsEditData && addQustionsEditData.id;
          dispatch(allFormEditQustionUpdateData(data, idqustion));
          navigatioToQuestion("editquestions");
        } else {
          toast.error("Validation fields are required");
        }
      } else if (EditAssessment === "UpdateAssessment") {
        if (
          formik.values.questiontype?.toLowerCase() === "boolean" ||
          formik.values.questiontype === "Single Choice" ||
          formik.values.questiontype === "Multiple Choice" ||
          formik.values.questiontype === "Text" ||
          formik.values.questiontype === "Numeric"
        ) {
          data.id = assesstQustionsEditData && assesstQustionsEditData.id;
          data.type.option_score =
            assesstQustionsEditData?.type?.option_score ||
            formik?.values?.option_score;
          data.type.validations = [];
          // delete data.rules;
          data.answer =
            formik.values.questiontype === "Numeric" ||
            formik.values.questiontype === "Text"
              ? values.assesstNumber
                ? values.assesstNumber
                : assesstQustionsEditData?.answer
              : formik.values.questiontype === "Single Choice"
              ? assessmentvalue?.answer
                ? assessmentvalue?.answer
                : assesstanswers
              : formik.values.questiontype === "Multiple Choice"
              ? multiChoice || assesstanswers
              : formik.values.questiontype === "Boolean"
              ? assesstanswers
              : "";
          // data.answer = multiChoice || assesstanswers;
        } else if (
          assessmentvalue &&
          assessmentvalue?.answer &&
          formik.values.questiontype === "Single Choice"
        ) {
          data.answer = assessmentvalue?.answer;
          data.type.validations = [];
        } else if (
          values.assesstNumber &&
          formik.values.questiontype?.toLowerCase() === "numeric"
        ) {
          data.answer = Number(values.assesstNumber);
          data.type.validations = [];
        }
        const idassestqustion = localStorage.getItem("assestqustioneditid");
        if (data) {
          const method =
            editAssesQuestions &&
            editAssesQuestions &&
            editAssesQuestions?.map((val, i) => {
              if (val.id === data.id) {
                let myonj = { ...val };
                myonj = data;
                return myonj;
              }
              return val;
            });
          localStorage.setItem("editquestionasses", JSON.stringify(method));
          // dispatch(assessmentsEditQustionUpdateData(data, idassestqustion));
          navigatioToQuestion(EditAssessment);
        }
      } else {
        if (
          formik.values.questiontype?.toLowerCase() === "boolean" ||
          formik.values.questiontype === "Single Choice" ||
          formik.values.questiontype === "Multiple Choice"
        ) {
          data.id = assesstQustionsEditData && assesstQustionsEditData.id;
          data.type.option_score =
            assesstQustionsEditData?.type?.option_score ||
            formik?.values?.option_score;
          data.type.validations = [];
          // delete data.rules;
          data.answer =
            formik.values.questiontype === "Multiple Choice"
              ? multiChoice
              : formik.values.questiontype === "Single Choice"
              ? assessmentvalue.answer
              : assesstanswers;
        } else if (
          assessmentvalue &&
          assessmentvalue?.answer &&
          formik.values.questiontype === "Single Choice"
        ) {
          data.answer = assessmentvalue?.answer;
          data.type.validations = [];
        } else if (
          (values.assesstNumber || assesstQustionsEditData?.answer) &&
          (formik.values.questiontype?.toLowerCase() === "numeric" ||
            formik.values.questiontype === "Numeric")
        ) {
          data.answer = Number(values.assesstNumber)
            ? Number(values.assesstNumber)
            : assesstQustionsEditData?.answer;
          data.type.validations = [];
        } else if (
          (values.assesstNumber || assesstQustionsEditData?.answer) &&
          formik.values.questiontype === "Text"
        ) {
          data.answer = values.assesstNumber
            ? values.assesstNumber
            : assesstQustionsEditData?.answer;
          data.type.validations = [];
        }
        const idassestqustion = localStorage.getItem("assestqustioneditid");
        dispatch(assessmentsEditQustionUpdateData(data, idassestqustion));
        navigatioToQuestion(EditAssessment);
      }
      // if (data && addnewEditasses) {
      //   if (formik.values.questiontype?.toLowerCase() === "boolean") {
      //     data.id = assesstQustionsEditData && assesstQustionsEditData.id;
      //     data.type.option_score = {};
      //     data.type.validations = [];
      //     delete data.rules;
      //     data.answer = assesstanswers;
      //   } else if (
      //     assessmentvalue &&
      //     assessmentvalue?.answer &&
      //     formik.values.questiontype === "Single Choice"
      //   ) {
      //     data.answer = assessmentvalue?.answer;
      //     data.type.validations = [];
      //   } else if (
      //     values.assesstNumber &&
      //     formik.values.questiontype?.toLowerCase() === "numeric"
      //   ) {
      //     data.answer = Number(values.assesstNumber);
      //     data.type.validations = [];
      //   }
      //   console.log('datadatadata', data);
      //   const idassestqustion = localStorage.getItem("assestqustioneditid");
      //   dispatch(assessmentsEditQustionUpdateData(data, idassestqustion));
      //   navigatioToQuestion(addnewEditasses);
      // }
      //   if (data) {
      //     let oldQues = localStorage.getItem("questions");
      //     if (oldQues) {
      //       let oldD1 = JSON.parse(oldQues);
      //       oldD1.push(data);
      //       localStorage.setItem("questions", JSON.stringify(oldD1));
      //     } else {
      //       let qArray = [];
      //       qArray.push(data);
      //       localStorage.setItem("questions", JSON.stringify(qArray));
      //     }
      //     navigatioToQuestion();
      //   } else {
      //     toast.error("All fields are required");
      //   }
    },
  });

  const handleFirst = (event, index, formValues) => {
    let item = event;
    if (event.condition && event.answer && formValues.observation) {
      if (
        formik &&
        formik?.values &&
        formik?.values?.questiontype === "multiplechoice"
      ) {
        const data = {
          condition: {
            id: index,
            key: event.condition,
            title_to_show: event.condition,
            title: event.condition,
          },
          answer: [event.answer],
          // issueTemplate: formValues ? formValues : "",
        };
        const requires = {};
        const newData = JSON.stringify(item);
        let newData1 = JSON.parse(newData);
        newData1.data = data;
        (newData1.requires = requires),
          (newData1.ruleString = `"When Answer is  \"<b>=</b>\"  to  \"<b>${event.answer.title}</b>\"  then allow comment."`);
        newData1.variables[0].options =
          formik?.values && formik?.values?.attachedlist?.items;
        newData1.variables[1].options = [data?.condition];
        delete newData1.condition;
        delete newData1.answer;
        firstData.push(newData1);
        setTimeout(() => {
          let allRules = ruleList;
          allRules[index].answer = "";
          allRules[index].condition = "";
          setRuleList(allRules);
          setUiRender(!uiRender);
        }, 500);
      } else if (
        formik &&
        formik?.values &&
        formik?.values?.questiontype === "singlechoice"
      ) {
        {
          const data = {
            condition: {
              id: index,
              key: event.condition,
              title_to_show: event.condition,
              title: event.condition,
            },
            answer: event.answer,
            issueTemplate: formValues ? formValues : "",
          };
          const newData = JSON.stringify(item);
          let newData1 = JSON.parse(newData);
          newData1.data = data;
          delete newData1.condition;
          delete newData1.answer;
          firstData.push(newData1);
          // setFirstData([...firstData, newData1]);
          setTimeout(() => {
            let allRules = ruleList;
            allRules[index].answer = "";
            allRules[index].condition = "";
            setRuleList(allRules);
            setUiRender(!uiRender);
          }, 500);
        }
      } else if (
        (formik &&
          formik?.values) &&
          (formik?.values?.questiontype === "Numeric" ||
            formik?.values?.questiontype === "numeric")
      ) {
        const data = {
          condition: {
            id: event?.condition?.id,
            key: event?.condition?.title,
            title_to_show: event?.condition?.title,
            title: event?.condition?.title,
          },

          answer: Number(event?.answer),
          issueTemplate: formValues ? formValues : "",
        };
        const requires = {};
        const newData = JSON.stringify(item);
        let newData1 = JSON.parse(newData);
        (newData1.requires = requires),
          (newData1.ruleString = `"When Answer is  \"<b>=</b>\"  to  \"<b>${event.answer.title}</b>\"  then allow comment."`);
        newData1.data = data;
        newData1.variables[0].type = "number";
        newData1.variables[1].options = [numericCondition];
        delete newData1.condition;
        delete newData1.answer;
        firstData.push(newData1);
        // setFirstData([...firstData, newData1]);
        setTimeout(() => {
          let allRules = ruleList;
          allRules[index].answer = "";
          allRules[index].condition = "";
          setRuleList(allRules);
          setUiRender(!uiRender);
        }, 500);
      } else {
        const data = {
          condition: {
            id: index,
            key: event.condition,
            title_to_show: event.condition,
            title: event.condition,
          },
          answer: {
            title: event?.answer?.title,
            value: event?.answer?.value,
            title_to_show: event.answer?.title,
          },
          issueTemplate: formValues ? formValues : "",
        };
        const requiresBooleand = {};
        const newData = JSON.stringify(item);
        let newData1 = JSON.parse(newData);

        newData1.requires = requiresBooleand;
        newData1.data = data;
        delete newData1.condition;
        delete newData1.answer;
        firstData.push(newData1);
        // setFirstData([...firstData, newData1]);
        setTimeout(() => {
          let allRules = ruleList;
          allRules[index].answer = "";
          allRules[index].condition = "";
          setRuleList(allRules);
          setUiRender(!uiRender);
        }, 500);
      }
    } else if (
      event.condition &&
      event.answer &&
      event.subType &&
      event.subType.title !== "QuestionAutoIssue"
    ) {
      if (
        formik &&
        formik?.values &&
        formik?.values?.questiontype === "multiplechoice"
      ) {
        const data = {
          condition: {
            id: index,
            key: event.condition,
            title_to_show: event.condition,
            title: event.condition,
          },
          answer: [event.answer],
          // issueTemplate: formValues ? formValues : "",
        };
        const requires = {};
        const newData = JSON.stringify(item);
        let newData1 = JSON.parse(newData);
        newData1.data = data;
        (newData1.requires = requires),
          (newData1.ruleString = `"When Answer is  \"<b>=</b>\"  to  \"<b>${event.answer.title}</b>\"  then allow comment."`);
        newData1.variables[0].options =
          formik?.values && formik?.values?.attachedlist?.items;
        newData1.variables[1].options = [data?.condition];
        delete newData1.condition;
        delete newData1.answer;
        firstData.push(newData1);
        setTimeout(() => {
          let allRules = ruleList;
          allRules[index].answer = "";
          allRules[index].condition = "";
          setRuleList(allRules);
          setUiRender(!uiRender);
        }, 500);
      } else if (
        (formik &&
          formik?.values) &&
          (formik?.values?.questiontype === "singlechoice" ||
          formik?.values?.questiontype === "Single Choice")
      ) {
        {
          const data = {
            condition: {
              id: index,
              key: event.condition,
              title_to_show: event.condition,
              title: event.condition,
            },
            answer: event.answer,
            // issueTemplate: formValues ? formValues : "",
          };
          const newData = JSON.stringify(item);
          let newData1 = JSON.parse(newData);
          newData1.data = data;
          delete newData1.condition;
          delete newData1.answer;
          firstData.push(newData1);
          // setFirstData([...firstData, newData1]);
          setTimeout(() => {
            let allRules = ruleList;
            allRules[index].answer = "";
            allRules[index].condition = "";
            setRuleList(allRules);
            setUiRender(!uiRender);
          }, 500);
        }
      } else if (
        (formik &&
          formik?.values) &&
          (formik?.values?.questiontype === "Numeric" ||
          formik?.values?.questiontype === "numeric")
      ) {
        const data = {
          condition: {
            id: event?.condition?.id,
            key: event?.condition?.title,
            title_to_show: event?.condition?.title,
            title: event?.condition?.title,
          },
          answer: Number(event?.answer),
          // issueTemplate: formValues ? formValues : "",
        };
        const requires = {};
        const newData = JSON.stringify(item);
        let newData1 = JSON.parse(newData);
        newData1.data = data;
        newData1.variables[0].type = "number";
        newData1.variables[1].options = [numericCondition];
        (newData1.requires = requires),
          (newData1.ruleString = `"When Answer is  \"<b>=</b>\"  to  \"<b>${event.answer}</b>\"  then allow comment."`);
        delete newData1.condition;
        delete newData1.answer;
        firstData.push(newData1);
        // setFirstData([...firstData, newData1]);
        setTimeout(() => {
          let allRules = ruleList;
          allRules[index].answer = "";
          allRules[index].condition = "";
          setRuleList(allRules);
          setUiRender(!uiRender);
        }, 500);
      } else {
        const data = {
          condition: {
            id: index,
            key: event.condition,
            title_to_show: event.condition,
            title: event.condition,
          },
          answer: {
            title: event?.answer?.title,
            value: event?.answer?.value,
            title_to_show: event.answer?.title,
          },
          // issueTemplate: formValues ? formValues : "",
        };
        const newData = JSON.stringify(item);
        let newData1 = JSON.parse(newData);
        newData1.data = data;
        delete newData1.condition;
        delete newData1.answer;
        firstData.push(newData1);
        // setFirstData([...firstData, newData1]);
        setTimeout(() => {
          let allRules = ruleList;
          allRules[index].answer = "";
          allRules[index].condition = "";
          setRuleList(allRules);
          setUiRender(!uiRender);
        }, 500);
      }
    } else {
      toast.error("All fields are required");
      return;
    }
    dispatch(UploadImgEditQSuccess({ data: { data: [] } }));
  };

  const handleAnswers = (event) => {
    setAssessmentVals(event.target.value);
    let events = event.target.value;
    // for (let item of assesstanswer) {
    if (events == "Yes") {
      assesstanswers.value = true;
      assesstanswers.title = "Yes";
    } else if (events == "No") {
      assesstanswers.value = true;
      assesstanswers.title = "No";
    }
    // }
    let options = assesstanswers;
    setAssesstAnswers(options);
  };
  const getCompanyData = () => {
    const cid = localStorage.getItem("companyId");
    const data = { url: BASE_URL + `companies/${cid}` };
    dispatch(CompanyGetApi(data));
  };
  useEffect(() => {
    getCompanyData();
  }, []);
  useEffect(() => {
    if (
      (addQustionsEditData &&
        addQustionsEditData.type &&
        addQustionsEditData?.type.key === "text") ||
      "media" ||
      "date " ||
      "time" ||
      "datetime" ||
      "timer"
    ) {
      setValue(0);
    }
  }, []);
  useEffect(() => {
    if (
      formik &&
      formik?.values &&
      formik?.values?.attachedlist &&
      formik?.values?.attachedlist?.items
    ) {
      setAssessmentRadio(formik?.values?.attachedlist?.items);
    }
  }, [formik?.values?.attachedlist]);

  useEffect(() => {
    if (multiChoice?.length > 0) {
      for (let item of assessmentradio) {
        assessmentvalue.answer = multiChoice;
      }
      let optionsRadio = assessmentvalue;
      setAssessmentValue(optionsRadio);
    }
  }, [multiChoice]);

  const handleassessts = (event) => {
    let events = event.target.value;
    setRadioVal(events);

    let optionsRadio = { ...assessmentvalue };

    for (let item of assessmentradio) {
      if (events == item.title) {
        optionsRadio.answer = { id: item.id, title: item.title };
      }
    }
    setAssessmentValue(optionsRadio);
    // setRequire(event.target.value);
  };
  const handleReset = () => {
    require[0].value = false;
    require[1].value = false;
    require[2].value = false;
    require[3].value = false;
    require[4].value = false;
    require[5].value = false;
    require[6].value = false;
    takeImageData[0].value = false;
    takeImageData[1].value = false;
    takeVideoData[0].value = false;
    takeVideoData[1].value = false;
    RecordAudioField[0].value = false;
    RecordAudioField[1].value = false;
    allowCommentData[0].value = false;
    allowCommentData[1].value = false;
    imageOptionalData[0].value = false;
    imageOptionalData[1].value = false;
    videoOptionalData[0].value = false;
    videoOptionalData[1].value = false;
    requiredCommentData[0].value = false;
    requiredCommentData[1].value = false;

    setVal("");
    setRecordAudio("");
    setTakeImage("");
    setTakeVideo("");
    setAllowComment("");
    setImageOptional("");
    setVideoOptional("");
    setRequiredComment("");
    // setRequire("");
  };

  return (
    <>
      <ToastContainer autoClose={3000} />
      <form onSubmit={formik.handleSubmit}>
        <Box
          sx={{ bgcolor: "#F6F8FC", borderRadius: "4px", mb: 1 }}
          className="header-card"
        >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Stack direction="row" spacing={1} sx={{ float: "right" }}>
                <Button
                  className="cancle-button cancle-hover"
                  onClick={() => navigate(-1)}
                >
                  <CloseOutlinedIcon sx={{ mr: 1 }} />
                  Cancel
                </Button>

                <Button
                  type="submit"
                  className="header-add cancle-hover"
                  // onClick={handleDone}
                >
                  <CheckOutlinedIcon sx={{ mr: 1 }} />
                  Done
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ bgcolor: "#F6F8FC", p: 1, borderRadius: "4px" }}>
          <Container
            maxWidth="lg"
            sx={{
              backgroundColor: "#fff",
              borderRadius: "10px",
              p: 1,
              textAlign: "left",
            }}
          >
            {/* <FormControl fullWidth sx={{ mb: 2 }}>
              <label>Title Images</label>
              <Box className="attachment-file">
                <input
                  accept="image/*"
                  className="file-upload"
                  id="contained-button-file"
                  multiple
                  type="file"
                  hidden
                  onChange={handleImageUpload}
                />
                <label htmlFor="contained-button-file">
                  <Fab component="span" className="store-file cancle-hover">
                    <AddOutlinedIcon />
                  </Fab>
                </label>
                <Box>
                  {multiimageUrl?.length > 0 ? (
                    multiimageUrl &&
                    multiimageUrl?.map((item) => {
                      return (
                        <img
                          className="file-preview"
                          src={item}
                          alt="attachment"
                        />
                      );
                    })
                  ) : (
                    <Box>
                      <img
                        className="file-preview"
                        src={Blank}
                        alt="attachment"
                      />
                    </Box>
                  )}
                </Box>
              </Box>
            </FormControl> */}

            <FormControl fullWidth sx={{ mb: 2 }}>
              <label>Title Images</label>
              <Box className="attachment-file">
                <input
                  name="titleImages"
                  accept="image/png, image/jpeg"
                  className="file-upload"
                  id="contained-button-file"
                  multiple
                  type="file"
                  hidden
                  onChange={handleImageUpload}
                />
                <label htmlFor="contained-button-file">
                  <Fab component="span" className="store-file cancle-hover">
                    <AddOutlinedIcon />
                  </Fab>
                </label>
                <Box display="flex" flexDirection="row" flexWrap="wrap">
                  {allKey && allKey.length > 0 ? (
                    allKey.map((item, index) => (
                      <div key={index} className="image-container">
                        <div className="image-wrapper">
                          <img
                            className="file-preview"
                            src={item.url || item.location}
                            alt={`attachment-${index}`}
                          />
                          <div className="delete-container">
                            <DeleteIcon
                              className="delete-icon"
                              onClick={() => handleDeleteImage(item.key)}
                              style={{
                                width: "22px",
                                height: "22px",
                                color: "#FFFFFF",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <Box>
                      <img
                        className="file-preview"
                        src={Blank}
                        alt="attachment"
                      />
                    </Box>
                  )}
                </Box>
              </Box>
            </FormControl>
            {pathName == "/questions/add-question-editform" && <FormControl fullWidth sx={{ mb: 2 }}>
              <FormControlLabel
                className="label-name"
                control={
                  <Checkbox
                    type="checkbox"
                    name="isQuestionToInspection"
                    onChange={(e)=>setIsQuestionToInspections(e.target.checked)}
                    inputProps={{ "aria-label": "controlled" }}
                    sx={{ color: "#196773" }}
                    checked={isQuestionToInspections}
                  />
                }
                label="Add Question to Inspection"
              />
            </FormControl>}
            <FormControl fullWidth sx={{ mb: 2 }}>
              <label>Title</label>
              <TextField
                name="title"
                value={formik?.values?.title}
                onChange={formik.handleChange}
                error={formik.touched.title && Boolean(formik.errors.title)}
                helperText={formik.touched.title && formik.errors.title}
                id="outlined-basic"
                variant="outlined"
                size="small"
                className="date-input"
              />
            </FormControl>

            <FormControl fullWidth sx={{ mb: 2 }}>
              <label>Description</label>
              <TextField
                name="description"
                value={formik.values.description}
                onChange={formik.handleChange}
                error={
                  formik.touched.description &&
                  Boolean(formik.errors.description)
                }
                helperText={
                  formik.touched.description && formik.errors.description
                }
                id="outlined-basic"
                variant="outlined"
                size="small"
                multiline
                className="date-input"
              />
            </FormControl>

            <FormControl fullWidth sx={{ mb: 2 }}>
              <label>Question Score</label>
              <TextField
                name="questionscore"
                InputLabelProps={{
                  shrink: true,
                }}
                type="number"
                value={formik?.values?.questionscore}
                onChange={formik.handleChange}
                error={
                  formik.touched.questionscore &&
                  Boolean(formik.errors.questionscore)
                }
                helperText={
                  formik.touched.questionscore && formik.errors.questionscore
                }
                id="filled-number"
                variant="outlined"
                size="small"
                className="date-input"
              />
            </FormControl>

            <FormControl fullWidth sx={{ mb: 2 }}>
              <label>Select Question Type</label>
              <Select
                size="small"
                defaultValue={addQustionsEditData?.type?.title}
                name="questiontype"
                value={
                  formik?.values?.questiontype
                    ? formik?.values?.questiontype
                    : assesstQustionsEditData?.type?.title
                }
                onChange={formik.handleChange}
                disabled
                error={
                  formik.touched.questiontype &&
                  Boolean(formik.errors.questiontype)
                }
                helperText={
                  formik.touched.questiontype && formik.errors.questiontype
                }
                MenuProps={{
                  disableScrollLock: true,
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "center",
                  },
                  sx: {
                    height: "400px",
                  },
                }}
                renderValue={(selected) => {
                  var type = typeof selected;

                  return selected;
                }}
                sx={{ borderRadius: "8px" }}
              >
                <MenuItem disabled value="">
                  Type
                </MenuItem>
                {questiontype?.map((option, i) => {
                  return (
                    <MenuItem key={i} value={option.title}>
                      {option.title
                        ? option.title
                        : addQustionsEditData?.type?.title}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            {formik &&
              formik?.values &&
              formik?.values?.questiontype === "singlechoice" && (
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <label>Main List</label>
                  <Select
                    size="small"
                    name="attachedlist"
                    value={formik?.values?.mainlist}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.mainlist && Boolean(formik.errors.mainlist)
                    }
                    helperText={
                      formik.touched.mainlist && formik.errors.mainlist
                    }
                    MenuProps={{
                      disableScrollLock: true,
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                      },
                      sx: {
                        height: "400px",
                      },
                    }}
                    sx={{ borderRadius: "8px" }}
                  >
                    <MenuItem disabled value="">
                      Type
                    </MenuItem>
                    {companyData &&
                      companyData?.map((option, i) => {
                        return (
                          <MenuItem key={i} value={option}>
                            {option.description ?? option.id}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              )}
            {formik &&
              formik?.values &&
              formik?.values?.questiontype === "multiplechoice" && (
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <label>Main List</label>
                  <Select
                    size="small"
                    name="attachedlist"
                    value={formik?.values?.mainlist}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.mainlist && Boolean(formik.errors.mainlist)
                    }
                    helperText={
                      formik.touched.mainlist && formik.errors.mainlist
                    }
                    MenuProps={{
                      disableScrollLock: true,
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                      },
                      sx: {
                        height: "400px",
                      },
                    }}
                    sx={{ borderRadius: "8px" }}
                  >
                    <MenuItem disabled value="">
                      Type
                    </MenuItem>
                    {companyData &&
                      companyData?.map((option, i) => {
                        return (
                          <MenuItem key={i} value={option}>
                            {option.description ?? option.id}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              )}
            {formik.values.questiontype || addQustionsEditData ? (
              <Box className="tab-box">
                <Box
                  sx={{
                    borderBottom: 1,
                    borderColor: "divider",
                    background: "#F6F8FC",
                  }}
                >
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    <Tab
                      label={EditAssessment ? "Answer" : "Validation"}
                      {...a11yProps(0)}
                      className="tab-item"
                    />

                    {addQustionsEditData &&
                    addQustionsEditData?.type?.key === "text" ? (
                      ""
                    ) : addQustionsEditData &&
                      addQustionsEditData?.type?.key === "numeric" ? (
                      ""
                    ) : addQustionsEditData &&
                      addQustionsEditData?.type?.key === "Numeric" ? (
                      ""
                    ) : addQustionsEditData &&
                      addQustionsEditData?.type?.key === "multi_select" ? (
                      ""
                    ) : addQustionsEditData &&
                      addQustionsEditData?.type?.key === "media" ? (
                      ""
                    ) : addQustionsEditData &&
                      addQustionsEditData?.type?.key === "file" ? (
                      ""
                    ) : addQustionsEditData &&
                      addQustionsEditData?.type?.key === "date" ? (
                      ""
                    ) : addQustionsEditData &&
                      addQustionsEditData?.type?.key === "time" ? (
                      ""
                    ) : addQustionsEditData &&
                      addQustionsEditData?.type?.key === "datetime" ? (
                      ""
                    ) : addQustionsEditData &&
                      addQustionsEditData?.type?.key === "timer" ? (
                      ""
                    ) : addQustionsEditData &&
                      addQustionsEditData?.type?.key === "number" ? (
                      ""
                    ) : 
                    // EditAssessment === "newaddNewAssesment" ||
                    //   EditAssessment === "UpdateAssessment" ? (
                    //   ""
                    // ) :
                     (
                      <Tab
                        label="Rules"
                        {...a11yProps(1)}
                        className="tab-item"
                      />
                    )}
                    {addQustionsEditData &&
                    addQustionsEditData?.type?.key === "text" ? (
                      ""
                    ) : addQustionsEditData &&
                      addQustionsEditData?.type?.key === "numeric" ? (
                      ""
                    ) : addQustionsEditData &&
                      addQustionsEditData?.type?.key === "Numeric" ? (
                      ""
                    ) : addQustionsEditData &&
                      addQustionsEditData?.type?.key === "multi_select" ? (
                      ""
                    ) : addQustionsEditData &&
                      addQustionsEditData?.type?.key === "file" ? (
                      ""
                    ) : addQustionsEditData &&
                      addQustionsEditData?.type?.key === "media" ? (
                      ""
                    ) : addQustionsEditData &&
                      addQustionsEditData?.type?.key === "date" ? (
                      ""
                    ) : addQustionsEditData &&
                      addQustionsEditData?.type?.key === "time" ? (
                      ""
                    ) : addQustionsEditData &&
                      addQustionsEditData?.type?.key === "datetime" ? (
                      ""
                    ) : addQustionsEditData &&
                      addQustionsEditData?.type?.key === "timer" ? (
                      ""
                    ) : addQustionsEditData &&
                      addQustionsEditData?.type?.key === "number" ? (
                      ""
                    ) : (
                      <Tab
                        label="Weightage Score"
                        {...a11yProps(2)}
                        className="tab-item"
                      />
                    )}
                  </Tabs>
                </Box>

                <TabPanel value={value} index={0}>
                  <>
                    {EditAssessment &&
                    formik?.values?.questiontype == "Single Choice"
                      ? formik &&
                        formik?.values &&
                        formik?.values?.attachedlist &&
                        formik?.values?.attachedlist?.items.map(
                          (data, index) => {
                            return (
                              <>
                                <FormControl fullWidth key={index}>
                                  <RadioGroup
                                    onChange={handleassessts}
                                    value={radioval ? radioval : ""}
                                    row
                                    name="row-radio-buttons-group"
                                    sx={{ p: 0 }}
                                    className="radio-btn"
                                  >
                                    <FormControlLabel
                                      value={data.title}
                                      control={
                                        <Radio
                                          size="small"
                                          sx={{
                                            "&, &.Mui-checked": {
                                              color: "#196773",
                                            },
                                          }}
                                        />
                                      }
                                      label={data.title}
                                    />
                                  </RadioGroup>
                                </FormControl>
                              </>
                            );
                          }
                        )
                      : EditAssessment &&
                        formik?.values?.questiontype === "Multiple Choice"
                      ? formik &&
                        formik?.values &&
                        formik?.values?.attachedlist &&
                        formik?.values?.attachedlist?.items.map(
                          (data, index) => {
                            const findValue = multiChoice.findIndex(
                              (val) => val.id === data.id
                            );
                            return (
                              <FormControl fullWidth key={index}>
                                <Box sx={{ display: "block", margin: "5px 0" }}>
                                  <input
                                    value={data.title}
                                    type="checkbox"
                                    checked={
                                      findValue >= 0
                                        ? multiChoice[findValue]?.title
                                        : false
                                    }
                                    onChange={(event) => {
                                      if (event.target.checked) {
                                        setMultiChoice([
                                          ...multiChoice,
                                          { id: data.id, title: data.title },
                                        ]);
                                      } else {
                                        setMultiChoice(
                                          multiChoice.filter(
                                            (people) => people.id !== data.id
                                          )
                                        );
                                      }
                                    }}
                                    // defaultChecked={multiChoice[index]?.title}
                                  />
                                  <label
                                    style={{
                                      "&, &.Mui-checked": {
                                        color: "#196773",
                                      },
                                    }}
                                  >
                                    {data?.title}
                                  </label>
                                </Box>
                              </FormControl>
                            );
                          }
                        )
                      : ""}

                    {EditAssessment &&
                    formik?.values?.questiontype?.toLowerCase() == "boolean" ? (
                      <FormControl fullWidth>
                        <RadioGroup
                          value={assessmentVals ? assessmentVals : ""}
                          onChange={handleAnswers}
                          row
                          name="row-radio-buttons-group"
                          sx={{ p: 0 }}
                          className="radio-btn"
                        >
                          <FormControlLabel
                            value="Yes"
                            control={
                              <Radio
                                size="small"
                                sx={{
                                  "&, &.Mui-checked": {
                                    color: "#196773",
                                  },
                                }}
                              />
                            }
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            control={
                              <Radio
                                size="small"
                                sx={{
                                  "&, &.Mui-checked": {
                                    color: "#196773",
                                  },
                                }}
                              />
                            }
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    ) : (
                      ""
                    )}

                    {EditAssessment &&
                    formik?.values?.questiontype?.toLowerCase() == "numeric" ? (
                      <FormControl hiddenLabel sx={{ mb: 2 }}>
                        {/* {items && items.answer ? "" : <InputLabel id="formsid" disableAnimation>Select Item</InputLabel>} */}
                        <TextField
                          name="assesstNumber"
                          // InputLabelProps={{
                          //   shrink: true,
                          // }}
                          autoComplete="off"
                          sx={{
                            width: { sm: 80, md: 150 },
                            "& .MuiInputBase-root": {
                              height: 35,
                            },
                          }}
                          // label={<Input id="formsid"/>}
                          variant="outlined"
                          disableUnderline={true}
                          InputProps={{
                            inputProps: { min: 1 },
                          }}
                          type="number"
                          // assesstQustionsEditData?.answer
                          value={formik?.values?.assesstNumber}
                          defaultValue={assesstQustionsEditData?.answer}
                          // value={items && items.answer ? items.answer : ""}
                          // onChange={formik.handleChange}
                          onChange={formik.handleChange}
                          id="filled-number"
                          size="md"
                          className="date-input"
                        />
                      </FormControl>
                    ) : (
                      ""
                    )}
                    {EditAssessment &&
                    formik?.values?.questiontype?.toLowerCase() == "text" ? (
                      <FormControl hiddenLabel sx={{ mb: 2 }}>
                        {/* {items && items.answer ? "" : <InputLabel id="formsid" disableAnimation>Select Item</InputLabel>} */}
                        <TextField
                          name="assesstNumber"
                          // InputLabelProps={{
                          //   shrink: true,
                          // }}
                          autoComplete="off"
                          sx={{
                            width: { sm: 80, md: 150 },
                            "& .MuiInputBase-root": {
                              height: 35,
                            },
                          }}
                          // label={<Input id="formsid"/>}
                          variant="outlined"
                          disableUnderline={true}
                          InputProps={{
                            inputProps: { min: 1 },
                          }}
                          type="text"
                          // assesstQustionsEditData?.answer
                          value={formik?.values?.assesstNumber}
                          defaultValue={assesstQustionsEditData?.answer}
                          // value={items && items.answer ? items.answer : ""}
                          // onChange={formik.handleChange}
                          onChange={formik.handleChange}
                          id="filled-number"
                          size="md"
                          className="date-input"
                        />
                      </FormControl>
                    ) : (
                      ""
                    )}
                    {validatarr.map((item) => {
                      return (
                        <div>
                          {!EditAssessment ? (
                            <>
                              {item?.key === "required" && (
                                <FormControl fullWidth>
                                  <label>
                                    {item.id}. &nbsp;
                                    {item?.title}
                                  </label>
                                  <RadioGroup
                                    // value={require}
                                    onChange={handleRequires}
                                    value={val ? val : ""}
                                    row
                                    name="required"
                                    sx={{ p: 0 }}
                                    className="radio-btn"
                                  >
                                    <FormControlLabel
                                      value={"Yes"}
                                      control={
                                        <Radio
                                          size="small"
                                          // onClick={() => {
                                          //   setRequire("yes");
                                          // }}
                                        />
                                      }
                                      label="Yes"
                                    />

                                    <FormControlLabel
                                      value={"No"}
                                      control={
                                        <Radio
                                          size="small"
                                          // onClick={() => setRequire("no")}
                                        />
                                      }
                                      label="No"
                                    />
                                    <FormControlLabel
                                      value={"Acceptable"}
                                      control={
                                        <Radio
                                          size="small"
                                          // onClick={() => setRequire("acceptable")}
                                        />
                                      }
                                      label="Acceptable"
                                    />
                                    <FormControlLabel
                                      value={"Not Acceptable"}
                                      control={
                                        <Radio
                                          size="small"
                                          // onClick={() => setRequire("not acceptable")}
                                        />
                                      }
                                      label="Not Acceptable"
                                    />
                                    <FormControlLabel
                                      value={"Discarded"}
                                      control={
                                        <Radio
                                          size="small"
                                          // onClick={() => setRequire("discarded")}
                                        />
                                      }
                                      label="Discarded"
                                    />
                                    <FormControlLabel
                                      value={"Returned"}
                                      control={
                                        <Radio
                                          size="small"
                                          // onClick={() => setRequire("returned")}
                                        />
                                      }
                                      label="Returned"
                                    />
                                    <FormControlLabel
                                      value={"Not applicable"}
                                      control={
                                        <Radio
                                          size="small"
                                          // onClick={() => setRequire("not applicable")}
                                        />
                                      }
                                      label="Not Applicable"
                                    />
                                  </RadioGroup>
                                </FormControl>
                              )}
                              {item?.key === "audio" && (
                                <FormControl fullWidth>
                                  <label>
                                    {item.id}. &nbsp;
                                    {item?.title}
                                  </label>
                                  <RadioGroup
                                    // onChange={formik.handleChange}
                                    onChange={handlesRecordaudio}
                                    value={RecordAudio}
                                    row
                                    name="audio"
                                    sx={{ p: 0 }}
                                    className="radio-btn"
                                  >
                                    <FormControlLabel
                                      value={"yes"}
                                      control={
                                        <Radio
                                          size="small"
                                          // onClick={() => setRecordAudio("yes")}
                                        />
                                      }
                                      label="Yes"
                                    />
                                    <FormControlLabel
                                      value={"no"}
                                      control={
                                        <Radio
                                          size="small"
                                          // onClick={() => setRecordAudio("no")}
                                        />
                                      }
                                      label="No"
                                    />
                                  </RadioGroup>
                                </FormControl>
                              )}
                              {item?.key === "image" && (
                                <FormControl fullWidth>
                                  <label>
                                    {item.id}. &nbsp;
                                    {item?.title}
                                  </label>
                                  <RadioGroup
                                    onChange={handlesTakeImage}
                                    value={takeImage}
                                    row
                                    name="image"
                                    sx={{ p: 0 }}
                                    className="radio-btn"
                                  >
                                    <FormControlLabel
                                      value={"yes"}
                                      control={
                                        <Radio
                                          size="small"
                                          // onClick={() => {
                                          //   setTakeImage("yes");
                                          // }}
                                        />
                                      }
                                      label="Yes"
                                    />
                                    <FormControlLabel
                                      value={"no"}
                                      control={
                                        <Radio
                                          size="small"
                                          // onClick={() => {
                                          //   setTakeImage("no");
                                          // }}
                                        />
                                      }
                                      label="No"
                                    />
                                  </RadioGroup>
                                </FormControl>
                              )}
                              {item?.key === "video" && (
                                <FormControl fullWidth>
                                  <label>
                                    {item.id}. &nbsp;
                                    {item?.title}
                                  </label>
                                  <RadioGroup
                                    onChange={handleTakeVideo}
                                    value={takeVideo}
                                    row
                                    name="video"
                                    sx={{ p: 0 }}
                                    className="radio-btn"
                                  >
                                    <FormControlLabel
                                      value={"yes"}
                                      control={
                                        <Radio
                                          size="small"
                                          // onClick={() => {
                                          //   setTakeImage("yes");
                                          // }}
                                        />
                                      }
                                      label="Yes"
                                    />
                                    <FormControlLabel
                                      value={"no"}
                                      control={
                                        <Radio
                                          size="small"
                                          // onClick={() => {
                                          //   setTakeImage("no");
                                          // }}
                                        />
                                      }
                                      label="No"
                                    />
                                  </RadioGroup>
                                </FormControl>
                              )}
                              {item?.key === "comment" && (
                                <FormControl fullWidth>
                                  <label>
                                    {item.id}. &nbsp;
                                    {item?.title}
                                  </label>
                                  <RadioGroup
                                    onChange={handlesAllowComment}
                                    value={allowComment}
                                    row
                                    name="comment"
                                    sx={{ p: 0 }}
                                    className="radio-btn"
                                  >
                                    <FormControlLabel
                                      value={"yes"}
                                      control={<Radio size="small" />}
                                      label="Yes"
                                    />
                                    <FormControlLabel
                                      value={"no"}
                                      control={<Radio size="small" />}
                                      label="No"
                                    />
                                  </RadioGroup>
                                </FormControl>
                              )}
                              {item?.key === "image_optional" && (
                                <FormControl fullWidth>
                                  <label>
                                    {item.id}. &nbsp;
                                    {item?.title}
                                  </label>
                                  <RadioGroup
                                    onChange={handleImageOptionals}
                                    value={imageOptional}
                                    row
                                    name="image_optional"
                                    sx={{ p: 0 }}
                                    className="radio-btn"
                                  >
                                    <FormControlLabel
                                      value={"yes"}
                                      control={<Radio size="small" />}
                                      label="Yes"
                                    />
                                    <FormControlLabel
                                      value={"no"}
                                      control={<Radio size="small" />}
                                      label="No"
                                    />
                                  </RadioGroup>
                                </FormControl>
                              )}
                              {item?.key === "video_optional" && (
                                <FormControl fullWidth>
                                  <label>
                                    {item.id}. &nbsp;
                                    {item?.title}
                                  </label>
                                  <RadioGroup
                                    onChange={handleVideoOptional}
                                    value={videoOptional}
                                    row
                                    name="video_optional"
                                    sx={{ p: 0 }}
                                    className="radio-btn"
                                  >
                                    <FormControlLabel
                                      value={"yes"}
                                      control={
                                        <Radio
                                          size="small"
                                          // onClick={() => {
                                          //   setImageOptional("yes");
                                          // }}
                                        />
                                      }
                                      label="Yes"
                                    />
                                    <FormControlLabel
                                      value={"no"}
                                      control={
                                        <Radio
                                          size="small"
                                          // onClick={() => {
                                          //   setImageOptional("no");
                                          // }}
                                        />
                                      }
                                      label="No"
                                    />
                                  </RadioGroup>
                                </FormControl>
                              )}
                              {item?.key === "comment_required" && (
                                <FormControl fullWidth>
                                  <label>
                                    {item.id}. &nbsp;
                                    {item?.title}
                                  </label>
                                  <RadioGroup
                                    onChange={handleRequiredComments}
                                    value={requiredComment}
                                    row
                                    name="comment_required"
                                    sx={{ p: 0 }}
                                    className="radio-btn"
                                  >
                                    <FormControlLabel
                                      value={"yes"}
                                      control={<Radio size="small" />}
                                      label="Yes"
                                    />
                                    <FormControlLabel
                                      value={"no"}
                                      control={<Radio size="small" />}
                                      label="No"
                                    />
                                  </RadioGroup>
                                </FormControl>
                              )}
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      );
                    })}
                    {/* <Button
                      className="header-add cancle-hover"
                      onClick={handleReset}
                    >
                      Reset All
                    </Button> */}
                    {!EditAssessment && (
                      <Button
                        variant="outlined"
                        color="default"
                        className="btn-resetall header-add cancle-hover"
                        onClick={handleReset}
                      >
                        Reset All
                      </Button>
                    )}
                  </>
                </TabPanel>

                <TabPanel value={value} index={1}>
                  {addQustionsEditData &&
                  addQustionsEditData?.type?.key === "text" ? (
                    ""
                  ) : addQustionsEditData &&
                    addQustionsEditData?.type?.key === "numeric" ? (
                    ""
                  ) : addQustionsEditData &&
                    addQustionsEditData?.type?.key === "Numeric" ? (
                    ""
                  ) : addQustionsEditData &&
                    addQustionsEditData?.type?.key === "multi_select" ? (
                    ""
                  ) : addQustionsEditData &&
                    addQustionsEditData?.type?.key === "media" ? (
                    ""
                  ) : addQustionsEditData &&
                    addQustionsEditData?.type?.key === "file" ? (
                    ""
                  ) : addQustionsEditData &&
                    addQustionsEditData?.type?.key === "date" ? (
                    ""
                  ) : addQustionsEditData &&
                    addQustionsEditData?.type?.key === "time" ? (
                    ""
                  ) : addQustionsEditData &&
                    addQustionsEditData?.type?.key === "datetime" ? (
                    ""
                  ) : addQustionsEditData &&
                    addQustionsEditData?.type?.key === "timer" ? (
                    ""
                  ) : addQustionsEditData &&
                    addQustionsEditData?.type?.key === "number" ? (
                    ""
                  ) : 
                  // EditAssessment === "newaddNewAssesment" ||
                  //   EditAssessment === "UpdateAssessment" ? (
                  //   ""
                  // ) :
                   (
                    <>
                      <Grid container spacing={1} sx={{ textAlign: "left" }}>
                        <Grid item xs={12}>
                          <Box sx={{ fontWeight: "bold" }}>Rules</Box>
                          <Box sx={{ fontWeight: "medium", mt: 1, mb: 2 }}>
                            Rules Preset
                          </Box>
                          {ruleList &&
                            ruleList.map((items, i) => {
                              return (
                                <Card sx={{ p: 3, mb: 2 }} key={i}>
                                  <Grid
                                    container
                                    spacing={1}
                                    sx={{
                                      textAlign: "left",
                                      padding: "20px 0px 20px 0px",
                                    }}
                                  >
                                    <Grid item xs={12} sm={12} md={12} lg={11}>
                                      <Stack
                                        direction="row"
                                        spacing={1}
                                        className="list-btn third-list"
                                      >
                                        <Typography>
                                          {items?.template?.split(/[{,}]/)[0]}
                                        </Typography>
                                        {items?.template?.split(/[{,}]/)[1] ===
                                        "condition" ? (
                                          <FormControl className="Rules-preset-form">
                                            {addQustionsEditData &&
                                            addQustionsEditData.type &&
                                            addQustionsEditData.type.key ===
                                              "number" ? (
                                              <Select
                                                labelId="demo-simple-select-label"
                                                size="small"
                                                id="demo-simple-select"
                                                name="condition"
                                                defaultValue="select"
                                                value={
                                                  items && items.condition
                                                    ? items.condition.title
                                                    : "SelectCondition"
                                                }
                                                renderValue={(selectvalue) =>
                                                  selectvalue
                                                }
                                                onChange={(e) => {
                                                  handleChangeRules(
                                                    e,
                                                    items,
                                                    i
                                                  );
                                                }}
                                                sx={{ borderRadius: "8px" }}
                                              >
                                                <MenuItem
                                                  disabled
                                                  value="select"
                                                >
                                                  <em
                                                    style={{
                                                      fontStyle: "unset",
                                                      fontSize: "12px",
                                                      color: "darkgrey",
                                                    }}
                                                  >
                                                    Select Condition
                                                  </em>
                                                </MenuItem>
                                                {numericCondition &&
                                                  numericCondition.map(
                                                    (select, i) => {
                                                      return (
                                                        <MenuItem
                                                          key={i}
                                                          value={select}
                                                          className="select-item"
                                                        >
                                                          {select?.title}
                                                        </MenuItem>
                                                      );
                                                    }
                                                  )}
                                              </Select>
                                            ) : (
                                              <Select
                                                labelId="demo-simple-select-label"
                                                size="small"
                                                id="demo-simple-select"
                                                name="condition"
                                                defaultValue="select"
                                                value={
                                                  items && items.condition
                                                    ? items.condition
                                                    : "select"
                                                }
                                                onChange={(e) =>
                                                  handleChangeRules(e, items, i)
                                                }
                                                sx={{ borderRadius: "8px" }}
                                              >
                                                <MenuItem
                                                  value="="
                                                  className="select-item"
                                                >
                                                  =
                                                </MenuItem>
                                              </Select>
                                            )}
                                          </FormControl>
                                        ) : (
                                          ""
                                        )}
                                        <Typography>
                                          {items?.template?.split(/[{,}]/)[2]}
                                        </Typography>
                                        {/* {items?.template?.split(/[{,}]/)[3] ===
                             "answer" ? (
                               <FormControl className="Rules-preset-form">
                                 <Select
                                   size="small"
                                   defaultValue="select"
                                   labelId="demo-simple-select-label"
                                   id="demo-simple-select"
                                   name="answer"
                                   value={
                                     items && items.answer
                                       ? items.answer
                                       : "select"
                                   }
                                   onChange={(e) =>
                                     handleChangeRules(e, items, i)
                                   }
                                   MenuProps={{
                                     disableScrollLock: true,
                                     anchorOrigin: {
                                       vertical: "bottom",
                                       horizontal: "center",
                                     },
                                     PaperProps: {
                                       sx: {
                                         height: "150px",
                                       },
                                     },
                                   }}
                                   sx={{ borderRadius: "8px" }}
                                 >
                                   <MenuItem disabled value="select">
                                     <em
                                       style={{
                                         fontStyle: "unset",
                                         color: "darkgrey",
                                       }}
                                     >
                                       Select Answer
                                     </em>
                                   </MenuItem>
                                   {optionsAnswer &&
                                     optionsAnswer.map((select, i) => {
                                       return (
                                         <MenuItem
                                           key={i}
                                           value={select.value}
                                         >
                                           {select?.title}
                                         </MenuItem>
                                       );
                                     })}
                                 </Select>
                               </FormControl>
                             ) : (
                               ""
                             )} */}
                                        {items?.template?.split(/[{,}]/)[3] ===
                                        "answer" ? (
                                          addQustionsEditData &&
                                          addQustionsEditData.type &&
                                          addQustionsEditData.type.key ===
                                            "single_select" &&
                                          addQustionsEditData &&
                                          addQustionsEditData?.attached_list ? (
                                            <FormControl className="Rules-preset-form">
                                              <Select
                                                size="small"
                                                defaultValue="select"
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                name="answer"
                                                value={
                                                  items && items.answer
                                                    ? items.answer
                                                    : "select"
                                                }
                                                onChange={(e) =>
                                                  handleChangeRules(e, items, i)
                                                }
                                                MenuProps={{
                                                  disableScrollLock: true,
                                                  anchorOrigin: {
                                                    vertical: "bottom",
                                                    horizontal: "center",
                                                  },
                                                  PaperProps: {
                                                    sx: {
                                                      height: "150px",
                                                    },
                                                  },
                                                }}
                                                sx={{ borderRadius: "8px" }}
                                              >
                                                <MenuItem
                                                  disabled
                                                  value="select"
                                                >
                                                  <em
                                                    style={{
                                                      fontStyle: "unset",
                                                      color: "darkgrey",
                                                    }}
                                                  >
                                                    Select Answer
                                                  </em>
                                                </MenuItem>
                                                {addQustionsEditData &&
                                                  addQustionsEditData?.attached_list &&
                                                  addQustionsEditData?.attached_list?.items?.map(
                                                    (select, i) => {
                                                      return (
                                                        <MenuItem
                                                          key={i}
                                                          value={select}
                                                        >
                                                          {select?.title}
                                                        </MenuItem>
                                                      );
                                                    }
                                                  )}
                                              </Select>
                                            </FormControl>
                                          ) : addQustionsEditData &&
                                            addQustionsEditData.type &&
                                            addQustionsEditData.type.key ===
                                              "multi_select" &&
                                            addQustionsEditData &&
                                            addQustionsEditData?.attached_list ? (
                                            <FormControl className="Rules-preset-form">
                                              <Select
                                                size="small"
                                                defaultValue="select"
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                name="answer"
                                                value={
                                                  items && items.answer
                                                    ? items.answer
                                                    : "select"
                                                }
                                                onChange={(e) =>
                                                  handleChangeRules(e, items, i)
                                                }
                                                MenuProps={{
                                                  disableScrollLock: true,
                                                  anchorOrigin: {
                                                    vertical: "bottom",
                                                    horizontal: "center",
                                                  },
                                                  PaperProps: {
                                                    sx: {
                                                      height: "150px",
                                                    },
                                                  },
                                                }}
                                                sx={{ borderRadius: "8px" }}
                                              >
                                                <MenuItem
                                                  disabled
                                                  value="select"
                                                >
                                                  <em
                                                    style={{
                                                      fontStyle: "unset",
                                                      color: "darkgrey",
                                                    }}
                                                  >
                                                    Select Answer
                                                  </em>
                                                </MenuItem>
                                                {addQustionsEditData &&
                                                  addQustionsEditData?.attached_list &&
                                                  addQustionsEditData?.attached_list?.items?.map(
                                                    (select, i) => {
                                                      return (
                                                        <MenuItem
                                                          key={i}
                                                          value={select}
                                                        >
                                                          {select?.title}
                                                        </MenuItem>
                                                      );
                                                    }
                                                  )}
                                              </Select>
                                            </FormControl>
                                          ) : addQustionsEditData &&
                                            addQustionsEditData.type &&
                                            addQustionsEditData.type.key ===
                                              "number" ? (
                                            <FormControl
                                              hiddenLabel
                                              sx={{ mb: 2 }}
                                            >
                                              {/* {items && items.answer ? "" : <InputLabel id="formsid" disableAnimation>Select Item</InputLabel>} */}
                                              <TextField
                                                name="answer"
                                                // InputLabelProps={{
                                                //   shrink: true,
                                                // }}
                                                autoComplete="off"
                                                sx={{
                                                  width: { sm: 80, md: 150 },
                                                  "& .MuiInputBase-root": {
                                                    height: 35,
                                                  },
                                                }}
                                                // label={<Input id="formsid"/>}
                                                variant="outlined"
                                                disableUnderline={true}
                                                InputProps={{
                                                  inputProps: { min: 1 },
                                                }}
                                                type="number"
                                                // value={formik?.values?.questionscore}
                                                value={
                                                  items && items.answer
                                                    ? items.answer
                                                    : ""
                                                }
                                                // onChange={formik.handleChange}
                                                onChange={(e) =>
                                                  handleChangeRules(e, items, i)
                                                }
                                                error={
                                                  formik.touched
                                                    .questionscore &&
                                                  Boolean(
                                                    formik.errors.questionscore
                                                  )
                                                }
                                                helperText={
                                                  formik.touched
                                                    .questionscore &&
                                                  formik.errors.questionscore
                                                }
                                                id="filled-number"
                                                size="md"
                                                className="date-input"
                                              />
                                            </FormControl>
                                          ) : (
                                            <FormControl className="Rules-preset-form">
                                              <Select
                                                size="small"
                                                defaultValue="select"
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                name="answer"
                                                value={
                                                  items && items.answer
                                                    ? items.answer.title
                                                    : "select"
                                                }
                                                renderValue={(selectvalue) =>
                                                  selectvalue
                                                }
                                                onChange={(e) =>
                                                  handleChangeRules(e, items, i)
                                                }
                                                MenuProps={{
                                                  disableScrollLock: true,
                                                  anchorOrigin: {
                                                    vertical: "bottom",
                                                    horizontal: "center",
                                                  },
                                                  PaperProps: {
                                                    sx: {
                                                      height: "150px",
                                                    },
                                                  },
                                                }}
                                                sx={{ borderRadius: "8px" }}
                                              >
                                                <MenuItem
                                                  disabled
                                                  value="select"
                                                >
                                                  <em
                                                    style={{
                                                      fontStyle: "unset",
                                                      color: "darkgrey",
                                                    }}
                                                  >
                                                    Select Answer
                                                  </em>
                                                </MenuItem>
                                                {optionsAnswer &&
                                                  optionsAnswer.map(
                                                    (select, i) => {
                                                      return (
                                                        <MenuItem
                                                          key={i}
                                                          value={select}
                                                          className="select-item"
                                                        >
                                                          {select?.title}
                                                        </MenuItem>
                                                      );
                                                    }
                                                  )}
                                              </Select>
                                            </FormControl>
                                          )
                                        ) : (
                                          ""
                                        )}
                                        <Typography>
                                          {items?.template?.split(/[{,}]/)[4]}
                                        </Typography>
                                        {items?.template?.split(/[{,}]/)[5] ===
                                        "issueTemplate" ? (
                                          <Stack
                                            direction="row"
                                            spacing={1}
                                            className="list-btn"
                                            sx={{
                                              alignItems: "center",
                                              margin: "5px 0 0 0",
                                            }}
                                          >
                                            <Button
                                              onClick={handleClickOpen}
                                              className="select-issues cancle-hover"
                                            >
                                              Select Issue Template
                                            </Button>
                                          </Stack>
                                        ) : (
                                          ""
                                        )}
                                        {items?.template?.split(/[{,}]/)[6]}
                                      </Stack>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      md={12}
                                      lg={1}
                                      className="plus-icon-grid"
                                    >
                                      <Box
                                        sx={{ float: "right" }}
                                        className="plus-icon"
                                      >
                                        <AddOutlinedIcon
                                          onClick={(e) =>
                                            handleFirst(items, i, formValues)
                                          }
                                          sx={{ mr: 1 }}
                                          className="add-icon"
                                        />
                                      </Box>
                                    </Grid>
                                  </Grid>
                                </Card>
                              );
                            })}
                        </Grid>
                      </Grid>
                      <Card sx={{ p: 3, mb: 1 }} className="card">
                        <h3>Your Rules</h3>
                        {/* {firstData &&
               firstData.map((items, i) => (
                 <Grid
                   container
                   spacing={1}
                   sx={{
                     textAlign: "left",
                     margin: "5px",
                     padding: "5px",
                   }}
                 >
                   <Grid item xs={12} sm={12} md={12} lg={11}>
                     <Box>
                       <Box sx={{ display: "flex" }}>
                         <Typography>
                           {items?.template?.split(/[{,}]/)[0]}
                         </Typography>
                         <Typography>
                           &nbsp; "
                           <b>
                             {items?.template?.split(/[{,}]/)[1] ===
                             "condition"
                               ? items.data?.condition?.title
                               : ""}
                           </b>
                           " &nbsp;{" "}
                         </Typography>
                         <Typography>
                           {items?.template?.split(/[{,}]/)[2]}
                         </Typography>
                         <Typography>
                           &nbsp; "
                           <b>
                             {items?.template?.split(/[{,}]/)[3] ===
                             "answer"
                               ? items.data?.answer?.title
                               : ""}
                           </b>
                           " &nbsp;
                         </Typography>
                         <Typography>
                           {items?.template?.split(/[{,}]/)[4]}
                         </Typography>
                         <Divider />

                         <Divider />
                       </Box>
                       {items?.template?.split(/[{,}]/)[5] ===
                       "issueTemplate" ? (
                         <Box>
                           <h4>
                             <b>
                               Observation :{" "}
                               {items?.data?.issueTemplate?.issue}
                             </b>
                           </h4>
                           <p>
                             {items?.data?.issueTemplate?.category}{" "}
                             {"=>"}{" "}
                             {items?.data?.issueTemplate?.subcategory}
                           </p>
                           <p>
                             IssuePriority :{" "}
                             <b>
                               {items?.data?.issueTemplate?.severity}
                             </b>
                           </p>
                         </Box>
                       ) : (
                         ""
                       )}
                       <Typography>
                         {" "}
                         {items?.template?.split(/[{,}]/)[6]}
                       </Typography>
                     </Box>
                   </Grid>
                   <Grid
                     item
                     xs={12}
                     sm={12}
                     md={12}
                     lg={1}
                     sx={{ display: "flex", justifyContent: "center" }}
                   >
                     <IconButton onClick={() => handleDelete(i)}>
                       <DeleteOutlineIcon sx={{ color: "#e74a3b" }} />
                     </IconButton>
                   </Grid>
                 </Grid>
               ))} */}
                        {firstData &&
                          firstData.map((items, i) => (
                            <Grid
                              container
                              spacing={1}
                              sx={{
                                textAlign: "left",
                                margin: "5px",
                                padding: "5px",
                              }}
                            >
                              <Grid item xs={12} sm={12} md={12} lg={11}>
                                <Box>
                                  <Box sx={{ display: "flex" }}>
                                    <Typography>
                                      {items?.template?.split(/[{,}]/)[0]}
                                    </Typography>
                                    <Typography>
                                      &nbsp; "
                                      <b>
                                        {items?.template?.split(/[{,}]/)[1] ===
                                        "condition"
                                          ? items?.data?.condition?.title
                                          : ""}
                                      </b>
                                      " &nbsp;{" "}
                                    </Typography>
                                    <Typography>
                                      {items?.template?.split(/[{,}]/)[2]}
                                    </Typography>
                                    <Typography>
                                      {/* &nbsp; "
                                 <b>
                                   {items?.template?.split(/[{,}]/)[3] ===
                                     "answer"
                                     ? items.data?.answer?.map((data)=>(
                                       <>{data.title}</>
                                     ))
                                     : ""}
                                 </b>
                                 " &nbsp; */}
                                      {
                                        <>
                                          {" "}
                                          &nbsp; "{" "}
                                          <b>
                                            {items &&
                                            items?.template?.split(
                                              /[{,}]/
                                            )[3] === "answer" ? (
                                              <>
                                                {items &&
                                                  items.data &&
                                                  Array.isArray(
                                                    items?.data?.answer
                                                  ) &&
                                                  items.data?.answer?.map(
                                                    (data) => <>{data.title}</>
                                                  )}

                                                {Array.isArray(
                                                  items?.data?.answer
                                                ) === false ? (
                                                  <>
                                                    {" "}
                                                    <b>
                                                      {items &&
                                                      items?.template?.split(
                                                        /[{,}]/
                                                      )[3] === "answer"
                                                        ? items &&
                                                          items.data &&
                                                          items.data?.answer &&
                                                          items.data?.answer
                                                            ?.title
                                                          ? items.data?.answer
                                                              ?.title
                                                          : items.data?.answer
                                                        : ""}
                                                    </b>
                                                  </>
                                                ) : (
                                                  ""
                                                )}
                                              </>
                                            ) : (
                                              ""
                                            )}
                                          </b>
                                          " &nbsp;
                                        </>

                                        // : formik?.values?.questiontype ===
                                        //   "numeric" ? (
                                        //   <>
                                        //     {" "}
                                        //     &nbsp; "{" "}
                                        //     <b>
                                        //       {items &&
                                        //       items?.template?.split(
                                        //         /[{,}]/
                                        //       )[3] === "answer"
                                        //         ? items &&
                                        //           items.data &&
                                        //           items.data?.answer
                                        //         : ""}
                                        //     </b>
                                        //     " &nbsp;
                                        //   </>
                                        // ) : (
                                        //   <>
                                        //     {" "}
                                        //     &nbsp; "{" "}
                                        //     <b>
                                        //       {items &&
                                        //       items?.template?.split(
                                        //         /[{,}]/
                                        //       )[3] === "answer"
                                        //         ? items &&
                                        //           items.data &&
                                        //           items.data?.answer?.title
                                        //         : ""}
                                        //     </b>
                                        //     " &nbsp;
                                        //   </>
                                        // )
                                      }
                                    </Typography>
                                    <Typography>
                                      {items?.template?.split(/[{,}]/)[4]}
                                    </Typography>
                                    <Divider />

                                    <Divider />
                                  </Box>
                                  {items?.template?.split(/[{,}]/)[5] ===
                                  "issueTemplate" ? (
                                    <Box>
                                      <h4>
                                        <b>
                                          Observation :{" "}
                                          {
                                            items?.data?.issueTemplate
                                              ?.observation
                                          }
                                        </b>
                                      </h4>
                                      <p>
                                        {items?.data?.issueTemplate?.category}{" "}
                                        {"=>"}{" "}
                                        {
                                          items?.data?.issueTemplate
                                            ?.subCategory
                                        }
                                      </p>
                                      <p>
                                        IssuePriority :{" "}
                                        <b>
                                          {items?.data?.issueTemplate?.severity}
                                        </b>
                                      </p>
                                    </Box>
                                  ) : (
                                    ""
                                  )}
                                  <Typography>
                                    {" "}
                                    {items?.template?.split(/[{,}]/)[6]}
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={1}
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <IconButton onClick={() => handleDelete(i)}>
                                  <DeleteOutlineIcon
                                    sx={{ color: "#e74a3b" }}
                                  />
                                </IconButton>
                              </Grid>
                            </Grid>
                          ))}
                      </Card>
                    </>
                  )}
                </TabPanel>
                <TabPanel
                  value={value}
                  index={
                    // EditAssessment === "newaddNewAssesment" ||
                    // EditAssessment === "UpdateAssessment"
                    //   ? 1
                    //   : 
                      2
                  }
                >
                  {addQustionsEditData &&
                  addQustionsEditData?.type?.key === "text" ? (
                    ""
                  ) : addQustionsEditData &&
                    addQustionsEditData?.type?.key === "media" ? (
                    ""
                  ) : addQustionsEditData &&
                    addQustionsEditData?.type?.key === "date" ? (
                    ""
                  ) : addQustionsEditData &&
                    addQustionsEditData?.type?.key === "time" ? (
                    ""
                  ) : addQustionsEditData &&
                    addQustionsEditData?.type?.key === "datetime" ? (
                    ""
                  ) : addQustionsEditData &&
                    addQustionsEditData?.type?.key === "timer" ? (
                    ""
                  ) : (
                    <>
                      {addQustionsEditData &&
                      addQustionsEditData.type &&
                      addQustionsEditData.type.key === "single_select" ? (
                        singlemultichoice &&
                        Object.entries(singlemultichoice)?.map(([key, val]) => (
                          <Grid container spacing={1} className="marginbottom">
                            <Grid item md={6} xs={12} sm={12} lg={2}>
                              <Typography>{key}</Typography>
                            </Grid>
                            <Grid item md={6} xs={12} sm={12} lg={6}>
                              <TextField
                                id="outlined-basic"
                                type="number"
                                variant="outlined"
                                size="small"
                                fullWidth
                                name={key}
                                defaultValue={val}
                                value={singleandmultichoice.value}
                                onChange={(e) =>
                                  handleSingleAndMultiChoice(e, key, val)
                                }
                                className="date-input"
                              />
                            </Grid>
                          </Grid>
                        ))
                      ) : addQustionsEditData &&
                        addQustionsEditData.type &&
                        addQustionsEditData.type.key === "multi_select" ? (
                        singlemultichoice &&
                        Object.entries(singlemultichoice)?.map(([key, val]) => (
                          <Grid container spacing={1} className="marginbottom">
                            <Grid item md={6} xs={12} sm={12} lg={2}>
                              <Typography>{key}</Typography>
                            </Grid>
                            <Grid item md={6} xs={12} sm={12} lg={6}>
                              <TextField
                                id="outlined-basic"
                                type="number"
                                variant="outlined"
                                size="small"
                                fullWidth
                                name={key}
                                defaultValue={val}
                                value={singleandmultichoice.value}
                                onChange={(e) =>
                                  handleSingleAndMultiChoice(e, key, val)
                                }
                                className="date-input"
                              />
                            </Grid>
                          </Grid>
                        ))
                      ) : (
                        <>
                          {" "}
                          <Grid container spacing={1} className="marginbottom">
                            <Grid item md={6} xs={12} sm={12} lg={2}>
                              <Typography>Yes</Typography>
                            </Grid>
                            <Grid item md={6} xs={12} sm={12} lg={6}>
                              <TextField
                                id="outlined-basic"
                                type="number"
                                variant="outlined"
                                size="small"
                                fullWidth
                                name="option_score.yes"
                                defaultValue="1"
                                value={formik.values?.option_score?.yes}
                                onChange={formik.handleChange}
                                className="date-input"
                              />
                            </Grid>
                          </Grid>
                          <Grid container spacing={1} className="marginbottom">
                            <Grid item md={6} xs={12} sm={12} lg={2}>
                              <Typography>No</Typography>
                            </Grid>
                            <Grid item md={6} xs={12} sm={12} lg={6}>
                              <TextField
                                id="outlined-basic"
                                type="number"
                                variant="outlined"
                                size="small"
                                fullWidth
                                defaultValue="0"
                                name="option_score.no"
                                value={formik.values?.option_score?.no}
                                onChange={formik.handleChange}
                                className="date-input"
                              />
                            </Grid>
                          </Grid>
                          <Grid container spacing={1} className="marginbottom">
                            <Grid item md={6} xs={12} sm={12} lg={2}>
                              <Typography>Acceptable</Typography>
                            </Grid>
                            <Grid item md={6} xs={12} sm={12} lg={6}>
                              <TextField
                                id="outlined-basic"
                                type="number"
                                variant="outlined"
                                size="small"
                                fullWidth
                                defaultValue="1"
                                name="option_score.acceptable"
                                value={formik.values?.option_score?.acceptable}
                                onChange={formik.handleChange}
                                className="date-input"
                              />
                            </Grid>
                          </Grid>
                          <Grid container spacing={1} className="marginbottom">
                            <Grid item md={6} xs={12} sm={12} lg={2}>
                              <Typography>Not Acceptable</Typography>
                            </Grid>
                            <Grid item md={6} xs={12} sm={12} lg={6}>
                              <TextField
                                id="outlined-basic"
                                type="number"
                                variant="outlined"
                                size="small"
                                fullWidth
                                defaultValue="0"
                                name="option_score.notAcceptable"
                                value={
                                  formik.values?.option_score?.notAcceptable
                                }
                                onChange={formik.handleChange}
                                className="date-input"
                              />
                            </Grid>
                          </Grid>
                          <Grid container spacing={1} className="marginbottom">
                            <Grid item md={6} xs={12} sm={12} lg={2}>
                              <Typography>Discarded</Typography>
                            </Grid>
                            <Grid item md={6} xs={12} sm={12} lg={6}>
                              <TextField
                                id="outlined-basic"
                                type="number"
                                variant="outlined"
                                size="small"
                                fullWidth
                                defaultValue="0"
                                name="option_score.discarded"
                                value={formik.values?.option_score?.discarded}
                                onChange={formik.handleChange}
                                className="date-input"
                              />
                            </Grid>
                          </Grid>
                          <Grid container spacing={1} className="marginbottom">
                            <Grid item md={6} xs={12} sm={12} lg={2}>
                              <Typography>Returned</Typography>
                            </Grid>
                            <Grid item md={6} xs={12} sm={12} lg={6}>
                              <TextField
                                id="outlined-basic"
                                type="number"
                                variant="outlined"
                                size="small"
                                fullWidth
                                defaultValue="0"
                                name="option_score.returned"
                                value={formik.values?.option_score?.returned}
                                onChange={formik.handleChange}
                                className="date-input"
                              />
                            </Grid>
                          </Grid>
                          <Grid container spacing={1} className="marginbottom">
                            <Grid item md={6} xs={12} sm={12} lg={2}>
                              <Typography>Not Applicable</Typography>
                            </Grid>
                            <Grid item md={6} xs={12} sm={12} lg={6}>
                              <TextField
                                id="outlined-basic"
                                type="number"
                                variant="outlined"
                                size="small"
                                fullWidth
                                defaultValue="0"
                                name="option_score.notApplicable"
                                value={
                                  formik.values?.option_score?.notApplicable
                                }
                                onChange={formik.handleChange}
                                className="date-input"
                              />
                            </Grid>
                          </Grid>
                        </>
                      )}
                    </>
                  )}
                  {/* {(formik &&
                    formik?.values &&
                    formik?.values?.questiontype === "singlechoice") ||
                  ("multiplechoice" &&
                    formik?.values &&
                    formik?.values?.attachedlist) ? (
                    <>
                      {formik &&
                        formik?.values &&
                        formik?.values?.attachedlist &&
                        formik?.values?.attachedlist?.items.map((data, i) => {
                          return (
                            <Grid
                              container
                              spacing={1}
                              className="marginbottom"
                            >
                              <Grid item md={6} xs={12} sm={12} lg={2}>
                                <Typography>{data.title}</Typography>
                              </Grid>
                              <Grid item md={6} xs={12} sm={12} lg={6}>
                                <TextField
                                  id="outlined-basic"
                                  type="number"
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                  name={data.title}
                                  defaultValue="1"
                                  value={singleandmultichoice.value}
                                  onChange={handleSingleAndMultiChoice}
                                  className="date-input"
                                />
                              </Grid>
                            </Grid>
                          );
                        })}{" "}
                    </>
                  ) : (
                    <>
                      {" "}
                      <Grid container spacing={1} className="marginbottom">
                        <Grid item md={6} xs={12} sm={12} lg={2}>
                          <Typography>Yes</Typography>
                        </Grid>
                        <Grid item md={6} xs={12} sm={12} lg={6}>
                          <TextField
                            id="outlined-basic"
                            type="number"
                            variant="outlined"
                            size="small"
                            fullWidth
                            name="option_score.yes"
                            defaultValue="1"
                            value={formik.values?.option_score?.yes}
                            onChange={formik.handleChange}
                            className="date-input"
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} className="marginbottom">
                        <Grid item md={6} xs={12} sm={12} lg={2}>
                          <Typography>No</Typography>
                        </Grid>
                        <Grid item md={6} xs={12} sm={12} lg={6}>
                          <TextField
                            id="outlined-basic"
                            type="number"
                            variant="outlined"
                            size="small"
                            fullWidth
                            defaultValue="0"
                            name="option_score.no"
                            value={formik.values?.option_score?.no}
                            onChange={formik.handleChange}
                            className="date-input"
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} className="marginbottom">
                        <Grid item md={6} xs={12} sm={12} lg={2}>
                          <Typography>Acceptable</Typography>
                        </Grid>
                        <Grid item md={6} xs={12} sm={12} lg={6}>
                          <TextField
                            id="outlined-basic"
                            type="number"
                            variant="outlined"
                            size="small"
                            fullWidth
                            defaultValue="1"
                            name="option_score.acceptable"
                            value={formik.values?.option_score?.acceptable}
                            onChange={formik.handleChange}
                            className="date-input"
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} className="marginbottom">
                        <Grid item md={6} xs={12} sm={12} lg={2}>
                          <Typography>Not Acceptable</Typography>
                        </Grid>
                        <Grid item md={6} xs={12} sm={12} lg={6}>
                          <TextField
                            id="outlined-basic"
                            type="number"
                            variant="outlined"
                            size="small"
                            fullWidth
                            defaultValue="0"
                            name="option_score.notacceptable"
                        value={formik.values?.option_score?.notacceptable}
                        onChange={formik.handleChange}
                            className="date-input"
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} className="marginbottom">
                        <Grid item md={6} xs={12} sm={12} lg={2}>
                          <Typography>Discarded</Typography>
                        </Grid>
                        <Grid item md={6} xs={12} sm={12} lg={6}>
                          <TextField
                            id="outlined-basic"
                            type="number"
                            variant="outlined"
                            size="small"
                            fullWidth
                            defaultValue="0"
                            name="option_score.discarded"
                        value={formik.values?.option_score?.discarded}
                        onChange={formik.handleChange}
                            className="date-input"
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} className="marginbottom">
                        <Grid item md={6} xs={12} sm={12} lg={2}>
                          <Typography>Returned</Typography>
                        </Grid>
                        <Grid item md={6} xs={12} sm={12} lg={6}>
                          <TextField
                            id="outlined-basic"
                            type="number"
                            variant="outlined"
                            size="small"
                            fullWidth
                            defaultValue="0"
                            name="option_score.returned"
                            value={formik.values?.option_score?.returned}
                            onChange={formik.handleChange}
                            className="date-input"
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} className="marginbottom">
                        <Grid item md={6} xs={12} sm={12} lg={2}>
                          <Typography>Not Applicable</Typography>
                        </Grid>
                        <Grid item md={6} xs={12} sm={12} lg={6}>
                          <TextField
                            id="outlined-basic"
                            type="number"
                            variant="outlined"
                            size="small"
                            fullWidth
                            defaultValue="0"
                            name="option_score.notapplicable"
                            value={formik.values?.option_score?.notapplicable}
                            onChange={formik.handleChange}
                            className="date-input"
                          />
                        </Grid>
                      </Grid>
                    </>
                  )} */}
                </TabPanel>
              </Box>
            ) : (
              ""
            )}
          </Container>
        </Box>
      </form>
      <SelectRuleIssueModal
        open={open}
        handleClose={handleClose}
        listData={listData}
        setFormValues={setFormValues}
      />
    </>
  );
};

export default AddQustionEdit;
